import {Component, OnInit} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {AuthService} from "./core/services/auth/auth.service";
import {LoaderComponent} from "../assets/shared/components/loader/loader.component";
import {NgxSpinnerService} from "ngx-spinner";
import {UntilDestroy} from "@ngneat/until-destroy";



@UntilDestroy()
@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet, LoaderComponent],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit{


    constructor(
        public http: HttpClient,
        private spinner: NgxSpinnerService,
        private authService: AuthService) {

    }


    ngOnInit() {
        this.authService.registerEvents();
        this.spinner.show();
    }




}


