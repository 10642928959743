import {Component} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {RouterLink, RouterLinkActive, RouterOutlet} from "@angular/router";
import {
  DropdownProfileComponent
} from "../../../assets/shared/components/dropdown/dropdown-profile/dropdown-profile.component";
import {AuthService} from "../../core/services/auth/auth.service";
import {UserInfoClass} from "../../core/class/user-info.class";

import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";


@UntilDestroy()
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    NgForOf,
    RouterLink,
    RouterLinkActive,
    NgIf,
    DropdownProfileComponent,
    RouterOutlet,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent{
  isDropdownOpen = false;
  navMenu = [
    {
      name: 'Пользователи',
      url: '/profile',
    },
    {
      name: 'Рассылка',
      url: '/mailing',
    },
    {
      name: 'Заявки на мероприятия',
      url: '/application',
    },
    {
      name: 'Инструкции',
      url: '/instructions',
    },
    {
      name: 'Аналитика',
      url: '/analytics',
    },
  ];

  userInfo: UserInfoClass | null = this.authService.getUserInfo();

  constructor(private authService: AuthService) {
  }





}
