<div class="dropdown-menu">
  <div class="menu-item">
    <div class="profile-info">
      <span class="text-inter-semibold info-text">{{userInfo?.fullName}}</span>
      <span class="text-inter-light role-text">{{userInfo?.email}}</span>
    </div>
    <div class="other-info">
      <!--<span class="text-inter-semibold setting-profile-text">Настройки профиля</span>-->
      <div (click)="logout()" class="exit-block">
        <img src="assets/images/ul-kit-img/exit.svg">
        <span class="text-inter-semibold">Выйти</span>
      </div>
    </div>
  </div>
</div>

