import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatFormField, MatInput, MatSuffix} from "@angular/material/input";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatCheckbox} from "@angular/material/checkbox";
import {
  MatCalendarCellClassFunction,MatDatepickerModule,
  MatDatepickerToggle, MatDateRangeInput, MatDateRangePicker
} from "@angular/material/datepicker";
import {ValidatorDateDirective} from "../../../../app/core/directive/validators-datepicker.directive";
import {HeadersTableInterface} from "../../../../app/core/interface/table/headers-table.interface";
import {DictionaryService} from "../../../../app/core/services/pages/dictionary.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {DateAdapter} from "@angular/material/core";
import {MatFormFieldModule} from "@angular/material/form-field";
import {CustomDateAdapter} from "../../../../app/core/directive/custom-date.adapter";

@UntilDestroy()
@Component({
  selector: 'app-table-filter-modal',
  standalone: true,
  imports: [
    MatInput,
    FormsModule,
    NgIf,
    MatCheckbox,
    NgForOf,
    NgClass,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatDatepickerToggle,
    MatFormField,
    MatSuffix,
    ValidatorDateDirective,
    MatOption,
    MatSelect,
    MatDateRangePicker,
    MatDateRangeInput
  ],
  templateUrl: './table-filter-modal.component.html',
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
  ],
  styleUrl: './table-filter-modal.component.scss'
})

export class TableFilterModalComponent implements OnInit{

  constructor(
    private dictionaryService:DictionaryService
  ) {
    this.getInfoDictionaries()
  }

  // приходящие данные для типа поля и для его значений
  @Input() column!: HeadersTableInterface;
  @Output() closedFilter: EventEmitter<void> = new EventEmitter<void>();

  // для сохранения значений чекбокса
  valueFilterOptions: Set<string> = new Set();

  // для справочника
  dictionaries: { [key: string]: any; } | undefined

  // для работы мат селекта
  isActiveOperation: boolean = false;

  // для работы поиска если тип не справочник
  value!: string | boolean | Date | null | number | string[] ;
  subValue!: string | boolean | Date | null | number ;


  // для работы поиска по справочнику
  filteredDictionaries: any[] = [];
  valueFilterDictionariest!: string  ;

  ngOnInit() {
    this.getInfoStandartColumnValue(this.column)
    this.value = this.column.value || null;
    this.subValue = this.column.subValue || null;
    this.valueFilterDictionariest = this.column.filterText || '';
    this.filteredDictionaries = this.column.filteredDictionaries || [];

    if (this.column.value && Array.isArray(this.column.value)) {
      this.valueFilterOptions = new Set(this.column.value);
    } else {
      this.valueFilterOptions.clear();
    }

    this.valueFilterOption();
  }


  getInfoDictionaries(){
    this.dictionaryService.dictionaries$.pipe(untilDestroyed(this)).subscribe(x=>{
      this.dictionaries = x
    })
  }
  onSave() {
    this.column.value = this.value;
    this.column.subValue = this.subValue;
    this.column.filteredDictionaries = this.filteredDictionaries;
    this.column.filterText = this.valueFilterDictionariest;
    this.closedFilter.emit()
  }


  onClose(){
    this.column.value = null;
    this.column.subValue = null;
    this.column.filteredDictionaries = [];
    this.closedFilter.emit()
  }

  onCheckboxChange(code: string, event: any): void {
    if (event.checked) {
      this.valueFilterOptions.add(code);
    } else {
      this.valueFilterOptions.delete(code);
    }
    this.value = Array.from(this.valueFilterOptions);
  }
  isOptionSelected(code: string): boolean {
    return this.valueFilterOptions.has(code);
  }


  toggleAllCheckboxes(event: any): void {
    if (event.checked) {
      (this.valueFilterDictionariest?.trim() ? this.filteredDictionaries : this.dictionaries![this.column.dictionaryName!])
        .forEach((option: { code: string }) => {
          this.valueFilterOptions.add(option.code);
        });
    } else {
      (this.valueFilterDictionariest?.trim() ? this.filteredDictionaries : this.dictionaries![this.column.dictionaryName!])
        .forEach((option: { code: string }) => {
          this.valueFilterOptions.delete(option.code);
        });
    }
    this.value = Array.from(this.valueFilterOptions);
  }


  // выбор всех чекбоксов справочника(главный чекбокс)
  isAllSelected(): boolean {
    return (this.valueFilterDictionariest?.trim() ? this.filteredDictionaries : this.dictionaries![this.column.dictionaryName!])
      .every((option: { code: string }) =>
        this.valueFilterOptions.has(option.code)
      );
  }


  // для работы поиска по справочнику
  valueFilterOption(): void {
    if (this.dictionaries && this.column.dictionaryName) {
      if (this.dictionaries[this.column.dictionaryName] && this.valueFilterDictionariest !== null) {
        this.filteredDictionaries = this.valueFilterDictionariest.trim()
          ? this.dictionaries[this.column.dictionaryName].filter((option: { name: string }) =>
            option.name.toLowerCase().startsWith(this.valueFilterDictionariest!.toLowerCase()))
          : this.dictionaries[this.column.dictionaryName];
      }
    }
  }



// для вычисления типа поля
  getInfoStandartColumnValue(column:HeadersTableInterface){
    if (column.operation != null)
      return column.operation;

    if (column.type == 'bool' || column.type == 'number'){
      return  column.operation = 1
    }
    else if (column.type == 'string' || column.type == 'dictionary' || column.type == 'dictionaryList'){
      return column.operation = 2
    }
    else if (column.type == 'datetime'){
      return column.operation = 5
    }
    return column.operation = 1
  }

  onStartDateChange(event: any): void {
      this.value = new Date(event.value);
  }

  onEndDateChange(event: any): void {
      // Если передан объект (например, событие от MatDatepicker)
      const endDate = new Date(event.value); // Преобразуем в Date
      endDate.setHours(23, 59, 59); // Устанавливаем конец дня
      this.subValue = endDate;
  }

  isWeekendDay: MatCalendarCellClassFunction<Date> = (date, view) => {
    return (date.getDay() === 0 || date.getDay() === 6) ? 'weekend' : '';
  };


  checkIfActive(event: boolean) {
    this.isActiveOperation = event
  }
}





