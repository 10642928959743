import {AuthService} from "../services/auth/auth.service";

export class UserInfoClass {
  fullName: string | undefined
  shortName: string | undefined
  firstName: string | undefined
  email: string | undefined
  private nickname: string | undefined

  constructor(nickname: string | undefined
              , email: string | undefined
              , given_name: string | undefined
              , family_name: string | undefined
              , middle_name: string | undefined) {
    this.email = email;
    this.nickname = nickname;
    this.firstName = given_name || nickname;
    this.fullName= [family_name, given_name, middle_name].filter((name) => !!name).join(' ');
    this.shortName = this.getShortName(given_name, family_name, middle_name);
  }

  getShortName(given_name?: string, family_name?: string, middle_name?: string): string {
    const initials = [
      given_name ? `${given_name.charAt(0)}.` : '',
      middle_name ? `${middle_name.charAt(0)}.` : '',
    ].join('');
    return family_name ? `${family_name} ${initials}`.trim() : initials;
  }
}
