<div [class.margin-bottom-unset]="!isActive"
     [formGroup]="formInfoApplication"
     class="form">
  <div (click)="isActive = !isActive" class="label-form">
    <img [class.rotate-180]="isActive" src="assets/images/ul-kit-img/arrow-black.svg">
    <span>Общая информация</span>
  </div>
  <div [class.active]="isActive" class="form-content">
    <div class="left-content">
      <div *ngFor="let item of contentItemLabel; let i = index; let last = last"
           class="item-content-label"
           [class.no-photo]="(last && !formInfoApplication.value.digitalAvatar?.hash)
           || (i === contentItemLabel.length - 2 && !formInfoApplication.value.photo?.hash)"
      >
        <img src="assets/images/ul-kit-img/green-point.svg">
        <span>{{ item.name }}</span>
      </div>
    </div>
    <div class="right-content">


      <div class="item-info">
        <input formControlName="userName" class="text-inter-semibold input-main-search"
               [placeholder]="formInfoApplication.get('userName')?.value === null ? 'Не заполнено' : ''"
               matInput>
      </div>


      <div class="item-info">
        <custom-selection [multipleCheckBox]="false"
                          [control]="formInfoApplication.controls.statusCode"
                          [options]="dictionaries?.['statuspersonalaccount']"
        ></custom-selection>
      </div>
      <div class="item-info">
        <app-date-and-timepicker [control]="formInfoApplication.controls.userCreationDate">
        </app-date-and-timepicker>
      </div>

      <div class="item-info">
        <input formControlName="reasonBlocking" class="text-inter-semibold input-main-search"
               [placeholder]="formInfoApplication.get('reasonBlocking')?.value === null ? 'Не заполнено' : ''"
               matInput>
      </div>

      <div class="item-info">
        <mat-form-field appearance="outline" class="custom-selection-search">
          <img [class.rotate-180]="isActiveSelect" class="arrow-custom-select"
               src="assets/shared/components/select/img-select/select-arrow.svg">
          <div class="select-text">
            <mat-select [formControl]="formInfoApplication.controls.mailingIsAccept"
                        (openedChange)="checkIfActive($event)">
              <div class="select-text">
                <mat-option [value]="true">Да</mat-option>
                <mat-option [value]="false">Нет</mat-option>
              </div>
            </mat-select>
          </div>
        </mat-form-field>
      </div>


      <div class="item-info block-with-checkbox ">
        <input (keypress)="$event.preventDefault()"
               class="input-main-search input-checkbox hide"
               [formControl]="formInfoApplication.controls.isTest"
               matInput>
        <mat-checkbox
          [formControl]="formInfoApplication.controls.isTest"
          class="custom-checkbox">
          <label class="checkbox-label"></label></mat-checkbox>
      </div>


      <div *ngIf="formInfoApplication.value.photo?.hash" class="photo ">
        <div class="item-info tools">
          <button [class.disabled-button]="true" (click)="fileInput.click()" class="button-classic"
                  [disabled]="true">
            <input type="file" style="display: none;" (change)="onFileChange($event,'photo')" #fileInput>
            <img src="assets/images/ul-kit-img/update.svg">
            <span  class="tooltip-text">Изменить фотографию профиля</span>
          </button>
          <button class="button-classic"
                  (click)="downloadFile(formInfoApplication.value.photo?.hash,formInfoApplication.value.photo?.name || '')"
                  [disabled]="true"
                  [class.disabled-button]="true"
          >


            <img src="assets/images/ul-kit-img/download.svg">
            <span class="tooltip-text">Скачать фотографию профиля</span>
          </button>
          <button class="button-classic"
                  [class.disabled-button]="true"
                  [disabled]="true"
                  (click)="openModalShowImage(formInfoApplication.value.photo?.name,formInfoApplication.value.photo?.hash )">

            <img src="assets/images/ul-kit-img/look.svg">
            <span  class="tooltip-text">Посмотреть фотографию профиля</span>
          </button>
        </div>
        <img class="photo-img" src="{{environment.photoUrl + formInfoApplication.value.photo?.hash }}"/>
      </div>

      <div *ngIf="!formInfoApplication.value.photo?.hash"  [class.no-photo]="!formInfoApplication.value.photo?.hash" class="photo">
        <div class="item-info tools">
          <button [class.disabled-button]="true" (click)="fileInput.click()" class="button-classic"
                  [disabled]="true">
            <input type="file" style="display: none;" (change)="onFileChange($event,'photo')" #fileInput>
            <img src="assets/images/ul-kit-img/upload.svg">
            <span  class="tooltip-text">Загрузить фотографию профиля</span>
          </button>
        </div>
      </div>


      <div  class="photo">
        <div class="item-info tools">
          <button [class.disabled-button]="true"
                  [disabled]="true" (click)="fileInput.click()" class="button-classic"
                  >
            <input type="file" style="display: none;" (change)="onFileChange($event,'digitalAvatar')" #fileInput>
            <img src="assets/images/ul-kit-img/update.svg">
            <span  class="tooltip-text">Изменить цифровой аватар</span>
          </button>
          <button class="button-classic"
                  [class.disabled-button]="true"
                  [disabled]="true"
                  (click)="downloadFile(formInfoApplication.value.digitalAvatar?.hash,formInfoApplication.value.digitalAvatar?.name || '')"
          >

            <img src="assets/images/ul-kit-img/download.svg">
            <span  class="tooltip-text">Скачать цифровой аватар</span>
          </button>
          <button class="button-classic"
                  [class.disabled-button]="true"
                  [disabled]="true"
                  (click)="openModalShowImage(formInfoApplication.value.digitalAvatar?.name ,formInfoApplication.value.digitalAvatar?.hash)">

            <img src="assets/images/ul-kit-img/look.svg">

            <span  class="tooltip-text">Посмотреть цифровой аватар</span>
          </button>
        </div>
        <img class="photo-img" src="{{environment.photoUrl + formInfoApplication.value.digitalAvatar?.hash}}"/>
      </div>


      <div *ngIf="!formInfoApplication.value.digitalAvatar?.hash" [class.no-photo]="!formInfoApplication.value.digitalAvatar?.hash" class="photo ">
        <div class="item-info tools">
          <button [class.disabled-button]="true" (click)="fileInput.click()" class="button-classic"
                  [disabled]="true">
            <input type="file" style="display: none;" (change)="onFileChange($event,'digitalAvatar')" #fileInput>
            <img src="assets/images/ul-kit-img/upload.svg">
            <span  class="tooltip-text">Загрузить цифровой аватар</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
