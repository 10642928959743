import { Component } from '@angular/core';

import {
    DevelopmentMessageComponent
} from "../../../assets/shared/components/development-message/development-message.component";

@Component({
  selector: 'app-instructions',
  standalone: true,
    imports: [
        DevelopmentMessageComponent
    ],
  templateUrl: './instructions.component.html',
  styleUrl: './instructions.component.scss'
})
export class InstructionsComponent {

}
