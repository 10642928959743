<div class="body">
    <div class="container">
        <div class="home-page">
            <div class="header">
                <div class="header-page">
                    <div class="header-page-home">
                        <div class="header-block-logo">
                            <img src="assets/images/ul-kit-img/logo.svg">
                                <div class="dropdown">
                                    <div class="info-user" (click)="isDropdownOpen = !isDropdownOpen">
                                        <span class="user-name">{{userInfo?.shortName}}</span>
                                        <img src="assets/images/ul-kit-img/arrow-down-white.svg"
                                             [class.rotate-180]="isDropdownOpen">
                                    </div>
                                    <app-dropdown-profile *ngIf="isDropdownOpen"></app-dropdown-profile>
                                </div>
                        </div>
                        <div class="welcome">
                            <div class="welcome-title">
                                <h1>Добро пожаловать в систему, {{userInfo?.firstName}}!</h1>
                                <span>{{userInfo?.email}}</span>
                            </div>
                            <!--            <div class="welcome-setting">-->
                            <!--              <img src="assets/images/ul-kit-img/setting-profile.svg">-->
                            <!--              <span>Настройки профиля</span>-->
                            <!--            </div>-->
                        </div>
                    </div>
                    <div class="nav-block">
                        <div [routerLink]="[item.url]" *ngFor="let item of homeNavBar" class="nav-item">
                            <div class="nav-block-label">
                                <img [src]="item.icon" alt="{{ item.label }}" class="nav-icon">
                                <span class="text-inter-regular nav-label">{{ item.label }}</span>
                            </div>
                            <div class="nav-content">
                                <p class="text-inter-regular nav-description">{{ item.description }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="nav-block">
                        <a [href]="item.url" *ngFor="let item of homeNavBarNotLong" class="nav-item" target="_blank"
                           rel="noopener noreferrer">
                            <div class="nav-block-label">
                                <img [src]="item.icon" alt="{{ item.label }}" class="nav-icon">
                                <span class="text-inter-regular nav-label">{{ item.label }}</span>
                            </div>
                            <div class="nav-content">
                                <p class="text-inter-regular nav-description">{{ item.description }}</p>
                            </div>
                        </a>
                    </div>
                  <
                </div>
            </div>
            <div class="home-block">
                <div class="download-statistics">
                    <span>Статистика</span>
                    <!--        <img src="assets/images/ul-kit-img/download.svg">-->
                </div>
                <div class="statistics-container">
                    <div *ngIf="dictionaries?.['countries']" class="statistics statics-left">
                        <app-charts-bar-vertical [dictionaries]="dictionaries"
                                                 [countriesCharts]="countriesCharts"
                        ></app-charts-bar-vertical>
                    </div>
                    <div class="statistics statistics-application">
                        <div class="info-members">
                            <div class="label-charts label-count">
                                <span class="text-inter-semibold">Общее кол-во участников в системе</span>
                            </div>
                            <div class="members-count">
              <span class="count-member">
                {{stat?.totalUsers}}
              </span>
                                <div class="count-block">
                                    <span class="count">(+{{stat?.totalUsersDailyIncrease}})</span><span>за день</span>
                                </div>
                            </div>
                            <div class="members-count count-day">
                                <div class="label-charts label-count">
                                    <span class="text-inter-semibold">Российских участников</span>
                                </div>
                                <div class="members-count">
                                    <span>{{stat?.russianUsers}}</span>
                                    <div class="count-block">
                                        <span class="count">(+{{stat?.russianUsersDailyIncrease}}
                                            )</span><span>за день</span>
                                    </div>
                                </div>
                            </div>
                            <div class="members-count count-day">
                                <div class="label-charts label-count">
                                    <span class="text-inter-semibold">Иностранных участников</span>
                                </div>
                                <div class="members-count">
                                    <span>{{stat?.foreignUsers}}</span>
                                    <div class="count-block">
                                        <span class="count">(+{{stat?.foreignUsersDailyIncrease}}
                                            )</span><span>за день</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--        <div class="statistics-column-container">-->
                    <!--          <div class="statistics statistics-event">-->
                    <!--            <div class="info-members info-event">-->
                    <!--              <div class="label-charts label-count">-->
                    <!--                <span class="text-inter-semibold">Общее кол-во мероприятий</span>-->
                    <!--              </div>-->
                    <!--              <div class="event-count">-->
                    <!--                <span>50</span>-->
                    <!--              </div>-->
                    <!--            </div>-->
                    <!--          </div>-->
                    <!--          <div class="statistics statistics-event">-->
                    <!--            <div class="info-members info-event">-->
                    <!--              <div class="label-charts label-count">-->
                    <!--                <span class="text-inter-semibold">Общее кол-во участников мероприятий</span>-->
                    <!--              </div>-->
                    <!--              <div class="event-count-members">-->
                    <!--                <span>70 000</span>-->
                    <!--              </div>-->
                    <!--            </div>-->
                    <!--          </div>-->
                    <!--        </div>-->
                    <!--        <div class="statistics statistics-application">-->
                    <!--          <app-charts-pie-chart></app-charts-pie-chart>-->
                    <!--        </div>-->
                </div>
            </div>
        </div>
    </div>
</div>




