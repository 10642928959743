import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {Component, OnInit} from "@angular/core";
import {ButtonBackComponent} from "../../../../assets/shared/components/button-back/button-back.component";
import {NgClass, NgIf} from "@angular/common";
import {GeneralInformationComponent} from "./general-information/general-information.component";
import {PersonalInformationComponent} from "./personal-information/personal-information.component";
import {DropdownComponent} from "../../../../assets/shared/components/dropdown/dropdown-basic/dropdown.component";
import {PassportInformationComponent} from "./passport-information/passport-information.component";
import {SocialInformationComponent} from "./social-information/social-information.component";
import {MoreInformationComponent} from "./more-information/more-information.component";
import {FieldActivityInformationComponent} from "./field-activity-information/field-activity-information.component";
import {FormGroup} from "@angular/forms";
import {ProfileForm} from "../../../core/interface/profile-card/profile-card-main.interface";
import {ProfileMainCardService} from "./profile-card.service";
import {DictionaryService} from "../../../core/services/pages/dictionary.service";

import {ActivatedRoute} from "@angular/router";

import {MatDialog} from "@angular/material/dialog";
import {ModalBlockConfirmComponent} from "../../../layout/dialogs/modal-block-confirm/modal-block-confirm.component";

import {NotificationService} from "../../../core/services/notification/notification.service";
import {
  ModalGeneralConfirmComponent
} from "../../../layout/dialogs/modal-general-confirm/modal-general-confirm.component";

@UntilDestroy()
@Component({
  selector: 'app-profile-card',
  standalone: true,
  imports: [
    ButtonBackComponent,
    NgClass,
    GeneralInformationComponent,
    PersonalInformationComponent,
    DropdownComponent,
    NgIf,
    PassportInformationComponent,
    SocialInformationComponent,
    MoreInformationComponent,
    FieldActivityInformationComponent,
  ],
  templateUrl: './profile-card.component.html',
  styleUrl: './profile-card.component.scss'
})
export class ProfileCardComponent implements OnInit {
  isDropdownOpen = false;
  formInfoProfile: FormGroup<ProfileForm> = this.profileCardService.formInfoProfile
  dictionaries: { [key: string]: any; } | undefined
  userUid = this.activatedRoute.snapshot.params['id'];
  isEdit?: boolean
  supplementalMenu = [
    // {label: 'Печатать', url: ''},
    // {label: 'Обновить', url: ''},
    // {label: 'Выгрузить', url: ''},
    // {label: 'Предпросмотр бейджа', url: ''},
    // {label: 'Документы', url: ''},
    {label: 'Блокировать', url: '', condition: () => this.formInfoProfile.get('statusCode')?.value !== 'blocked'},
    {label: 'Разблокировать', url: '', condition: () => this.formInfoProfile.get('statusCode')?.value === 'blocked'},
    // {label: 'Настроить расположение атрибутов', url: ''},
    // {label: 'Зарегистрировать на событие', url: ''},
    // {label: 'Выбрать слот питания', url: ''},
  ]
  historyChanges = [
    {date: '2024-12-01', description: 'description', author: 'Герман11 Герман11 Герман11'},
    {date: '2024-12-10', description: 'description', author: 'Герман11 Герман11 Герман11'},
    {date: '2024-12-20', description: 'description', author: 'Герман11 Герман11 Герман11'},
  ];

  constructor(public profileCardService: ProfileMainCardService,
              private dictionaryService: DictionaryService,
              private dialog: MatDialog,
              private notify: NotificationService,
              private activatedRoute: ActivatedRoute) {

    this.loadDictionaries()
    this.getInfoDictionaries()
  }

  ngOnInit() {
    this.getInfoEditFormCard()
  }

  getInfoEditFormCard() {
    this.profileCardService.isEdit$.pipe(untilDestroyed(this)).subscribe(isEdit => {
      this.isEdit = isEdit
    });
  }

  getFilteredMenu(menu: any[]) {
    return menu.filter(item => !item.condition || item.condition());
  }

  onMenuItemClick(action: string): void {
    if (action === 'Блокировать' || action === 'Разблокировать') {
      this.openModalConfirmBlocking(action);
    }
  }

  openModalConfirmBlocking(action: string) {
    const dialogRef = this.dialog.open(ModalBlockConfirmComponent, {
      data: {
        action: action
      }
    });
    dialogRef.afterClosed().pipe(untilDestroyed(this)).subscribe(result => {
      if (result) {
        if (result.reasonBlocking) {
          this.profileCardService.changeProfileStatusBlocked(this.userUid, result.reasonBlocking).pipe(untilDestroyed(this)).subscribe({
            next: () => {
              this.profileCardService.getInfoProfileCard(this.userUid)
              this.notify.success('Cтатус ЛК успешно изменен на "Заблокирован"')
              this.isDropdownOpen = false
            }
          })
        } else {
          this.profileCardService.changeProfileStatusUnblocked(this.userUid).pipe(untilDestroyed(this)).subscribe({
            next: () => {
              this.profileCardService.getInfoProfileCard(this.userUid)
              this.notify.success('Cтатус ЛК успешно изменен на "Активен"')
              this.isDropdownOpen = false
            }
          })
        }
      }
    });
  }

  openModalConfirmGeneral() {
    const dialogRef = this.dialog.open(ModalGeneralConfirmComponent, {
      data: {
      message: 'подтвердить это действие'
      }
    });
    dialogRef.afterClosed().pipe(untilDestroyed(this)).subscribe(result => {
      if (result) {
        this.profileCardService.sendUpdatedFormProfile()
      }
    });
  }

  getInfoDictionaries() {
    this.dictionaryService.dictionaries$.pipe(untilDestroyed(this)).subscribe(x => {
      this.dictionaries = x
    })
  }

  loadDictionaries() {
    let dictionariesList: string[] = [
      'genders',
      'direction',
      'regions',
      'languages',
      'countries',
      'participantstatus',
      'cities',
      'statuspersonalaccount',
      'healthrestrictions',
      'foodpreferences',
      'socialnetworktypes',
      'languagelevels',
      'clothingsizes',
      'statuspersonalaccount',
      'titles'
    ];
    this.profileCardService.loadDictionaries(dictionariesList)
    this.getInfoProfileCard()
  }


  isEditForm(): void {
    this.profileCardService.isEditForm();
  }


  canselForm(): void {
    this.profileCardService.canselForm()
  }

  getInfoProfileCard() {
    this.profileCardService.getInfoProfileCard(this.userUid)
  }


}
