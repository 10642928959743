import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject } from 'rxjs';
import {UserInfoClass} from "../../class/user-info.class";

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  constructor(private oauthService: OAuthService) {}

  registerEvents() {
    this.oauthService.setupAutomaticSilentRefresh();

    this.oauthService.events
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (e.type === 'token_received') {
          this.isAuthenticatedSubject$.next(true);
        }
        if (e.type === 'logout') {
          this.isAuthenticatedSubject$.next(false);
        }
      });

    this.oauthService.tryLogin().then(() => {
      this.isAuthenticatedSubject$.next(true);
    });
  }

  async login(): Promise<boolean> {
    return await this.oauthService.loadDiscoveryDocumentAndLogin();
  }

  async logout() {
    await this.oauthService.revokeTokenAndLogout();
  }

  hasValidToken(): boolean {
    return this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken();
  }

  getAccessToken(): string {
    return this.oauthService.getAccessToken();
  }

  getUserInfo():UserInfoClass{
    let test = this.getUserClaims();
    return new UserInfoClass(test.nickname, test.email, test.given_name, test.family_name, test.middle_name)
  }

  private getUserClaims(): {
    given_name?: string;
    family_name?: string;
    middle_name?: string;
    nickname?: string;
    email?: string; } {
    const claims = this.oauthService.getIdentityClaims();
    if (claims) {
      return {
        given_name: claims['given_name'],
        family_name: claims['family_name'],
        middle_name: claims['middle_name'],
        nickname: claims['nickname'],
        email: claims['email'],
      };
    }
    return {};
  }
}
