import {Component, OnInit} from '@angular/core';
import {FormControl, FormsModule} from "@angular/forms";
import {CustomSelectionComponent} from "../../../assets/shared/components/select/custom-selection/custom-selection.component";
import {MatFormFieldModule, MatLabel} from "@angular/material/form-field";
import {MatInput, MatInputModule} from "@angular/material/input";
import {PaginatorComponent} from "../../../assets/shared/components/paginator/paginator.component";
import {
  MatDatepickerModule,
} from "@angular/material/datepicker";
import {ProfileMainService} from "./profile-main.service";
import {UntilDestroy} from "@ngneat/until-destroy";
import {TableInfo} from "../../core/interface/table/table-info";
import {ProfileTableComponent} from "./profile-table/profile-table.component";


@UntilDestroy()
@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    ProfileTableComponent,
    FormsModule,
    CustomSelectionComponent,
    MatLabel,
    MatInput,
    PaginatorComponent,
    MatFormFieldModule, MatInputModule, MatDatepickerModule,
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent implements OnInit{
  tableInfo: TableInfo = this.profileMainService.tableInfo;
  clearControl = new FormControl([])

  constructor(public profileMainService:ProfileMainService) {}

  ngOnInit() {
    this.getInfoTakeProfileTable()
  }

  getInfoTakeProfileTable(){
    return this.profileMainService.getInfoTakeProfileTable()
  }

  changePage(page: number): void {
    this.profileMainService.changePage(page);
  }
  changeTake(take: number): void {
    this.profileMainService.changeTake(take);
  }

  onSaveFilterSearch(){
    this.tableInfo.skip = 0
    this.profileMainService.updateTableProfile()
  }
  onClearFilterSearch(){
    this.tableInfo.skip = 0
    if (this.profileMainService.tableInfo.quickSearch) {
      this.profileMainService.tableInfo.quickSearch.value = '';
      this.profileMainService.tableInfo.quickSearch.fields = [];
      this.clearControl.setValue([]);
    }
    this.profileMainService.updateTableProfile()
  }
}
