import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {Injectable} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {BehaviorSubject, forkJoin} from "rxjs";
import {ProfileCardService} from "../../../core/services/pages/profile/profile-card.service";
import {Profile, ProfileForm} from "../../../core/interface/profile-card/profile-card-main.interface";
import {PassportForm} from "../../../core/interface/profile-card/profile-passport.interface";
import {PhotoForm} from "../../../core/interface/profile-card/profile-card-photo.interface";
import {ExtraInformationForm} from "../../../core/interface/profile-card/profile-extraInfo-interface";
import {DictionaryService} from "../../../core/services/pages/dictionary.service";
import {SphereActivityForm} from "../../../core/interface/profile-card/profile-sphereActivity.interface";
import {NotificationService} from "../../../core/services/notification/notification.service";


@UntilDestroy()
@Injectable()

export class ProfileMainCardService {
  oldFormInfoProfile: ProfileForm | undefined
  isEdit = new BehaviorSubject<boolean>(false);
  isEdit$ = this.isEdit.asObservable();
  formInfoProfile: FormGroup<ProfileForm> = new FormGroup<ProfileForm>({
    isTest: new FormControl<boolean | undefined | null>(null),
    userUid: new FormControl<number | null>(null),
    userName: new FormControl<string | null | undefined>(null),
    mailingIsAccept: new FormControl<boolean | null | undefined>(null),
    phoneNumber: new FormControl<string | null | undefined>(null),
    statusCode: new FormControl<string | null | undefined>(null),
    reasonBlocking: new FormControl<string | null | undefined>(null),
    lastName: new FormControl<string | null | undefined>(null),
    firstName: new FormControl<string | null | undefined>(null),
    middleName: new FormControl<string | null | undefined>(null),
    dateBirth: new FormControl<string | Date | null | undefined>(null),
    noMiddleName: new FormControl<boolean | null | undefined>(null),
    noLastName: new FormControl<boolean | null | undefined>(null),
    noFirstName: new FormControl<boolean | null | undefined>(null),
    firstNameEng: new FormControl<string | null | undefined>(null),
    lastNameEng: new FormControl<string | null | undefined>(null),
    middleNameEng: new FormControl<string | null | undefined>(null),
    genderCode: new FormControl<string | null | undefined>(null),
    corrLanguageCode: new FormControl<string | null | undefined>(null),
    titleCode: new FormControl<string | null | undefined>(null),
    userCreationDate: new FormControl<Date | string | undefined | null>(null),
    digitalAvatarId: new FormControl<number | undefined | null>(null),
    agreedCodeConduct: new FormControl<boolean | null | undefined>(null),
    consentProcessingPersonalData: new FormControl<boolean | null | undefined>(null),
    photoId: new FormControl<number | null | undefined>(null),

    extraInformation: new FormGroup<ExtraInformationForm>({
      userUid: new FormControl<number | null | undefined>(null),
      healthRestrictions: new FormGroup<any>({
        other: new FormControl<string | null | undefined>(null),
        healthRestrictionCode: new FormControl<string | null | undefined>(null),
      }),
      isParticipantMcd: new FormControl<boolean | null | undefined>(null),
      directions: new FormControl([]),
      isNoHealthRestrictions: new FormControl<boolean | null | undefined>(null),
      foodPreferenceCode: new FormControl<string | null | undefined>(null),
      clothingSizeCode: new FormControl<string | null | undefined>(null),
      wantsParticipantMcd: new FormControl<boolean | null | undefined>(null),
      // passportParticipantMcdId: new FormControl<number | null | undefined>(null),
      socialNetworks: new FormGroup<any>({
        vk: new FormControl<string | null | undefined>(null),
        vkLink: new FormControl<string | null | undefined>(null),
        facebook: new FormControl<string | null | undefined>(null),
        facebookLink: new FormControl<string | null | undefined>(null),
        telegram: new FormControl<string | null | undefined>(null),
        telegramLink: new FormControl<string | null | undefined>(null),
        instagram: new FormControl<string | null | undefined>(null),
        instagramLink: new FormControl<string | null | undefined>(null),
      }),
    }),
    passportDetail: new FormGroup<PassportForm>({
      userUid: new FormControl<number | null | undefined>(null),
      actualDomicileAddress: new FormControl<string | null | undefined>(null),
      citizenshipOksmCode: new FormControl<string | null | undefined>(null),
      isCheckedRegionsRussian: new FormControl<boolean | null | undefined>(false),
      cityResident: new FormControl<string | null | undefined>(null),
      dateIssued: new FormControl<string | Date | null | undefined>(null),
      dateExpires: new FormControl<string | Date | null | undefined>(null),
      documentId: new FormControl<number | null | undefined>(null),
      documentName: new FormControl<string | null | undefined>(null),
      // document: new FormGroup<DocumentForm>({
      //   id: new FormControl<number | null>(null),
      // }),
      identityDocTypeCode: new FormControl<string | null | number>(null),
      number: new FormControl<string | null | undefined>(null),
      issued: new FormControl<string | null | undefined>(null),
      placeBirth: new FormControl<string | null | undefined>(null),
      registrationAddress: new FormControl<string | null | undefined>(null),
      residenceOksmCode: new FormControl<string | null | undefined>(null),
      residenceRegionCode: new FormControl<string | null | undefined>(null),
      series: new FormControl<string | null | undefined>(null),
    }),
    sphereActivity: new FormGroup<SphereActivityForm>({
      userUid: new FormControl<number | null | undefined>(null),
      positionName: new FormControl<string | null | undefined>(null),
      positionNameEng: new FormControl<string | null | undefined>(null),
      workplace: new FormControl<string | null | undefined>(null),
      workplaceEng: new FormControl<string | null | undefined>(null),
      engLanguageLevelCode: new FormControl<string | null | undefined>(null),
      statusParticipants: new FormControl<any | [] | null>(null),
    }),
    photo: new FormGroup<PhotoForm>({
      hash: new FormControl<string | null | undefined>(null),
      name: new FormControl<string | null | undefined>(null),
    }),
    digitalAvatar: new FormGroup<PhotoForm>({
      hash: new FormControl<string | null | undefined>(null),
      name: new FormControl<string | null | undefined>(null),
    }),
  });

  constructor(private profileCardService: ProfileCardService,
              private notify: NotificationService,
              private dictionaryService: DictionaryService) {
    this.getInfoFormEnable()
  }

  isEditForm(): void {
    if (!this.isEdit.value) {
    }
    this.isEdit.next(!this.isEdit.value);
  }


  // метод на получение данных карточки которые разбиваются на поля для формы api (разбиваю модель)
  getInfoProfileCard(userUid: number) {
    forkJoin([
      this.profileCardService.getInfoCardProfile(userUid),
      this.profileCardService.getInfoCardIntegration(userUid),
    ]).pipe(untilDestroyed(this)).subscribe(([info, infoIntegration]) => {
      this.formInfoProfile.patchValue({
        isTest: info.isTest,
        userUid: info.userUid || null,
        userName: info.userName || null,
        mailingIsAccept: info.mailingIsAccept,
        phoneNumber: info.phoneNumber || null,
        lastName: info.lastName || null,
        firstName: info.firstName || null,
        middleName: info.middleName || null,
        dateBirth: info.dateBirth || null,
        genderCode: info.genderCode || null,
        statusCode: info.statusCode || null,
        reasonBlocking: infoIntegration.reasonBlocking || null,
        corrLanguageCode: info.corrLanguageCode || null,
        userCreationDate: info.userCreationDate || null,
        noMiddleName: info.noMiddleName,
        noLastName: info.noLastName,
        noFirstName: info.noFirstName,
        firstNameEng: infoIntegration.firstNameEng || null,
        lastNameEng: infoIntegration.lastNameEng || null,
        middleNameEng: infoIntegration.middleNameEng || null,
        agreedCodeConduct: infoIntegration.agreedCodeConduct || null,
        consentProcessingPersonalData: infoIntegration.consentProcessingPersonalData || null,
        photoId: infoIntegration.photoId || null,
        digitalAvatarId: infoIntegration.digitalAvatarId || null,
        titleCode: infoIntegration.titleCode || null,
        digitalAvatar: {
          hash: infoIntegration.digitalAvatar?.hash || null,
          name: infoIntegration.digitalAvatar?.name || null
        },
        photo: {
          hash: infoIntegration.photo?.hash || null,
          name: infoIntegration.photo?.name || null,
        },
        extraInformation: {
          userUid: info.userUid || null,
          isNoHealthRestrictions: infoIntegration.extraInformation?.isNoHealthRestrictions,
          foodPreferenceCode: infoIntegration.extraInformation?.foodPreferenceCode || null,
          clothingSizeCode: infoIntegration.extraInformation?.clothingSizeCode || null,
          isParticipantMcd: info.extraInformation?.isParticipantMcd,
          directions: Array.isArray(info.extraInformation?.directions) ?
            info.extraInformation.directions.map(direction => direction.directionCode) : [],
          socialNetworks: (infoIntegration.extraInformation?.socialNetworks || []).reduce(
            (acc: Record<string, string | null>, item: { socialNetworkTypeCode: string; link: string }) => {
              if (item.socialNetworkTypeCode) {
                acc[item.socialNetworkTypeCode] = item.socialNetworkTypeCode || null;
                acc[`${item.socialNetworkTypeCode}Link`] = item.link || null;
              }
              return acc;
            },
            {
              vk: null, vkLink: null,
              instagram: null, instagramLink: null,
              telegram: null, telegramLink: null,
              facebook: null, facebookLink: null,
            }
          ),
          healthRestrictions: {
            userUid: info.userUid || 'Не заполнено',
            other: infoIntegration.extraInformation?.healthRestrictions ?
              infoIntegration.extraInformation?.healthRestrictions.map(other => other.other).join('')
              : '',
            healthRestrictionCode: Array.isArray(infoIntegration.extraInformation?.healthRestrictions) ?
              infoIntegration.extraInformation?.healthRestrictions.map(status => status.healthRestrictionCode) : [],
          },
          wantsParticipantMcd: info.extraInformation?.wantsParticipantMcd,
        },
        passportDetail: {
          userUid: info.userUid || 'Не заполнено',
          isCheckedRegionsRussian: info.passportDetail?.residenceOksmCode === 'rus' ? true : false,
          citizenshipOksmCode: info.passportDetail?.citizenshipOksmCode,
          identityDocTypeCode: infoIntegration.passportDetail?.identityDocTypeCode || null,
          series: infoIntegration.passportDetail?.series || null,
          number: infoIntegration.passportDetail?.number || null,
          issued: infoIntegration.passportDetail?.issued || null,
          dateIssued: infoIntegration.passportDetail?.dateIssued || null,
          dateExpires: infoIntegration.passportDetail?.dateExpires || null,
          placeBirth: infoIntegration.passportDetail?.placeBirth || null,
          residenceRegionCode: info.passportDetail?.residenceRegionCode || null,
          residenceOksmCode: info.passportDetail?.residenceOksmCode || null,
          cityResident: infoIntegration.passportDetail?.cityResident || null,
          registrationAddress: infoIntegration.passportDetail?.registrationAddress || null,
          actualDomicileAddress: infoIntegration.passportDetail?.actualDomicileAddress || null,
          documentId: infoIntegration.passportDetail?.documentId,
          documentName: infoIntegration.passportDetail?.documentName,
        },
        sphereActivity: {
          userUid: info.userUid || null,
          positionName: infoIntegration.sphereActivity?.positionName || null,
          positionNameEng: infoIntegration.sphereActivity?.positionNameEng || null,
          workplace: infoIntegration.sphereActivity?.workplace || null,
          workplaceEng: infoIntegration.sphereActivity?.workplaceEng || null,
          engLanguageLevelCode: infoIntegration.sphereActivity?.engLanguageLevelCode || null,
          statusParticipants: Array.isArray(infoIntegration.sphereActivity?.statusParticipants) ?
            infoIntegration.sphereActivity?.statusParticipants.map(status => status.statusParticipantCode) : [],
        },
      });
      this.oldFormInfoProfile = JSON.parse(JSON.stringify(this.formInfoProfile.getRawValue()));
      this.isEdit.next(false)
    });
  }

// метод отправки формы в той структуре в которой принимает api (собираю в модель)
  sendUpdatedFormProfile() {
    let formInfoProfileAfterEdit = {
      isTest: this.formInfoProfile.getRawValue().isTest,
      userUid: this.formInfoProfile.getRawValue().userUid,
      userName: this.formInfoProfile.getRawValue().userName,
      mailingIsAccept: this.formInfoProfile.getRawValue().mailingIsAccept,
      phoneNumber: this.formInfoProfile.getRawValue().phoneNumber,
      lastName: this.formInfoProfile.getRawValue().lastName,
      firstName: this.formInfoProfile.getRawValue().firstName,
      middleName: this.formInfoProfile.getRawValue().middleName,
      dateBirth: this.formInfoProfile.getRawValue().dateBirth,
      genderCode: this.formInfoProfile.getRawValue().genderCode,
      statusCode: this.formInfoProfile.getRawValue().statusCode,
      reasonBlocking: this.formInfoProfile.getRawValue().reasonBlocking,
      corrLanguageCode: this.formInfoProfile.getRawValue().corrLanguageCode,
      noMiddleName: this.formInfoProfile.getRawValue().noMiddleName,
      userCreationDate: this.formInfoProfile.getRawValue().userCreationDate,
      noLastName: this.formInfoProfile.getRawValue().noLastName,
      noFirstName: this.formInfoProfile.getRawValue().noFirstName,
      firstNameEng: this.formInfoProfile.getRawValue().firstNameEng,
      lastNameEng: this.formInfoProfile.getRawValue().lastNameEng,
      middleNameEng: this.formInfoProfile.getRawValue().middleNameEng,
      agreedCodeConduct: this.formInfoProfile.getRawValue().agreedCodeConduct,
      consentProcessingPersonalData: this.formInfoProfile.getRawValue().consentProcessingPersonalData,
      photoId: this.formInfoProfile.getRawValue().photoId,
      digitalAvatarId: this.formInfoProfile.getRawValue().digitalAvatarId,
      titleCode: this.formInfoProfile.getRawValue().titleCode,
      extraInformation: {
        userUid: this.formInfoProfile.getRawValue().extraInformation.userUid,
        isNoHealthRestrictions: this.formInfoProfile.getRawValue().extraInformation.isNoHealthRestrictions,
        foodPreferenceCode: this.formInfoProfile.getRawValue().extraInformation.foodPreferenceCode,
        clothingSizeCode: this.formInfoProfile.getRawValue().extraInformation.clothingSizeCode,
        isParticipantMcd: this.formInfoProfile.getRawValue().extraInformation.isParticipantMcd,
        wantsParticipantMcd: this.formInfoProfile.getRawValue().extraInformation?.wantsParticipantMcd,
        directions: this.formInfoProfile.getRawValue().extraInformation.directions?.map((code: any) => ({directionCode: code})) || [],
        socialNetworks: [
          {
            socialNetworkTypeCode: 'vk',
            link: this.formInfoProfile.getRawValue().extraInformation.socialNetworks?.["vkLink"]
          },
          {
            socialNetworkTypeCode: 'facebook',
            link: this.formInfoProfile.getRawValue().extraInformation.socialNetworks?.["facebookLink"]
          },
          {
            socialNetworkTypeCode: 'telegram',
            link: this.formInfoProfile.getRawValue().extraInformation.socialNetworks?.["telegramLink"]
          },
          {
            socialNetworkTypeCode: 'instagram',
            link: this.formInfoProfile.getRawValue().extraInformation.socialNetworks?.["instagramLink"]
          },
        ].filter(sn => sn.link), // Удаляем пустые ссылки
        healthRestrictions: this.formInfoProfile.getRawValue().extraInformation.healthRestrictions?.["healthRestrictionCode"]?.map((code: any) => ({
          healthRestrictionCode: code,
          other: code === 'other' ? this.formInfoProfile.getRawValue().extraInformation.healthRestrictions?.["other"] : '',
        })) || [],
      },
      passportDetail: {
        userUid: this.formInfoProfile.getRawValue().passportDetail.userUid,
        isCheckedRegionsRussian: this.formInfoProfile.getRawValue().passportDetail.isCheckedRegionsRussian,
        citizenshipOksmCode: this.formInfoProfile.getRawValue().passportDetail.citizenshipOksmCode,
        identityDocTypeCode: this.formInfoProfile.getRawValue().passportDetail.identityDocTypeCode,
        series: this.formInfoProfile.getRawValue().passportDetail.series,
        number: this.formInfoProfile.getRawValue().passportDetail.number,
        issued: this.formInfoProfile.getRawValue().passportDetail.issued,
        dateIssued: this.formInfoProfile.getRawValue().passportDetail.dateIssued,
        dateExpires: this.formInfoProfile.getRawValue().passportDetail.dateExpires,
        placeBirth: this.formInfoProfile.getRawValue().passportDetail.placeBirth,
        residenceRegionCode: this.formInfoProfile.getRawValue().passportDetail.residenceRegionCode,
        residenceOksmCode: this.formInfoProfile.getRawValue().passportDetail.residenceOksmCode,
        cityResident: this.formInfoProfile.getRawValue().passportDetail.cityResident,
        registrationAddress: this.formInfoProfile.getRawValue().passportDetail.registrationAddress,
        actualDomicileAddress: this.formInfoProfile.getRawValue().passportDetail.actualDomicileAddress,
        documentId:  this.formInfoProfile.getRawValue().passportDetail.documentId,
        documentName:  this.formInfoProfile.getRawValue().passportDetail.documentName,
      },
      sphereActivity: {
        userUid: this.formInfoProfile.getRawValue().sphereActivity.userUid,
        positionName: this.formInfoProfile.getRawValue().sphereActivity.positionName,
        positionNameEng: this.formInfoProfile.getRawValue().sphereActivity.positionNameEng,
        workplace: this.formInfoProfile.getRawValue().sphereActivity.workplace,
        workplaceEng: this.formInfoProfile.getRawValue().sphereActivity.workplaceEng,
        engLanguageLevelCode: this.formInfoProfile.getRawValue().sphereActivity.engLanguageLevelCode,
        statusParticipants: this.formInfoProfile.getRawValue().sphereActivity.statusParticipants?.map((code: any) => ({
          statusParticipantCode: code,
        })),
      },
    } as Profile;
    this.sendFormInfoProfileAfterEdit(formInfoProfileAfterEdit);
  }

  // метод который выполняет запросы к api в зависимости в какйо части формы были изменения
  sendFormInfoProfileAfterEdit(formInfoProfileAfterEdit: any) {
    let requests = [];

    if (JSON.stringify(this.conversionToTheType(this.oldFormInfoProfile?.extraInformation)) !== JSON.stringify(this.conversionToTheType(this.formInfoProfile.getRawValue().extraInformation))) {
      requests.push(
        this.profileCardService.changeProfileUserExtraInformation(formInfoProfileAfterEdit?.extraInformation || {})
      );
    }

    if (JSON.stringify(this.conversionToTheType(this.oldFormInfoProfile?.sphereActivity)) !== JSON.stringify(this.conversionToTheType(this.formInfoProfile.getRawValue().sphereActivity))) {
      requests.push(
        this.profileCardService.changeProfileUserSphereActivity(formInfoProfileAfterEdit?.sphereActivity || {})
      );
    }

    if (JSON.stringify(this.conversionToTheType(this.oldFormInfoProfile?.passportDetail)) !==
      JSON.stringify(this.conversionToTheType(this.formInfoProfile.getRawValue().passportDetail))) {
      requests.push(
        this.profileCardService.changeProfileUserPassportData(formInfoProfileAfterEdit?.passportDetail || {})
      );
    }
    if (JSON.stringify(this.profileUserDataChangeInfo(this.conversionToTheType(this.oldFormInfoProfile))) !==
      JSON.stringify(this.profileUserDataChangeInfo(this.conversionToTheType((this.formInfoProfile.getRawValue()))))
    ) {
      requests.push(
        this.profileCardService.changeProfileUser(this.profileUserDataChangeInfo(formInfoProfileAfterEdit))
      );
    }
    if (requests.length > 0) {
      forkJoin(requests)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: () => {
            this.notify.success('Данные успешно изменены');
              this.getInfoProfileCard(this.formInfoProfile.getRawValue().userUid!);
          },
          error: (error) => {
            this.notify.error('Произошла ошибка при обновлении данных')
          },
        });
    }
    else if (requests.length === 0){
      this.getInfoProfileCard(this.formInfoProfile.getRawValue().userUid ?? 0);
    }
  }

  // сравнивает поля null и undefined и приходит их к одному типу и проходится по всем ключам
  conversionToTheType(data: any): any {
    if (data === null || data === undefined || data === '') return [];
    if (Array.isArray(data)) return data;
    if (typeof data === 'object') {
      const normalized: any = {};
      for (const key in data) {
        normalized[key] = this.conversionToTheType(data[key]);
      }
      return normalized;
    }
    return data;
  }

  // копию формы в метод без вложенностей
  profileUserDataChangeInfo(data: Profile) {
    const {extraInformation, sphereActivity, passportDetail, ...profile} = data;
    return profile;
  }

  // изменение статуса пользователя
  changeProfileStatusBlocked(userUid: number, reasonBlocking: string) {
    return this.profileCardService.changeProfileStatusBlocked(userUid, reasonBlocking)
  }

  changeProfileStatusUnblocked(userUid: number) {
    return this.profileCardService.changeProfileStatusUnblocked(userUid)
  }
  // изменение статуса пользователя


  // загрузка справочника
  loadDictionaries(dictionariesList: string[]) {
    return this.dictionaryService.loadDictionaries(dictionariesList)
  }

  canselForm(): void {
    this.isEdit.next(false);
  }

  // состояние disabled для полей которые редактировать нельзя
  getInfoFormEnable() {
    this.isEdit$.pipe(untilDestroyed(this)).subscribe(isEdit => {
      if (isEdit) {
        this.formInfoProfile.enable();
        this.formInfoProfile.get('userName')?.disable({onlySelf: true});
        this.formInfoProfile.get('userUid')?.disable({onlySelf: true});
        this.formInfoProfile.get('userCreationDate')?.disable({onlySelf: true});
        this.formInfoProfile.get('statusCode')?.disable({onlySelf: true});
        if ( this.formInfoProfile.get('statusCode')?.value === 'active'){
          this.formInfoProfile.get('reasonBlocking')?.disable({onlySelf: true});
        }
      } else {
        this.formInfoProfile.disable();
        // для фоток ,чтобы не пропадало отображение и не перезаписывалось каждый раз с disabled на null
        this.formInfoProfile.get('photo')?.enable({ onlySelf: true });
        this.formInfoProfile.get('digitalAvatar')?.enable({onlySelf: true });
      }
    });
  }
}
