import {Component, Input} from '@angular/core';
import {FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatInput} from "@angular/material/input";
import {ApplicationForm} from "../../../../core/interface/application/application.main.interface";

@Component({
  selector: 'app-social-application-information',
  imports: [
    FormsModule,
    MatInput,
    ReactiveFormsModule
  ],
  standalone: true,
  templateUrl: './social-information.component.html',
  styleUrl: './social-information.component.scss'
})
export class SocialInformationApplicationComponent {
  @Input() formInfoApplication!: FormGroup<ApplicationForm>
  @Input() dictionaries: { [key: string]: any; } | undefined
  isActive: boolean = true;

  getInfoSocial(socialNetworkTypeCode: string) : string {
    return this.dictionaries?.['socialnetworktypes']?.find((item: { code: string, name: string }) => item.code === socialNetworkTypeCode)?.name;
  }

}
