import { Injectable } from '@angular/core';
import {BehaviorSubject, forkJoin} from "rxjs";
import { FormControl, FormGroup} from "@angular/forms";
import {ExtraInformationForm} from "../../../core/interface/profile-card/profile-extraInfo-interface";
import {PassportForm} from "../../../core/interface/profile-card/profile-passport.interface";
import {SphereActivityForm} from "../../../core/interface/profile-card/profile-sphereActivity.interface";
import {PhotoForm} from "../../../core/interface/profile-card/profile-card-photo.interface";
import {NotificationService} from "../../../core/services/notification/notification.service";
import {DictionaryService} from "../../../core/services/pages/dictionary.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ApplicationForm} from "../../../core/interface/application/application.main.interface";

import {ApplicationCardService} from "../../../core/services/pages/application/application-card.service";
@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class ApplicationCardMainService {
  isEdit = new BehaviorSubject<boolean>(false);
  isEdit$ = this.isEdit.asObservable();
  formInfoApplication: FormGroup<ApplicationForm> = new FormGroup<ApplicationForm>({
    isTest: new FormControl<boolean | undefined | null>(null),
    userUid: new FormControl<number | null>(null),
    userName: new FormControl<string | null | undefined>(null),
    mailingIsAccept: new FormControl<boolean | null | undefined>(null),
    phoneNumber: new FormControl<string | null | undefined>(null),
    statusCode: new FormControl<string | null | undefined>(null),
    reasonBlocking: new FormControl<string | null | undefined>(null),
    lastName: new FormControl<string | null | undefined>(null),
    firstName: new FormControl<string | null | undefined>(null),
    middleName: new FormControl<string | null | undefined>(null),
    dateBirth: new FormControl<string | Date | null | undefined>(null),
    noMiddleName: new FormControl<boolean | null | undefined>(null),
    noLastName: new FormControl<boolean | null | undefined>(null),
    noFirstName: new FormControl<boolean | null | undefined>(null),
    firstNameEng: new FormControl<string | null | undefined>(null),
    lastNameEng: new FormControl<string | null | undefined>(null),
    middleNameEng: new FormControl<string | null | undefined>(null),
    genderCode: new FormControl<string | null | undefined>(null),
    corrLanguageCode: new FormControl<string | null | undefined>(null),
    titleCode: new FormControl<string | null | undefined>(null),
    userCreationDate: new FormControl<Date | string | undefined | null>(null),
    digitalAvatarId: new FormControl<number | undefined | null>(null),
    agreedCodeConduct: new FormControl<boolean | null | undefined>(null),
    consentProcessingPersonalData: new FormControl<boolean | null | undefined>(null),
    photoId: new FormControl<number | null | undefined>(null),
    eventCode: new FormControl<string | undefined | null>(null),
    applicationUid: new FormControl<string | undefined | null>(null),
    moderationStateCode: new FormControl<string | undefined | null>(null),
    dateOfRequest: new FormControl<string | undefined | null>(null),
    reasonForRefusal: new FormControl<string | undefined | null>(null),
    dateOfApproval: new FormControl<string | undefined | null>(null),
    statusDVFM: new FormControl<string | undefined | null>(null),
    departureOksmCode: new FormControl<string | undefined | null>(null),
    departureCityCode: new FormControl<string | undefined | null>(null),
    ownExpense: new FormControl<boolean | undefined | null>(null),
    visaIsNotRequired: new FormControl<boolean | undefined | null>(null),
    visaIssuingCityCode: new FormControl<string | undefined | null>(null),
    confirmationVisaInvitation: new FormControl<boolean | undefined | null>(null),
    visaInvitationId: new FormControl<number | null | undefined>(null),
    regProgramCityCode:  new FormControl<string | null | undefined>(null),
    regProgramStateCode: new FormControl<string | null | undefined>(null),
    travelTicketFileId: new FormControl<number | null | undefined>(null),
    visaInvitationFile : new FormGroup<PhotoForm>({
      hash: new FormControl<string | null | undefined>(null),
      name: new FormControl<string | null | undefined>(null),
    }),
    travelTicketFile : new FormGroup<PhotoForm>({
      hash: new FormControl<string | null | undefined>(null),
      name: new FormControl<string | null | undefined>(null),
    }),
    extraInformation: new FormGroup<ExtraInformationForm>({
      userUid: new FormControl<number | null | undefined>(null),
      healthRestrictions: new FormGroup<any>({
        other: new FormControl<string | null | undefined>(null),
        healthRestrictionCode: new FormControl<string | null | undefined>(null),
      }),
      isParticipantMcd: new FormControl<boolean | null | undefined>(null),
      directions: new FormControl([]),
      isNoHealthRestrictions: new FormControl<boolean | null | undefined>(null),
      foodPreferenceCode: new FormControl<string | null | undefined>(null),
      clothingSizeCode: new FormControl<string | null | undefined>(null),
      wantsParticipantMcd: new FormControl<boolean | null | undefined>(null),
      // passportParticipantMcdId: new FormControl<number | null | undefined>(null),
      socialNetworks: new FormGroup<any>({
        vk: new FormControl<string | null | undefined>(null),
        vkLink: new FormControl<string | null | undefined>(null),
        facebook: new FormControl<string | null | undefined>(null),
        facebookLink: new FormControl<string | null | undefined>(null),
        telegram: new FormControl<string | null | undefined>(null),
        telegramLink: new FormControl<string | null | undefined>(null),
        instagram: new FormControl<string | null | undefined>(null),
        instagramLink: new FormControl<string | null | undefined>(null),
      }),
    }),
    passportDetail: new FormGroup<PassportForm>({
      userUid: new FormControl<number | null | undefined>(null),
      actualDomicileAddress: new FormControl<string | null | undefined>(null),
      citizenshipOksmCode: new FormControl<string | null | undefined>(null),
      isCheckedRegionsRussian: new FormControl<boolean | null | undefined>(false),
      cityResident: new FormControl<string | null | undefined>(null),
      dateIssued: new FormControl<string | Date | null | undefined>(null),
      dateExpires: new FormControl<string | Date | null | undefined>(null),
      documentId: new FormControl<number | null | undefined>(null),
      documentName: new FormControl<string | null | undefined>(null),
      // document: new FormGroup<DocumentForm>({
      //   id: new FormControl<number | null>(null),
      // }),
      identityDocTypeCode: new FormControl<string | null | number>(null),
      number: new FormControl<string | null | undefined>(null),
      issued: new FormControl<string | null | undefined>(null),
      placeBirth: new FormControl<string | null | undefined>(null),
      registrationAddress: new FormControl<string | null | undefined>(null),
      residenceOksmCode: new FormControl<string | null | undefined>(null),
      residenceRegionCode: new FormControl<string | null | undefined>(null),
      series: new FormControl<string | null | undefined>(null),
    }),
    sphereActivity: new FormGroup<SphereActivityForm>({
      userUid: new FormControl<number | null | undefined>(null),
      positionName: new FormControl<string | null | undefined>(null),
      positionNameEng: new FormControl<string | null | undefined>(null),
      workplace: new FormControl<string | null | undefined>(null),
      workplaceEng: new FormControl<string | null | undefined>(null),
      engLanguageLevelCode: new FormControl<string | null | undefined>(null),
      statusParticipants: new FormControl<any | [] | null>(null),
    }),
    photo: new FormGroup<PhotoForm>({
      hash: new FormControl<string | null | undefined>(null),
      name: new FormControl<string | null | undefined>(null),
    }),
    digitalAvatar: new FormGroup<PhotoForm>({
      hash: new FormControl<string | null | undefined>(null),
      name: new FormControl<string | null | undefined>(null),
    }),
  });

  constructor(private applicationCardService: ApplicationCardService,
              private notify: NotificationService,
              private dictionaryService: DictionaryService) {
    this.getInfoFormEnable()
  }

  isEditForm(): void {
    if (!this.isEdit.value) {
    }
    this.isEdit.next(!this.isEdit.value);
  }

  getInfoApplicationCard(codeEvent: string,userUid: number) {
    forkJoin([

      this.applicationCardService.getInfoFileApplication(codeEvent, userUid),
      this.applicationCardService.getInfoCardIntegration(userUid),
      this.applicationCardService.getInfoCardApplicationCrm(userUid),
      this.applicationCardService.getInfoCardApplication(codeEvent,userUid),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([fileInfo, infoIntegration,infoCrm,info]) => {
        this.formInfoApplication.patchValue({
          eventCode: info.eventCode ,
          applicationUid: info.applicationUid ,
          moderationStateCode: info.moderationStateCode.toLowerCase(),
          dateOfRequest: info.dateOfRequest ,
          reasonForRefusal: info.reasonForRefusal ,
          dateOfApproval: info.dateOfApproval ,
          statusDVFM: info.statusDVFM ,
          departureOksmCode: info.departureOksmCode ,
          departureCityCode: info?.departureCityCode?.toLowerCase(),
          ownExpense: info.ownExpense ,
          visaIsNotRequired: info.visaIsNotRequired,
          visaIssuingCityCode: info.visaIssuingCityCode ,
          confirmationVisaInvitation: info.confirmationVisaInvitation,

          visaInvitationId: fileInfo.visaInvitationId,
          regProgramCityCode: fileInfo.regProgramCityCode,
          regProgramStateCode: fileInfo.regProgramStateCode,
          travelTicketFileId: fileInfo.travelTicketFileId,
          visaInvitationFile:{
            name: fileInfo.visaInvitationFile?.name,
            hash: fileInfo.visaInvitationFile?.hash,
          },
          travelTicketFile:{
            name: fileInfo.travelTicketFile?.name,
            hash: fileInfo.travelTicketFile?.hash,
          },
          isTest: infoCrm.isTest,
          userUid: infoCrm.userUid || null,
          userName: infoCrm.userName || null,
          mailingIsAccept: infoCrm.mailingIsAccept,
          phoneNumber: infoCrm.phoneNumber || null,
          lastName: infoCrm.lastName || null,
          firstName: infoCrm.firstName || null,
          middleName: infoCrm.middleName || null,
          dateBirth: infoCrm.dateBirth || null,
          genderCode: infoCrm.genderCode || null,
          statusCode: infoCrm.statusCode || null,
          reasonBlocking: infoIntegration.reasonBlocking || null,
          corrLanguageCode: infoCrm.corrLanguageCode || null,
          userCreationDate: infoCrm.userCreationDate || null,
          noMiddleName: infoCrm.noMiddleName,
          noLastName: infoCrm.noLastName,
          noFirstName: infoCrm.noFirstName,
          firstNameEng: infoIntegration.firstNameEng || null,
          lastNameEng: infoIntegration.lastNameEng || null,
          middleNameEng: infoIntegration.middleNameEng || null,
          agreedCodeConduct: infoIntegration.agreedCodeConduct || null,
          consentProcessingPersonalData: infoIntegration.consentProcessingPersonalData || null,
          photoId: infoIntegration.photoId || null,
          digitalAvatarId: infoIntegration.digitalAvatarId || null,
          titleCode: infoIntegration.titleCode || null,
          digitalAvatar: {
            hash: infoIntegration.digitalAvatar?.hash || null,
            name: infoIntegration.digitalAvatar?.name || null
          },
          photo: {
            hash: infoIntegration.photo?.hash || null,
            name: infoIntegration.photo?.name || null,
          },
          extraInformation: {
            userUid: infoCrm.userUid || null,
            isNoHealthRestrictions: infoIntegration.extraInformation?.isNoHealthRestrictions,
            foodPreferenceCode: infoIntegration.extraInformation?.foodPreferenceCode || null,
            clothingSizeCode: infoIntegration.extraInformation?.clothingSizeCode || null,
            isParticipantMcd: infoCrm.extraInformation?.isParticipantMcd,
            directions: Array.isArray(infoCrm.extraInformation?.directions) ?
              infoCrm.extraInformation.directions.map((direction: { directionCode: any; }) => direction.directionCode) : [],
            socialNetworks: (infoIntegration.extraInformation?.socialNetworks || []).reduce(
              (acc: Record<string, string | null>, item: { socialNetworkTypeCode: string; link: string }) => {
                if (item.socialNetworkTypeCode) {
                  acc[item.socialNetworkTypeCode] = item.socialNetworkTypeCode || null;
                  acc[`${item.socialNetworkTypeCode}Link`] = item.link || null;
                }
                return acc;
              },
              {
                vk: null, vkLink: null,
                instagram: null, instagramLink: null,
                telegram: null, telegramLink: null,
                facebook: null, facebookLink: null,
              }
            ),
            healthRestrictions: {
              userUid: infoCrm.userUid || 'Не заполнено',
              other: infoIntegration.extraInformation?.healthRestrictions ?
                infoIntegration.extraInformation?.healthRestrictions.map((other: { other: any; }) => other.other).join('')
                : '',
              healthRestrictionCode: Array.isArray(infoIntegration.extraInformation?.healthRestrictions) ?
                infoIntegration.extraInformation?.healthRestrictions.map((status: { healthRestrictionCode: any; }) => status.healthRestrictionCode) : [],
            },
            wantsParticipantMcd: infoCrm.extraInformation?.wantsParticipantMcd,
          },
          passportDetail: {
            userUid: infoCrm.userUid || 'Не заполнено',
            isCheckedRegionsRussian: infoCrm.passportDetail?.residenceOksmCode === 'rus' ? true : false,
            citizenshipOksmCode: infoCrm.passportDetail?.citizenshipOksmCode,
            identityDocTypeCode: infoIntegration.passportDetail?.identityDocTypeCode || null,
            series: infoIntegration.passportDetail?.series || null,
            number: infoIntegration.passportDetail?.number || null,
            issued: infoIntegration.passportDetail?.issued || null,
            dateIssued: infoIntegration.passportDetail?.dateIssued || null,
            dateExpires: infoIntegration.passportDetail?.dateExpires || null,
            placeBirth: infoIntegration.passportDetail?.placeBirth || null,
            residenceRegionCode: infoCrm.passportDetail?.residenceRegionCode || null,
            residenceOksmCode: infoCrm.passportDetail?.residenceOksmCode || null,
            cityResident: infoIntegration.passportDetail?.cityResident || null,
            registrationAddress: infoIntegration.passportDetail?.registrationAddress || null,
            actualDomicileAddress: infoIntegration.passportDetail?.actualDomicileAddress || null,
            documentId: infoIntegration.passportDetail?.documentId,
            documentName: infoIntegration.passportDetail?.documentName,
          },
          sphereActivity: {
            userUid: infoCrm.userUid || null,
            positionName: infoIntegration.sphereActivity?.positionName || null,
            positionNameEng: infoIntegration.sphereActivity?.positionNameEng || null,
            workplace: infoIntegration.sphereActivity?.workplace || null,
            workplaceEng: infoIntegration.sphereActivity?.workplaceEng || null,
            engLanguageLevelCode: infoIntegration.sphereActivity?.engLanguageLevelCode || null,
            statusParticipants: Array.isArray(infoIntegration.sphereActivity?.statusParticipants) ?
              infoIntegration.sphereActivity?.statusParticipants.map((status: { statusParticipantCode: any; }) => status.statusParticipantCode) : [],
          },
        });
        this.isEdit.next(false)
      })
  }

  changeTicketApplication(userUid: number | undefined | null,codeEvent: string | undefined | null,ownExpense: boolean){
    return  this.applicationCardService.changeTicketApplication(userUid, codeEvent,ownExpense)
  }
  changeLocationRegprogram(userUid: number | null | undefined,codeEvent: string | undefined | null,locationCode: string | undefined | null){
    return  this.applicationCardService.changeLocationRegprogram(userUid, codeEvent,locationCode)
  }

  getInfoLocationCity(codeEvent: string | undefined | null){
    return this.applicationCardService.getInfoLocationCity(codeEvent)
  }
  addVisaInvitationGenerate( userUid: number | undefined | null,codeEvent: string | undefined | null,) {
    return  this.applicationCardService.addVisaInvitationGenerate(userUid, codeEvent)
  }
  changeApplicationsState(userUid: number | undefined | null,codeEvent: string | undefined | null,moderationStateCode: string) {
    return  this.applicationCardService.changeApplicationStatus(userUid,codeEvent,moderationStateCode)
  }
  changeApplicationsStateRP(userUid: number | undefined | null,codeEvent: string | undefined | null,moderationStateCode: string) {
    return  this.applicationCardService.changeApplicationStatusRP(userUid,codeEvent,moderationStateCode)
  }

  changeApplicationStatusDvfm(userUid: number | undefined | null,codeEvent: string | undefined | null,moderationStateCode: string) {
    return  this.applicationCardService.changeApplicationStatusDvfm(userUid,codeEvent,moderationStateCode)
  }
  getInfoAnswersQuestions(userUid: number | undefined | null,codeEvent: string | undefined | null){
    return  this.applicationCardService.getInfoAnswersQuestions(userUid,codeEvent)
  }
  // загрузка справочника
  loadDictionaries(dictionariesList: string[]) {
    return this.dictionaryService.loadDictionaries(dictionariesList)
  }

  canselForm(): void {
    this.isEdit.next(false);
  }


  getInfoFormEnable() {
    this.formInfoApplication.disable();
    this.formInfoApplication.get('travelTicketFile')?.enable({ onlySelf: true });
    this.formInfoApplication.get('visaInvitationFile')?.enable({onlySelf: true });
    this.formInfoApplication.get('visaInvitationId')?.enable({ onlySelf: true });
    this.formInfoApplication.get('travelTicketFileId')?.enable({ onlySelf: true });
    this.formInfoApplication.get('photo')?.enable({ onlySelf: true });
    this.formInfoApplication.get('digitalAvatar')?.enable({onlySelf: true });

  }
}
