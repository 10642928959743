import {Component, Input} from '@angular/core';

import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgForOf, NgIf} from "@angular/common";


@Component({
  selector: 'app-answers-questions',
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgForOf,
    NgIf
  ],
  standalone: true,
  templateUrl: './answers-questions.component.html',
  styleUrl: './answers-questions.component.scss'
})
export class AnswersQuestionsComponent {
  @Input()questions: string[] | undefined
  isActive: boolean = true;
}
