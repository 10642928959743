import {Component, Input} from '@angular/core';
import {
    CustomSelectionComponent
} from "../../../../../assets/shared/components/select/custom-selection/custom-selection.component";
import {NgForOf} from "@angular/common";
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {ProfileForm} from "../../../../core/interface/profile-card/profile-card-main.interface";

import {MatInput} from "@angular/material/input";

@Component({
  selector: 'app-field-activity-information',
  standalone: true,
    imports: [
        CustomSelectionComponent,
        NgForOf,
        ReactiveFormsModule,
        MatInput
    ],
  templateUrl: './field-activity-information.component.html',
  styleUrl: './field-activity-information.component.scss'
})
export class FieldActivityInformationComponent {
    @Input() formInfoProfile!: FormGroup<ProfileForm>
    @Input() dictionaries: { [key: string]: any; } | undefined
    isActive: boolean = true;
    isActiveSelect: boolean = false;

    contentItemLabel = [
        {name : 'Статус', value: 'positionName'},
        {name : 'Должность', value: 'positionName'},
        {name : 'Место работы/учебы', value: 'workplace'},
        {name : 'Место работы/учебы на англ', value: 'workplaceEng'},
        {name : 'Должность на англ', value: 'positionNameEng'},
        {name : 'Уровень владения английским языком', value: 'languagelevels'},
    ]

    checkIfActive(event: boolean) {
        this.isActiveSelect = event;
    }
}
