import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgForOf, NgIf, NgStyle} from "@angular/common";
import {MatCheckbox, MatCheckboxChange} from "@angular/material/checkbox";
import {HeadersTableInterface} from "../../../../app/core/interface/table/headers-table.interface";
import {TableFilterModalComponent} from "../table-filter-modal/table-filter-modal.component";
import {UntilDestroy} from "@ngneat/until-destroy";

import {TableInfo} from "../../../../app/core/interface/table/table-info";

@UntilDestroy()
@Component({
  selector: 'app-table',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    MatCheckbox,
    TableFilterModalComponent,
    NgStyle,
  ],
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss'
})


export class TableComponent {
  @Input() tableInfo!: Partial<TableInfo>;
  @Input() isMainCheckboxChecked!: boolean
  @Input() dictionaries: { [key: string]: any; } | undefined
  @Input() selectedIds: number[] | undefined
  @Input() inverseOfTranslationHeader!: string
  @Output() triggerCheckAll: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() trigger: EventEmitter<void> = new EventEmitter<void>();

  activeColumnFilterCode: string | null = null;

  triggerCheck(event: MatCheckboxChange) {
    this.triggerCheckAll.emit(event.checked);
  }
  onColumnFilterClick(code: string): void {
    if (this.activeColumnFilterCode === code) {
      this.activeColumnFilterCode = null;
    } else {
      this.activeColumnFilterCode = code;
    }
  }
  onClose(){
    this.activeColumnFilterCode = null;
    this.tableInfo.skip = 0
    this.trigger.emit();
  }

  onSelectedSortColumn(column: HeadersTableInterface) {
    if (this.tableInfo.orderByColumn != column.code) {
      this.tableInfo.orderByColumn = column.code;
      this.tableInfo.orderByDescending = false;
    } else if (this.tableInfo.orderByColumn && this.tableInfo.orderByDescending == false) {
      this.tableInfo.orderByColumn = column.code;
      this.tableInfo.orderByDescending = true;
    } else {
      this.tableInfo.orderByColumn = undefined;
      this.tableInfo.orderByDescending = undefined;
    }
    this.trigger.emit();
  }



}
