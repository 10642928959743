<div *ngIf="menuItems" class="menu-item">
  <div *ngFor="let menuItem of menuItems" class="other-info">
    <div class="block-arrow"
         (click)="menuItem.subMenu ? toggleSubMenu(menuItem) : onMenuItemClick(menuItem)">
      <span [ngClass]="{'active-dropdown': isOpenMenuItem === menuItem.label}"
            class="setting-profile-text">
        {{ menuItem.label }}
      </span>
      <svg *ngIf="menuItem?.subMenu"
           [ngClass]="{'rotate-180': isOpenMenuItem === menuItem.label}"
           class="arrow-icon"
           width="14"
           height="9"
           viewBox="0 0 14 9"
           fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path [attr.fill]="isOpenMenuItem === menuItem.label ? '#FF6B00' : '#252525'"
              d="M0.334637 1.35033L1.51797 0.166992L7.0013 5.65033L12.4846 0.166992L13.668 1.35033L7.0013 8.01699L0.334637 1.35033Z"/>
      </svg>

    </div>

    <div *ngIf="menuItem?.subMenu && isOpenMenuItem === menuItem.label"
         class="other-info sub-menu">
      <span *ngFor="let subMenuItem of menuItem?.subMenu"
            (click)="onMenuItemClick(subMenuItem?.label);subMenuItem?.action()"
            class="setting-profile-text">
        {{ subMenuItem?.label }}
      </span>
    </div>
  </div>
</div>
