<div  class="modal-filter">
  <div class="filter">
    <div class="select-header"
         [ngClass]="{'remove-after': !((column.type == 'dictionary' || column.type == 'dictionaryList') && column.dictionaryName != null)}">

      <ng-container *ngIf="column.type == 'string'">
        <!--operation 2 1 3 4-->
        <mat-form-field  appearance="outline" class="custom-selection-search custom-filter-table">
          <img [class.rotate-180]="isActiveOperation" class="arrow-custom-select" src="assets/shared/components/select/img-select/select-arrow.svg">
          <mat-select [(ngModel)]="column.operation" (openedChange)="checkIfActive($event)">
            <div class="select-text">
            <mat-option  [value]="1">Равно</mat-option>
            <mat-option [value]="2">Содержит</mat-option>
            <mat-option [value]="3">Начинается с</mat-option>
            <mat-option [value]="4">Заканчивается</mat-option>
            </div>
          </mat-select>
        </mat-form-field>
        <input placeholder="Поиск"
               [(ngModel)]="value" class="filter-modal-input" matInput>
      </ng-container>

      <ng-container *ngIf="column.type == 'bool'">
        <mat-form-field  appearance="outline" class="custom-selection-search custom-filter-table">
          <img [class.rotate-180]="isActiveOperation" class="arrow-custom-select" src="assets/shared/components/select/img-select/select-arrow.svg">
          <mat-select placeholder="Выберите из списка" [(ngModel)]="value" (openedChange)="checkIfActive($event)">
            <div class="select-text">
              <mat-option [value]="true">Да</mat-option>
              <mat-option [value]="false">Нет</mat-option>
            </div>
          </mat-select>
        </mat-form-field>

      </ng-container>
      <ng-container *ngIf="column.type == 'number'">
        <mat-form-field  appearance="outline" class="custom-selection-search custom-filter-table">
          <img [class.rotate-180]="isActiveOperation" class="arrow-custom-select" src="assets/shared/components/select/img-select/select-arrow.svg">
          <mat-select [(ngModel)]="column.operation" (openedChange)="checkIfActive($event)">
            <div class="select-text">
            <mat-option [value]="1">Равно</mat-option>
            <mat-option [value]="5">Между</mat-option>
            </div>
          </mat-select>
        </mat-form-field>
        <input placeholder="Поиск"
               [(ngModel)]="value" class="filter-modal-input" matInput type="number">
        <input *ngIf="column.operation == 5" placeholder="Поиск"
               [(ngModel)]="subValue" class="filter-modal-input" matInput type="number">
      </ng-container>

      <ng-container *ngIf="column.type == 'datetime'">
        <div class="custom-datepicker multi-datepicker">
          <mat-form-field>
            <mat-date-range-input  [rangePicker]="picker">
              <input app-validator-date [date-value]="value" [isStartDate]="true" matStartDate placeholder="Нач.дата" [(ngModel)]="value" (dateChange)="onStartDateChange($event)">
              <input app-validator-date [date-value]="subValue"  matEndDate  placeholder="Кон.дата" [(ngModel)]="subValue" (dateChange)="onEndDateChange($event)" >
            </mat-date-range-input>
            <mat-datepicker-toggle  matIconSuffix  [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker [dateClass]="isWeekendDay" #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
      </ng-container>


      <ng-container *ngIf="(column.type == 'dictionary' || column.type == 'dictionaryList') && column.dictionaryName != null">
        <!--operation 5-->
        <input placeholder="Поиск по фильтру"
               [(ngModel)]="valueFilterDictionariest"
               (keyup)="valueFilterOption()"
               class="filter-modal-input" matInput>

        <div *ngIf="column.dictionaryName != null" class="main-check-box">
          <mat-checkbox
            [checked]="isAllSelected()"
            (change)="toggleAllCheckboxes($event)">Выделить все</mat-checkbox>
        </div>
      </ng-container>
    </div>

    <div *ngIf="column.dictionaryName && dictionaries && dictionaries[column.dictionaryName]" class="checkbox-selected">


      <mat-checkbox *ngFor="let option of filteredDictionaries"
                    [value]="option.code"
                    [checked]="isOptionSelected(option.code)"
                    (change)="onCheckboxChange(option.code, $event)">
        {{ option.name }}</mat-checkbox>
    </div>
  </div>

  <div  class="bth-search">
    <button  (click)="onClose()" class="button-red"><span class="cancel">Отменить</span></button>
    <button (click)="onSave()" class="button-green"><span class="accept">Применить</span></button>
  </div>
</div>
