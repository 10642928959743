import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormField} from "@angular/material/form-field";
import {MatOption, MatSelect, MatSelectModule} from "@angular/material/select";

import {NgForOf, NgIf} from "@angular/common";
import {MatCheckbox, MatCheckboxModule} from "@angular/material/checkbox";
import {MatOptionModule} from "@angular/material/core";

import {TableInfo} from "../../../../../app/core/interface/table/table-info";
import {UntilDestroy} from "@ngneat/until-destroy";


@UntilDestroy()
@Component({
  selector: 'custom-selection',
  standalone: true,
  templateUrl: './custom-selection.component.html',
  imports: [
    MatFormField,
    MatSelectModule,
    MatOptionModule,
    MatCheckboxModule,
    MatSelect,
    ReactiveFormsModule,
    FormsModule,
    MatOption,
    NgForOf,
    NgIf,
    MatCheckbox
  ],
  styleUrls: ['./custom-selection.component.scss']
})
export class CustomSelectionComponent implements OnInit{
  @Input() value!:  Partial<TableInfo>;
  @Input() options: any[] = [];
  @Input() multipleCheckBox = false;
  @Input() control: FormControl<any | null | undefined | string | {}> = new FormControl([]);
  @Input() showCountOnly: boolean = false;
  @Input() optionsSelected: any[] = [];

  @ViewChild(MatSelect) matSelect!: MatSelect;

  isActive: boolean = false;
  dictionaries: { [key: string]: any; } | undefined

  ngOnInit() {
    this.control.setValue(this.value?.quickSearch?.fields);
  }

  accept(){
    this.matSelect.close();
  }
  cancel() {
    this.control.setValue([]);
    this.matSelect.close();
    this.optionsSelected = []
  }

  getSelectedCount(): string {
    if (this.multipleCheckBox) {
      if (this.showCountOnly) {
        if (!this.control?.value || this.control.value.length === 0) {
          return 'Не заполнено';
        }
        return `Выбрано ${this.control.value.length} из ${this.options.length}`;
      } else {
        // Отображение списка выбранных значений
        if (this.control?.value && Array.isArray(this.options)) {
          return this.options
            .filter(option => this.control.value.includes(option.code))
            .map(option => option.name)
            .join(', ') || 'Не заполнено';
        }
        return 'Не заполнено';
      }
    } else {
      // Для одиночного выбора
      if (Array.isArray(this.options) && this.options.length > 0) {
        return this.options.find(option => option.code === this.control?.value)?.name || 'Не заполнено';
      }
      return '';
    }
  }



  selectedAll(isChecked: boolean) {
    if (isChecked) {
      this.control.setValue(this.options.map(o => o.code));
      this.optionsSelected = [...this.options.map(o => o.code)];
    } else {
      this.control.setValue([]);
      this.optionsSelected = [];
    }
    if (this.value?.quickSearch) {
      this.value.quickSearch.fields = [...this.optionsSelected];
    }
  }

  isSelectedCheckAll(): boolean {
    return Array.isArray(this.control.value) && this.options && this.control.value.length === this.options.length;
  }


  checkIfActive(event: boolean) {
    this.isActive = event;
  }

  getInfoOptionCode(optionCode: number) {
    if (this.optionsSelected.indexOf(optionCode) === -1) {
      this.optionsSelected.push(optionCode);
    } else {
      this.optionsSelected.splice(this.optionsSelected.indexOf(optionCode), 1);
    }
    if (this.value?.quickSearch) {
      this.value.quickSearch.fields = [...this.optionsSelected];
    }

  }
}
