import { Component } from '@angular/core';
import {Observable} from "rxjs";
import {LoaderService} from "./loader.service";
import {AsyncPipe, NgIf} from "@angular/common";
import {NgxSpinnerComponent} from "ngx-spinner";

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [
    NgxSpinnerComponent,
    NgIf,
    AsyncPipe
  ],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss'
})
export class LoaderComponent {
  isLoad: Observable<boolean>

  constructor (private loaderService :LoaderService) {
    this.isLoad = loaderService.isLoad$;
  }
}
