<div [class.margin-bottom-unset]="!isActive"
     [formGroup]="formInfoApplication"
      class="form">
  <div (click)="isActive = !isActive" class="label-form">
    <img [class.rotate-180]="isActive" src="assets/images/ul-kit-img/arrow-black.svg">
    <span>Социальные сети</span>
  </div>
  <div formGroupName="extraInformation" [class.active]="isActive"
       class="form-content">
    <div class="left-content">
      <div  class="item-content-label">
        <img src="assets/images/ul-kit-img/green-point.svg">
        <span class="text-inter-regular">{{getInfoSocial(formInfoApplication.value.extraInformation?.socialNetworks.vk || 'vk')}}</span>
      </div>
      <div  class="item-content-label">
        <img src="assets/images/ul-kit-img/green-point.svg">
        <span class="text-inter-regular">{{getInfoSocial(formInfoApplication.value.extraInformation?.socialNetworks.instagram || 'instagram')}}</span>
      </div>

      <div  class="item-content-label">
        <img src="assets/images/ul-kit-img/green-point.svg">
        <span class="text-inter-regular">{{getInfoSocial(formInfoApplication.value.extraInformation?.socialNetworks.telegram || 'telegram')}}</span>
      </div>

      <div  class="item-content-label">
        <img src="assets/images/ul-kit-img/green-point.svg">
        <span class="text-inter-regular">{{getInfoSocial(formInfoApplication.value.extraInformation?.socialNetworks.facebook || 'facebook')}}</span>
      </div>
    </div>

    <div [class.active]="isActive" class="form-content">
      <div formGroupName="socialNetworks" class="right-content">
        <div  class="item-info">
          <input
            formControlName="vkLink"
            [placeholder]="formInfoApplication.get('vkLink')?.value === null ? 'Не заполнено' : ''"
            class="text-inter-semibold input-main-search"
            matInput
          >
        </div>
        <div  class="item-info">
          <input
            formControlName="instagramLink"
            [placeholder]="formInfoApplication.get('instagramLink')?.value === null ? 'Не заполнено' : ''"
            class="text-inter-semibold input-main-search"
            matInput
          >
        </div>
        <div  class="item-info">
          <input
            formControlName="telegramLink"
            [placeholder]="formInfoApplication.get('telegramLink')?.value === null ? 'Не заполнено' : ''"
            class="text-inter-semibold input-main-search"
            matInput
          >
        </div>
        <div  class="item-info">
          <input
            formControlName="facebookLink"
            [placeholder]="formInfoApplication.get('facebookLink')?.value === null ? 'Не заполнено' : ''"
            class="text-inter-semibold input-main-search"
            matInput
          >
        </div>
      </div>
    </div>
  </div>
</div>

