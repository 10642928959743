import { Injectable } from '@angular/core';

import {environment} from "../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Application} from "../../../interface/application/application.main.interface";
import {FileApplicationInterface} from "../../../interface/application/file-application.interface";
import {Profile} from "../../../interface/profile-card/profile-card-main.interface";
import {Photo} from "../../../interface/profile-card/profile-card-photo.interface";
import {Document} from "../../../interface/profile-card/profile-card-document.intareface";

@Injectable({
  providedIn: 'root'
})
export class ApplicationCardService {

  constructor(private http: HttpClient) { }

  getInfoLocationCity(codeEvent: string | undefined | null){
    return this.http.get<string[]>(`${environment.requestBaseUrl}/api/v1-web/Application/GetRegProgLocations?eventCode=${codeEvent}`)
  }
  getInfoCardApplicationCrm(userUid: number){
    return this.http.get<Profile>(`${environment.baseUrl}/api/v1-web/UserProfile/Detail?userUid=${userUid}`)
  }

  getInfoCardIntegration(userUid: number){
    return this.http.get<Profile>(`${environment.integrationBaseUrl}/api/IntegrationCrm/GetProfileData?userUid=${userUid}`)
  }


  getInfoCardApplication(codeEvent: string,userUid: number){
    return this.http.get<Application>(`${environment.baseUrl}/api/v1-web/Application/GetApplicationInfo?userUid=${userUid}&eventCode=${codeEvent}`)
  }

  getInfoFileApplication(codeEvent: string,userUid: number){
    return this.http.get<FileApplicationInterface>(`${environment.requestBaseUrl}/api/v1-web/Application/GetApplicationInfo?userUid=${userUid}&eventCode=${codeEvent}`)
  }
   getInfoAnswersQuestions(userUid: number | undefined | null,codeEvent: string | undefined | null){
    return this.http.get<string[]>(`${environment.requestBaseUrl}/api/v1-web/Application/GetPollAnswer?userUid=${userUid}&eventCode=${codeEvent}`)
   }
  changeApplicationStatus(userUid: number | undefined | null,codeEvent: string | undefined | null,moderationStateCode: string){
    return this.http.get<string>(`${environment.requestBaseUrl}/api/v1-web/IntegrationCrm/ChangeApplicationStatus?userUid=${userUid}&eventCode=${codeEvent}&moderationStateCode=${moderationStateCode}`)
  }

  changeApplicationStatusDvfm(userUid: number | undefined | null,codeEvent: string | undefined | null,moderationStateCode: string){
    return this.http.get<string>(`${environment.requestBaseUrl}/api/v1-web/Application/ChangeStatusDvfm?userUid=${userUid}&eventCode=${codeEvent}&moderationStateCode=${moderationStateCode}`)
  }
  changeTicketApplication(userUid: number | undefined | null,codeEvent: string | undefined | null,ownExpense: boolean){
    return  this.http.get<string>(`${environment.requestBaseUrl}/api/v1-web/Application/ChangeStatusTicket?userUid=${userUid}&eventCode=${codeEvent}&ownExpense=${ownExpense}`)
  }
  changeApplicationStatusRP(userUid: number | undefined | null,codeEvent: string | undefined | null,moderationStateCode: string){
    return this.http.get<string>(`${environment.requestBaseUrl}/api/v1-web/Application/ChangeRegProgramStatus?userUid=${userUid}&eventCode=${codeEvent}&moderationStateCode=${moderationStateCode}`)
  }
  addVisaInvitationGenerate(userUid: number | undefined | null,codeEvent: string | undefined | null){
    return this.http.get<string>(`${environment.requestBaseUrl}/api/v1-web/IntegrationCrm/AddVisaInvitationGenerate?userUid=${userUid}&eventCode=${codeEvent}`)
  }

  changeLocationRegprogram(userUid: number | null | undefined,codeEvent: string | undefined | null,locationCode: string | undefined | null){
    return this.http.get<string>(`${environment.requestBaseUrl}/api/v1-web/Application/ChangeLocationRegprogram?userUid=${userUid}&eventCode=${codeEvent}&locationCode=${locationCode}`)
  }

  uploadPersonalFile(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post<Document>(
      `${environment.integrationBaseUrl}/api/IntegrationCrm/UploadPersonalFile`,
      formData
    );
  }

  uploadImage(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post<Photo>(
      `${environment.integrationBaseUrl}/api/IntegrationCrm/UploadImage`,
      formData
    );
  }
}
