import { Component } from '@angular/core';
import {
    DevelopmentMessageComponent
} from "../../../assets/shared/components/development-message/development-message.component";

@Component({
  selector: 'app-analytics',
  standalone: true,
    imports: [
        DevelopmentMessageComponent
    ],
  templateUrl: './analytics.component.html',
  styleUrl: './analytics.component.scss'
})
export class AnalyticsComponent {

}
