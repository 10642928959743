import {Injectable} from '@angular/core';
import {ToastrService} from "ngx-toastr";


@Injectable({providedIn: 'root'})
export class NotificationService {
  private toastr: ToastrService;


  constructor(
    toastr: ToastrService) {
    this.toastr = toastr;
  }

  public success(message: string, title: string = 'Успешно'): void {
    this.toastr.success(message, title);
  }

  public error(message: string, title: string = 'Ошибка:'): void {
    this.toastr.error(message, title);
  }

  public warning(message: string, title: string = 'Внимание'): void {
    this.toastr.warning(message, title);
  }
  public info(message: string, title: string = 'Внимание'): void {
    this.toastr.warning(message, title);
  }

}
