import {Component, Input, OnInit} from '@angular/core';
import {
  CustomSelectionComponent
} from "../../../../../assets/shared/components/select/custom-selection/custom-selection.component";
import {DatepickerComponent} from "../../../../../assets/shared/components/datepicker/datepicker/datepicker.component";
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatInput} from "@angular/material/input";
import {NgForOf, NgIf} from "@angular/common";
import {ProfileForm} from "../../../../core/interface/profile-card/profile-card-main.interface";
import {FileStorageService} from "../../../../core/services/file-storage/file.storage.service";

import {NotificationService} from "../../../../core/services/notification/notification.service";
import {untilDestroyed} from "@ngneat/until-destroy";
import {GeneralInformationApplicationComponent} from "../general-information/general-information.component";
import {ApplicationForm} from "../../../../core/interface/application/application.main.interface";
import {ProfileCardService} from "../../../../core/services/pages/profile/profile-card.service";
import {environment} from "../../../../../environments/environment";
import {ApplicationCardService} from "../../../../core/services/pages/application/application-card.service";

@Component({
  selector: 'app-passport-application-information',
  imports: [
    CustomSelectionComponent,
    DatepickerComponent,
    MatCheckbox,
    MatInput,
    NgForOf,
    NgIf,
    ReactiveFormsModule
  ],
  standalone: true,
  templateUrl: './passport-information.component.html',
  styleUrl: './passport-information.component.scss'
})
export class PassportInformationApplicationComponent implements OnInit {
  @Input() formInfoApplication!: FormGroup<ApplicationForm>
  @Input() isEdit?: boolean
  @Input() dictionaries: { [key: string]: any; } | undefined
  isActive: boolean = true;
  isActiveSelect: boolean = false;
  uploadedFiles!: any[]

  contentItemLabel = [
    {name: 'Тип документа', value: 'identityDocTypeCode'},
    {name: 'Серия', value: 'series'},
    {name: 'Номер', value: 'number'},
    {name: 'Кем выдан документ', value: 'issued'},
    {name: 'Дата выдачи', value: 'dateIssued'},
    {name: 'Срок действия', value: 'dateExpires'},
    {name: 'Гражданство', value: 'citizenshipOksmCode'},
    {name: 'Страна / Регион проживания', value: 'residenceOksmCode'},
    {name: 'Место рождения', value: 'placeBirth'},
    {name: 'Город проживания', value: 'cityResident'},
    {name: 'Адрес регистрации', value: 'registrationAddress'},
    {name: 'Адрес места жительства', value: 'actualDomicileAddress'},
    {name: 'Скан документа', value: 'scan'},
  ];


  constructor(private fileStorageService: FileStorageService,private applicationCardService: ApplicationCardService, private notify: NotificationService,) {
  }

  ngOnInit() {
    this.checkIsRegionRussiaChange()
  }

  checkIsRegionRussiaChange() {
    this.formInfoApplication.get('passportDetail.isCheckedRegionsRussian')!.valueChanges.subscribe((isChecked: boolean | null | undefined) => {
      if (isChecked === true) {
        this.formInfoApplication.get('passportDetail')?.get('residenceOksmCode')?.setValue('rus');
      } else if (isChecked === false) {
        this.formInfoApplication.get('passportDetail')?.get('residenceRegionCode')?.setValue(null);
      }
    });
  }
  getInfoTokenForDocument(documentId: number ,documentName: string) {
    this.fileStorageService.getTokenForDocument(documentId).pipe(untilDestroyed(this)).subscribe(hash => {
      this.downloadFile(hash,documentName);
    })
  }


  downloadFile(hash: string | undefined | null, fileName: string | undefined) {
    if (hash && fileName !== undefined) {
      fetch(environment.filePersonalUrl + hash)
        .then(response => response.blob())
        .then(blob => {
          const file = new File([blob], fileName, {type: blob.type});
          const url = window.URL.createObjectURL(file);
          const link = document.createElement('a');
          link.style.display = 'none';
          link.href = url;
          link.download = fileName;
          link.click();
        })

    }
  }
  onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const filesArray = Array.from(input.files);
      this.uploadCustomFiles(filesArray, this.uploadedFiles);
    }
  }

  uploadCustomFiles(files: any[], uploadedFiles: any[]): void {
    const filesCopy = [...files];
    filesCopy.forEach((file, index) => {

      this.applicationCardService.uploadPersonalFile(file).pipe(untilDestroyed(this))
        .subscribe(linkFile => {
          this.notify.success('Документ успешно загружен')
          file.linkFile = linkFile;
          this.formInfoApplication.get('passportDetail.documentId')?.patchValue(linkFile.id);
          this.formInfoApplication.get('passportDetail.documentName')?.patchValue(linkFile.name);
          if (uploadedFiles !== undefined) {
            uploadedFiles.push(file);
            files.splice(files.indexOf(file), 1);
          }
        });
    });
  }

}
