<div class="body">
  <div class="container">
    <div class="card-page">
      <div class="card-page-main">
        <app-button-back/>
        <div class="card">
          <div class="title-card">
            <div class="title-card-left">
              <span *ngIf="formInfoApplication.controls.lastName.value !== 'Не заполнено' ||
         formInfoApplication.controls.firstName.value !== 'Не заполнено' ||
         formInfoApplication.controls.middleName.value !== 'Не заполнено'"
                    class="name">
    <span *ngIf="formInfoApplication.controls.lastName.value !== 'Не заполнено'">
      {{ formInfoApplication.controls.lastName.value }}
    </span>
    <span *ngIf="formInfoApplication.controls.firstName.value !== 'Не заполнено'">
      {{ formInfoApplication.controls.firstName.value }}
    </span>
    <span *ngIf="formInfoApplication.controls.middleName.value !== 'Не заполнено'">
      {{ formInfoApplication.controls.middleName.value }}
    </span>
</span>


              <span *ngIf="formInfoApplication.controls.lastName.value === 'Не заполнено' &&
              formInfoApplication.controls.firstName.value === 'Не заполнено' &&
              formInfoApplication.controls.middleName.value === 'Не заполнено'"
                    class="name">Нет данных</span>

              <span class="text-inter-regular id-number">{{ formInfoApplication.controls.userUid.value }}</span>
            </div>
            <div class="title-card-right">
              <div *ngIf="!isEdit"  class="supplemental">
                <svg (click)="isDropdownOpen = !isDropdownOpen"
                     [ngClass]="{'active-dropdown': isDropdownOpen}"
                     width="12" height="4" viewBox="0 0 12 4"
                     fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.33333 0.666748C0.6 0.666748 0 1.26675 0 2.00008C0 2.73341 0.6 3.33341 1.33333 3.33341C2.06667 3.33341 2.66667 2.73341 2.66667 2.00008C2.66667 1.26675 2.06667 0.666748 1.33333 0.666748ZM10.6667 0.666748C9.93333 0.666748 9.33333 1.26675 9.33333 2.00008C9.33333 2.73341 9.93333 3.33341 10.6667 3.33341C11.4 3.33341 12 2.73341 12 2.00008C12 1.26675 11.4 0.666748 10.6667 0.666748ZM6 0.666748C5.26667 0.666748 4.66667 1.26675 4.66667 2.00008C4.66667 2.73341 5.26667 3.33341 6 3.33341C6.73333 3.33341 7.33333 2.73341 7.33333 2.00008C7.33333 1.26675 6.73333 0.666748 6 0.666748Z"
                    fill="currentColor"/>
                </svg>
                <div *ngIf="isDropdownOpen" class="dropdown dropdown-supplemental">
                  <div class="dropdown-menu dropdown-menu-supplemental">
                    <app-dropdown [menuItems]="settingsMenu()"
                    ></app-dropdown>
                  </div>
                </div>
              </div>
              <button *ngIf="isEdit" (click)="canselForm()" class="button-red">
                <span class="text-inter-regular cancel">Отменить</span></button>
<!--              <button *ngIf="!isEdit" (click)="isEditForm()"-->
<!--                      class="bth-orange">-->
<!--              <span class="text-inter-regular text-bth">-->
<!--               Редактировать</span>-->
<!--              </button>-->

              <button *ngIf="isEdit"
                      (click)="isEdit ? openModalConfirmGeneral() : isEdit = true"
                      class="button-green">
              <span class="text-inter-regular text-bth accept">
                Сохранить</span>
              </button>
            </div>
          </div>
          <div class="form-card">
            <app-general-application-information [formInfoApplication]="formInfoApplication"
                                                 [isEdit]="isEdit"
                                                 [dictionaries]="dictionaries"
            ></app-general-application-information>


            <app-info-application-application [formInfoApplication]="formInfoApplication"
                                  [isEdit]="isEdit"
                                  [dictionaries]="dictionaries">

            </app-info-application-application>

            <app-personal-application-information [formInfoApplication]="formInfoApplication"
                                      [dictionaries]="dictionaries"
            ></app-personal-application-information>


            <app-passport-application-information
              [formInfoApplication]="formInfoApplication"
              [isEdit]="isEdit"
              [dictionaries]="dictionaries"
            >
            </app-passport-application-information>


            <app-social-application-information [formInfoApplication]="formInfoApplication"
                                    [dictionaries]="dictionaries">
            </app-social-application-information>


            <app-field-activity-application-information [formInfoApplication]="formInfoApplication"
                                            [dictionaries]="dictionaries">
            </app-field-activity-application-information>

            <app-more-application-information [formInfoApplication]="formInfoApplication"

                                  [dictionaries]="dictionaries">

            </app-more-application-information>

            <app-answers-questions [questions]="questions">

            </app-answers-questions>
<!--            <app-change-history [changes]="historyChanges">-->
<!--            </app-change-history>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
