import {Component, Input, OnInit} from '@angular/core';
import {
  CustomSelectionComponent
} from "../../../../../assets/shared/components/select/custom-selection/custom-selection.component";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatInput} from "@angular/material/input";
import {NgForOf, NgIf} from "@angular/common";

import {ApplicationForm} from "../../../../core/interface/application/application.main.interface";
import {combineLatest} from "rxjs";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ApplicationCardMainService} from "../application-card.service";

@UntilDestroy()
@Component({
  selector: 'app-more-application-information',
  imports: [
    CustomSelectionComponent,
    FormsModule,
    MatCheckbox,
    MatInput,
    NgForOf,
    NgIf,
    ReactiveFormsModule
  ],
  standalone: true,
  templateUrl: './more-information.component.html',
  styleUrl: './more-information.component.scss'
})
export class MoreInformationApplicationComponent implements OnInit {
  @Input() formInfoApplication!: FormGroup<ApplicationForm>
  @Input() dictionaries: { [key: string]: any; } | undefined
  isActive: boolean = true;


  contentItemLabel = [
    {name: 'Направление', value: 'direction'},
    {name: 'Ограничение по здоровью', value: 'healthrestrictions'},
    {name: 'Другое', value: 'other'},
    {name: 'Не имею ограничений по здоровью', value: 'isNoHealthRestrictions'},
    {name: 'Тип питания', value: 'foodpreferences'},
    {name: 'Размер одежды', value: 'clothingSizeCode'},
  ]

  constructor(private applicationMainCardService:ApplicationCardMainService) {
  }

  ngOnInit() {
    this.checkIsNoHealthRestrictions()
  }

  getFilteredContentItemLabel(): { name: string; value: string }[] {
    return this.contentItemLabel.filter(
      item => item.value !== 'other' || this.getHealthRestrictionCodeControl()?.value?.includes('other')
    );
  }

  checkIsNoHealthRestrictions() {
    if (this.formInfoApplication.get('extraInformation.healthRestrictions') && this.formInfoApplication.get('extraInformation.isNoHealthRestrictions')) {
      combineLatest([
        this.applicationMainCardService.isEdit$,
        this.formInfoApplication.get('extraInformation.isNoHealthRestrictions')!.valueChanges
      ]).pipe(untilDestroyed(this)).subscribe(([edit, isNoHealthRestrictions]) => {
        if (!isNoHealthRestrictions && edit) {
          this.formInfoApplication
            .get('extraInformation.healthRestrictions')
            ?.enable({ emitEvent: false });
        } else if (isNoHealthRestrictions) {
          this.formInfoApplication
            .get('extraInformation.healthRestrictions.healthRestrictionCode')
            ?.setValue(null, { emitEvent: false });
          this.formInfoApplication
            .get('extraInformation.healthRestrictions.healthRestrictionCode')
            ?.disable({ emitEvent: false });

          this.formInfoApplication
            .get('extraInformation.healthRestrictions.other')
            ?.setValue(null, { emitEvent: false });
          this.formInfoApplication
            .get('extraInformation.healthRestrictions.other')
            ?.disable({ emitEvent: false });
        } else {
          // Чекбокс false, но редактирование запрещено
          this.formInfoApplication
            .get('extraInformation.healthRestrictions.healthRestrictionCode')
            ?.disable({ emitEvent: false });
          this.formInfoApplication
            .get('extraInformation.healthRestrictions.other')
            ?.disable({ emitEvent: false });
        }
      });
    }
  }

  getHealthRestrictionCodeControl(): FormControl<string[]> {
    return this.formInfoApplication.get('extraInformation.healthRestrictions.healthRestrictionCode') as FormControl<string[]>;
  }
}
