<div *ngIf="isVisible"
     [ngStyle]="{'top': position.y, 'left': position.x, 'position': 'fixed'}"
     class="context-menu"
     (mousedown)="$event.stopPropagation()">
  <div class="label">
    <div *ngFor="let menuItem of contextMenu" class="context-menu-item"
         (mouseenter)="menuItem.isHovered = true" (mouseleave)="menuItem.isHovered = false">
      <div class="item-with-arrow">
        <span [ngClass]="{'hover-text': menuItem.isHovered}" (click)="menuItem.action()">{{ menuItem.label }}</span>

        <svg *ngIf="menuItem?.subMenu"
             class="arrow-icon"
             [ngClass]="{'rotate-180': menuItem.isHovered}"
             width="14"
             height="9"
             viewBox="0 0 14 9"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path [attr.fill]="menuItem.isHovered ? '#FF6B00' : '#252525'"
                d="M0.334637 1.35033L1.51797 0.166992L7.0013 5.65033L12.4846 0.166992L13.668 1.35033L7.0013 8.01699L0.334637 1.35033Z"/>
        </svg>
      </div>
    </div>
  </div>


  <div class="main-submenu"  [ngClass]="{'submenu-open': menuItem.isHovered}"  *ngFor="let menuItem of contextMenu" (mouseenter)="menuItem.isHovered = true" (mouseleave)="menuItem.isHovered = false">
    <div *ngIf="menuItem.subMenu" class="submenu" >
      <div *ngFor="let subItem of menuItem.subMenu" class="context-menu-item">
        <span (click)="subItem.action()">{{ subItem.label }}</span>
      </div>
    </div>
  </div>

</div>
