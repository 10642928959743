import {TableInfo} from "../../core/interface/table/table-info";
import {FormControl, FormsModule} from "@angular/forms";
import {ApplicationMainService} from "./application-main-service";
import {ApplicationTableComponent} from "./application-table/application-table.component";
import {PaginatorComponent} from "../../../assets/shared/components/paginator/paginator.component";
import {MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {
  CustomSelectionComponent
} from "../../../assets/shared/components/select/custom-selection/custom-selection.component";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {AfterContentInit, Component, OnChanges, OnInit} from "@angular/core";
import {AsyncPipe, NgIf} from "@angular/common";
import {DropdownComponent} from "../../../assets/shared/components/dropdown/dropdown-basic/dropdown.component";
import {MatDialog} from "@angular/material/dialog";
import {NotificationService} from "../../core/services/notification/notification.service";
import {ModalGeneralConfirmComponent} from "../../layout/dialogs/modal-general-confirm/modal-general-confirm.component";
import {DictionaryService} from "../../core/services/pages/dictionary.service";
import {delay} from "rxjs";


@UntilDestroy()
@Component({
  selector: 'app-application',
  standalone: true,
  imports: [
    CustomSelectionComponent,
    MatInput,
    MatLabel,
    PaginatorComponent,
    ApplicationTableComponent,
    FormsModule,
    DropdownComponent,
    NgIf,
  ],
  templateUrl: './application.component.html',
  styleUrl: './application.component.scss'
})
export class ApplicationComponent implements OnInit {
  tableInfo: TableInfo = this.applicationMainService.tableInfo;
  clearControl = new FormControl([])
  optionsSelectedClear: any[] = []
  isDropdownOpen = false;
  dictionaries!: { [key: string]: any; } | undefined


  constructor(private dictionaryService: DictionaryService,public applicationMainService: ApplicationMainService, private dialog: MatDialog,private notify: NotificationService) {
  }




  ngOnInit() {
    this.getInfoTakeProfileTable()
    this.getInfoDictionaries()
  }

  getInfoDictionaries() {
    this.dictionaryService.dictionaries$.pipe(untilDestroyed(this)).subscribe(x => {
      this.dictionaries = x
    })
  }
  settingsMenu() {
    const menu = [
      {
        label: 'Статус заявки',
        subMenu: [
          ...(this.dictionaries?.['moderationstates']?.map((state: any) => ({
            label: `Статус заявки всем «${state.name}»`,
          })) || []),
          {label: 'Статус билета всем за свой счёт «Да»'},
          {label: 'Статус билета всем за свой счёт «Нет»'},
        ],
      },
      {
        label: 'Статус ДВФМ',
        subMenu: this.dictionaries?.['moderationstates']
          ? this.dictionaries['moderationstates'].map((state: any) => ({
            label: `Статус ДВФМ всем «${state.name}»`,
          }))
          : []
      },
      { label: 'Сгенерировать визовое приглашение', url: '' },
    ];
    return menu;
  }

      onMenuItemClick(action: string): void {
    let message: Record<string, string> = {
      'заявки': 'проставить этот статус заявкам',
      'билета': 'проставить этот статус билета',
      'ДВФМ': 'проставить этот статус ДВФМ',
      'визовое': 'сгенерировать визовое приглашение участникам',
    };
    for (const key in message) {
      if (action.includes(key)) {
        this.openModalConfirmBlocking(message[key]);
        break;
      }
    }
  }
  openModalConfirmBlocking(message: string) {
    const dialogRef = this.dialog.open(ModalGeneralConfirmComponent, {
      data: {
        message: message
      }
    });

    dialogRef.afterClosed().pipe(untilDestroyed(this)).subscribe((result: any) => {
      if (result) {
            this.notify.success('Операция завершена успешно');
            this.isDropdownOpen = false;
      }
    });
  }

  getInfoTakeProfileTable() {
    return this.applicationMainService.getInfoTakeProfileTable()
  }


  changePage(page: number): void {
    this.applicationMainService.changePage(page);
  }

  changeTake(take: number): void {
    this.applicationMainService.changeTake(take);
  }

  onSaveFilterSearch() {
    this.tableInfo.skip = 0
    this.applicationMainService.updateTableProfile()
  }

  onClearFilterSearch() {
    this.tableInfo.skip = 0
    if (this.applicationMainService.tableInfo.quickSearch) {
      this.applicationMainService.tableInfo.quickSearch.value = '';
      this.applicationMainService.tableInfo.quickSearch.fields = [];
      this.clearControl.setValue([]);
      this.optionsSelectedClear = []
    }
    this.applicationMainService.updateTableProfile()
  }
}
