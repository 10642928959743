import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import {BarChartModule, Color, ScaleType} from "@swimlane/ngx-charts";
import {NgClass, NgForOf, NgStyle} from "@angular/common";
import {ChartsService} from "../../../../../app/core/services/charts/charts.service";
import {ChartsClass} from "../../../../../app/core/class/home.class";

@Component({
  selector: 'app-charts-bar-vertical',
  standalone: true,
  imports: [
    BarChartModule,
    NgForOf,
    NgClass,
    NgStyle
  ],
  templateUrl: './charts-bar-vertical.component.html',
  styleUrl: './charts-bar-vertical.component.scss',
  encapsulation: ViewEncapsulation.None,
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartsBarVerticalComponent {
  @Input() dictionaries: { [key: string]: any; } | undefined
  @Input()  countriesCharts: ChartsClass[] | undefined;
  activeCountry: string | null = null;
  activeCountryColors: Color = {
    name: 'custom',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#00AA40', '#FF6B00', '#AC182D', '#0082C2', '#E81F76', '#6338A9']
  };


  constructor(private chartsService: ChartsService,) {
  }

  updateTickClasses() {
    this.chartsService.updateTickClasses(this.activeCountry)
  }

  getCustomColors(activeItem: string | null, data: any | undefined, baseColors: Color): any {
    return this.chartsService.getCustomColors(activeItem, data, baseColors);
  }

  formatPercentage = (value: number): string => {
    return this.chartsService.formatPercentage(value);
  }

  formatYAxisTick = (value: number): string => {
    return this.chartsService.formatYAxisTick(value);
  }

  onItemSelect(itemName: string | undefined): void {
    this.activeCountry = this.chartsService.onItemSelect(this.activeCountry, itemName!);
    this.updateTickClasses()
  }

  getNameCountry(code: string | undefined): string {
    if (code === undefined || !this.dictionaries || !this.dictionaries?.['countries']) return 'N/A';
    return this.dictionaries?.['countries']?.find((c: any) => c.code.toUpperCase() == code)?.name;
  }
}
