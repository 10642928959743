import {Component, Inject} from '@angular/core';
import {FormsModule} from "@angular/forms";

import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-modal-general-confirm',
  standalone: true,
    imports: [
        FormsModule,
    ],
  templateUrl: './modal-general-confirm.component.html',
  styleUrl: './modal-general-confirm.component.scss'
})
export class ModalGeneralConfirmComponent {
  message: string = ''
  constructor( public dialogRef: MatDialogRef<ModalGeneralConfirmComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.message = data.message
  }

  closeModal(): void {
    this.dialogRef.close(false)
  }

  confirm(){
    this.dialogRef.close({action: this.message});
  }
}
