<div class="modal-block">
  <span class="message">Вы действительно хотите <br/> {{action === 'Блокировать' ? 'заблокировать' : 'разблокировать'}} пользователя?</span>

    <textarea class="message-textarea" *ngIf="action === 'Блокировать'" [(ngModel)]="reasonBlocking"  placeholder="Введите причину блокировки"></textarea>

  <div class="block-bth">
    <button (click)="confirm()" [disabled]="action === 'Блокировать' && !reasonBlocking" class="button-green"><span class="text-inter-regular text-bth accept">Да</span></button>
    <button (click)="closeModal()" class="button-red"><span class="text-inter-regular cancel">Нет</span></button>
  </div>
</div>
