<div [class.margin-bottom-unset]="!isActive"  [formGroup]="formInfoApplication" class="form">
  <div (click)="isActive = !isActive" class="label-form">
    <img [class.rotate-180]="isActive" src="assets/images/ul-kit-img/arrow-black.svg">
    <span>Паспортные данные</span>
  </div>
  <div [class.active]="isActive" class="form-content">
    <div class="left-content">
      <div *ngFor="let item of contentItemLabel" class="item-content-label">
        <img src="assets/images/ul-kit-img/green-point.svg">
        <span class="text-inter-regular">{{item.name}}</span>
      </div>
    </div>
    <div class="right-content">

      <div formGroupName="passportDetail" class="item-info">
        <input formControlName="identityDocTypeCode" class="text-inter-semibold input-main-search"
               [placeholder]="formInfoApplication.get('identityDocTypeCode')?.value === null ? 'Не заполнено' : ''"
               matInput>
      </div>

      <div formGroupName="passportDetail" class="item-info">
        <input formControlName="series" class="text-inter-semibold input-main-search"
               [placeholder]="formInfoApplication.get('series')?.value === null ? 'Не заполнено' : ''"
               matInput>
      </div>

      <div formGroupName="passportDetail" class="item-info">
        <input formControlName="number" class="text-inter-semibold input-main-search"
               [placeholder]="formInfoApplication.get('number')?.value === null ? 'Не заполнено' : ''"
               matInput>
      </div>

      <div formGroupName="passportDetail" class="item-info">
        <input formControlName="issued" class="text-inter-semibold input-main-search"
               [placeholder]="formInfoApplication.get('issued')?.value === null ? 'Не заполнено' : ''"
               matInput>
      </div>

      <div formGroupName="passportDetail" class="item-info">
        <custom-datepicker
          [control]="formInfoApplication.controls.passportDetail.controls.dateIssued"></custom-datepicker>
      </div>

      <div formGroupName="passportDetail" class="item-info">
        <custom-datepicker
          [control]="formInfoApplication.controls.passportDetail.controls.dateExpires"></custom-datepicker>
      </div>

      <div class="item-info">
        <custom-selection [multipleCheckBox]="false"
                          [control]="formInfoApplication.controls.passportDetail.controls.citizenshipOksmCode"
                          [options]="dictionaries?.['countries']"
        ></custom-selection>

      </div>

      <div formGroupName="passportDetail" class="item-info block-with-checkbox">
        <custom-selection
          [multipleCheckBox]="false"
          [control]="formInfoApplication.controls.passportDetail.controls.isCheckedRegionsRussian.value
             ? formInfoApplication.controls.passportDetail.controls.residenceRegionCode
             : formInfoApplication.controls.passportDetail.controls.residenceOksmCode"
          [options]="formInfoApplication.controls.passportDetail.controls.isCheckedRegionsRussian.value
             ? dictionaries?.['regions']
             : dictionaries?.['countries']">
        </custom-selection>

        <mat-checkbox formControlName="isCheckedRegionsRussian"
                      class="custom-checkbox">
          <label class="checkbox-label">
            Российская федерация</label></mat-checkbox>
      </div>

      <div formGroupName="passportDetail" class="item-info">
        <input formControlName="placeBirth" class="text-inter-semibold input-main-search"
               [placeholder]="formInfoApplication.get('placeBirth')?.value === null ? 'Не заполнено' : ''"
               matInput>
      </div>



      <div formGroupName="passportDetail" class="item-info">
        <input formControlName="cityResident" class="text-inter-semibold input-main-search"
               [placeholder]="formInfoApplication.get('cityResident')?.value === null ? 'Не заполнено' : ''"
               matInput>
      </div>

      <div formGroupName="passportDetail" class="item-info">
        <input formControlName="registrationAddress" class="text-inter-semibold input-main-search"
               [placeholder]="formInfoApplication.get('registrationAddress')?.value === null ? 'Не заполнено' : ''"
               matInput>
      </div>

      <div formGroupName="passportDetail" class="item-info">
        <input formControlName="actualDomicileAddress" class="text-inter-semibold input-main-search"
               [placeholder]="formInfoApplication.get('actualDomicileAddress')?.value === null ? 'Не заполнено' : ''"
               matInput>
      </div>

      <!-- фото-->
      <div *ngIf="formInfoApplication.value.passportDetail?.documentId !== undefined" class="photo">
        <div  class="item-info tools">
          <button [class.disabled-button]="!isEdit" (click)="fileInput.click()" class="button-classic"
                  [disabled]="!isEdit"> <input type="file" style="display: none;" (change)="onFileChange($event)" #fileInput>
            <img src="assets/images/ul-kit-img/update.svg">
            <span *ngIf="isEdit" class="tooltip-text">Изменить скан документа</span>
          </button>
          <button  class="button-classic"
                   [disabled]="!isEdit"
                   [class.disabled-button]="!isEdit"
                   (click)="getInfoTokenForDocument(formInfoApplication.value.passportDetail?.documentId!,formInfoApplication.value.passportDetail?.documentName!)"
          >

            <img src="assets/images/ul-kit-img/download.svg">
            <span *ngIf="isEdit" class="tooltip-text">Скачать скан документа</span>
          </button>
        </div>
      </div>

      <div *ngIf="formInfoApplication.value.passportDetail?.documentId === undefined" class="photo no-photo">
        <div class="item-info tools">
          <button (click)="fileInput.click()" [class.disabled-button]="!isEdit" class="button-classic"
                  [disabled]="!isEdit">
            <input type="file" style="display: none;" (change)="onFileChange($event)" #fileInput>
            <img src="assets/images/ul-kit-img/upload.svg">
            <span *ngIf="isEdit" class="tooltip-text">Загрузить скан документа</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
