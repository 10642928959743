<div class="statistics-main">
  <div class="statistics-block">
    <div class="label-charts main-charts">
      <span>Участники в разрезе стран (топ-10)</span>
    </div>
    <div  class="charts-countries">
      <ngx-charts-bar-vertical
        [view]="[540, 310]"
        [showXAxisLabel]="true"
        [xAxis]="true"
        [yAxis]="true"
        [tooltipDisabled]="true"
        [dataLabelFormatting]="formatPercentage"
        [yAxisTickFormatting]="formatYAxisTick"
        [showDataLabel]="true"
        [yScaleMax]="1"
        [results]="countriesCharts"
        [scheme]="getCustomColors(activeCountry, countriesCharts, activeCountryColors)"
        (select)="onItemSelect($event.name)" style="padding-left: 12px">
      </ngx-charts-bar-vertical>
    </div>
  </div>
  <div class="info-charts-bar" >
    <ul class="info-country">
      <li *ngFor="let country of countriesCharts; let i = index"
          (click)="onItemSelect(country.name)"
          [ngClass]="{'inactive': activeCountry && activeCountry !== country.name}">
                  <span class="point"
                        [ngStyle]="{'color': activeCountryColors.domain[i % activeCountryColors.domain.length]}">•</span>
        <span class="text-inter-semibold name-country">{{ getNameCountry(country.name) }}</span>
        <br>
        <div class="members-info">
          <span class="text-inter-regular">{{country.membersValue}}</span>
          <span class="text-inter-regular">участников ({{ country.value }}%)</span>
        </div>
      </li>
    </ul>
  </div>
</div>
