import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormsModule} from "@angular/forms";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-modal-block-confirm',
  standalone: true,
  imports: [
    FormsModule,
    NgIf
  ],
  templateUrl: './modal-block-confirm.component.html',
  styleUrl: './modal-block-confirm.component.scss'
})
export class ModalBlockConfirmComponent {
  action: string | undefined
  reasonBlocking: string | undefined

  constructor( public dialogRef: MatDialogRef<ModalBlockConfirmComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.action = data.action
  }

  closeModal(): void {
    this.dialogRef.close(false)
  }

  confirm(){
    this.dialogRef.close({ reasonBlocking: this.reasonBlocking });
  }

}

