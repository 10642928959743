<div class="page">
  <div class="body">
    <div class="container">
      <div class="block-profile">
        <div class="main-block">
          <div class="title-block">
            <span class="title">Пользователи</span>
<!--            <div class="setting-block">-->
<!--              <div class="supplemental">-->
<!--                <svg-->
<!--                     [ngClass]="{'active-dropdown': ''}" width="16" height="16" viewBox="0 0 16 16"-->
<!--                     fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <path-->
<!--                    d="M5.33469 4.00008V6.00008H3.33469V4.00008H5.33469ZM2.00135 2.66675V7.33342H6.66803V2.66675H2.00135ZM8.66803 2.66675H14.0014V4.00008H8.66803V2.66675ZM8.66803 7.33342H14.0014V8.66675H8.66803V7.33342ZM8.66803 12.0001H14.0014V13.3334H8.66803V12.0001ZM7.13943 10.8048L6.19661 9.86201L4.00135 12.0573L2.80609 10.862L1.86328 11.8048L4.00135 13.9429L7.13943 10.8048Z"-->
<!--                    fill="currentColor"/>-->
<!--                </svg>-->
<!--                <div class="dropdown dropdown-event">-->
<!--                  <div class="dropdown-menu dropdown-menu-filter">-->
<!--                    <app-dropdown-filter></app-dropdown-filter>-->
<!--                    <div class="menu-item">-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="setting">-->
<!--                <svg-->
<!--                     [ngClass]="{'active-dropdown': ''}"-->
<!--                     width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <path-->
<!--                    d="M3.1125 10.9999C3.38706 10.2231 4.12788 9.66658 4.9987 9.66658C5.8695 9.66658 6.61036 10.2231 6.8849 10.9999H13.6654V12.3333H6.8849C6.61036 13.1101 5.8695 13.6666 4.9987 13.6666C4.12788 13.6666 3.38706 13.1101 3.1125 12.3333H0.332031V10.9999H3.1125ZM7.1125 6.33325C7.38703 5.55646 8.1279 4.99992 8.9987 4.99992C9.8695 4.99992 10.6104 5.55646 10.8849 6.33325H13.6654V7.66658H10.8849C10.6104 8.44339 9.8695 8.99992 8.9987 8.99992C8.1279 8.99992 7.38703 8.44339 7.1125 7.66658H0.332031V6.33325H7.1125ZM3.1125 1.66659C3.38706 0.889792 4.12788 0.333252 4.9987 0.333252C5.8695 0.333252 6.61036 0.889792 6.8849 1.66659H13.6654V2.99992H6.8849C6.61036 3.77671 5.8695 4.33325 4.9987 4.33325C4.12788 4.33325 3.38706 3.77671 3.1125 2.99992H0.332031V1.66659H3.1125ZM4.9987 2.99992C5.36688 2.99992 5.66536 2.70144 5.66536 2.33325C5.66536 1.96507 5.36688 1.66659 4.9987 1.66659C4.63051 1.66659 4.33203 1.96507 4.33203 2.33325C4.33203 2.70144 4.63051 2.99992 4.9987 2.99992ZM8.9987 7.66658C9.3669 7.66658 9.66536 7.36812 9.66536 6.99992C9.66536 6.63172 9.3669 6.33325 8.9987 6.33325C8.6305 6.33325 8.33203 6.63172 8.33203 6.99992C8.33203 7.36812 8.6305 7.66658 8.9987 7.66658ZM4.9987 12.3333C5.36688 12.3333 5.66536 12.0348 5.66536 11.6666C5.66536 11.2984 5.36688 10.9999 4.9987 10.9999C4.63051 10.9999 4.33203 11.2984 4.33203 11.6666C4.33203 12.0348 4.63051 12.3333 4.9987 12.3333Z"-->
<!--                    fill="currentColor"/>-->
<!--                </svg>-->
<!--                <div class="dropdown dropdown-supplemental dropdown-setting" >-->
<!--                  <div class="dropdown-menu setting-menu-constructor">-->
<!--                    <app-dropdown></app-dropdown>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="supplemental">-->
<!--                <svg-->
<!--                     [ngClass]="{'active-dropdown': ''}" width="12" height="4" viewBox="0 0 12 4"-->
<!--                     fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <path-->
<!--                    d="M1.33333 0.666748C0.6 0.666748 0 1.26675 0 2.00008C0 2.73341 0.6 3.33341 1.33333 3.33341C2.06667 3.33341 2.66667 2.73341 2.66667 2.00008C2.66667 1.26675 2.06667 0.666748 1.33333 0.666748ZM10.6667 0.666748C9.93333 0.666748 9.33333 1.26675 9.33333 2.00008C9.33333 2.73341 9.93333 3.33341 10.6667 3.33341C11.4 3.33341 12 2.73341 12 2.00008C12 1.26675 11.4 0.666748 10.6667 0.666748ZM6 0.666748C5.26667 0.666748 4.66667 1.26675 4.66667 2.00008C4.66667 2.73341 5.26667 3.33341 6 3.33341C6.73333 3.33341 7.33333 2.73341 7.33333 2.00008C7.33333 1.26675 6.73333 0.666748 6 0.666748Z"-->
<!--                    fill="currentColor"/>-->
<!--                </svg>-->
<!--                <div class="dropdown dropdown-supplemental">-->
<!--                  <div class="dropdown-menu dropdown-menu-supplemental">-->
<!--                    <app-dropdown></app-dropdown>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="constructor">-->
<!--                <button class="bth-orange">-->
<!--                  <span class="text-inter-regular text-bth">Конструктор</span>-->
<!--                </button>-->
<!--                <div class="dropdown dropdown-constructor">-->
<!--                  <div class="dropdown-menu dropdown-menu-constructor">-->
<!--                    <app-dropdown></app-dropdown>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
          </div>
          <div class="profile-search">
            <div class="custom-multiselect-search">
              <mat-label class="text-inter-regular">Фильтрация</mat-label>
              <custom-selection
                [value]="tableInfo"
                [multipleCheckBox]="true"
                [showCountOnly]="true"

                [control]="clearControl"
                [options]="getInfoTakeProfileTable()"></custom-selection>
            </div>
            <div class="search">
              <div class="custom-multiselect-search input-search">
                <mat-label class="text-inter-regular">Быстрый поиск</mat-label>
                <input [(ngModel)]="tableInfo.quickSearch!.value" class="input-main-search" matInput>
                <img src="assets/images/ul-kit-img/search-icon.svg">
              </div>
            </div>
            <div class="bth-search">
              <button (click)="onClearFilterSearch()" class="button-red"><span class="cancel">Очистить</span></button>
              <button (click)="onSaveFilterSearch()" class="button-green"><span class="accept">Найти</span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-profile-table style="flex-grow: 1;"></app-profile-table>

  <app-paginator
    (changePage)="changePage($event)"
    (changeTake)="changeTake($event)"
    [value]="tableInfo.take!"></app-paginator>

</div>



