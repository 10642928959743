<div [class.margin-bottom-unset]="!isActive"
     [formGroup]="formInfoApplication"
     class="form">
  <div (click)="isActive = !isActive" class="label-form">
    <img [class.rotate-180]="isActive" src="assets/images/ul-kit-img/arrow-black.svg">
    <span>Сфера деятельности</span>
  </div>
  <div [class.active]="isActive" class="form-content">
    <div class="left-content">
      <div *ngFor="let item of contentItemLabel" class="item-content-label">
        <img src="assets/images/ul-kit-img/green-point.svg">
        <span>{{item.name}}</span>
      </div>
    </div>
    <div class="right-content">

      <div class="item-info">
        <custom-selection [multipleCheckBox]="true"
                          [control]="formInfoApplication.controls.sphereActivity.controls.statusParticipants"
                          [options]="dictionaries?.['participantstatus']"
        ></custom-selection>
      </div>

      <div formGroupName="sphereActivity" class="item-info">
        <input formControlName="workplace" class="text-inter-semibold input-main-search"
               [placeholder]="formInfoApplication.get('workplace')?.value === null ? 'Не заполнено' : ''"
               matInput>
      </div>

      <div formGroupName="sphereActivity" class="item-info">
        <input formControlName="workplaceEng" class="text-inter-semibold input-main-search"
               [placeholder]="formInfoApplication.get('workplaceEng')?.value === null ? 'Не заполнено' : ''"
               matInput>
      </div>

      <div formGroupName="sphereActivity" class="item-info">
        <input formControlName="positionName" class="text-inter-semibold input-main-search"
               [placeholder]="formInfoApplication.get('positionName')?.value === null ? 'Не заполнено' : ''"
               matInput>
      </div>

      <div formGroupName="sphereActivity" class="item-info">
        <input formControlName="positionNameEng" class="text-inter-semibold input-main-search"
               [placeholder]="formInfoApplication.get('positionNameEng')?.value === null ? 'Не заполнено' : ''"
               matInput>
      </div>
      <div class="item-info">
        <custom-selection [multipleCheckBox]="false"
                          [control]="formInfoApplication.controls.sphereActivity.controls.engLanguageLevelCode"
                          [options]="dictionaries?.['languagelevels']"
        ></custom-selection>
      </div>
    </div>
  </div>
</div>
