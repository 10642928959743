import {Injectable} from '@angular/core';
import {Color} from "@swimlane/ngx-charts";
import {HomeInterface} from "../../interface/home/home.interface";

@Injectable({
  providedIn: 'root',
})
export class ChartsService {
  formatPercentage(value: number): string {
    return `${value}%`;
  }
  formatYAxisTick(value: number): string {
    return `${value}%`;
  }
  onItemSelect(currentItem: string | null, itemName: string): string | null {
    return currentItem === itemName ? null : itemName;
  }
  updateTickClasses(activeCountry: string | null) {
    const ticks = document.querySelectorAll('.x  .tick');
    if (!activeCountry) {
      ticks.forEach((tick) => {
        tick.classList.remove('active');
        tick.classList.remove('inactive');
      });
      return;
    }
    ticks.forEach((tick) => {
      const tickText = tick.querySelector('text')?.textContent?.trim();
      if (tickText === activeCountry) {
        tick.classList.add('active');
        tick.classList.remove('inactive');
      } else {
        tick.classList.add('inactive');
        tick.classList.remove('active');
      }
    });
  }

  getCustomColors(activeItem: string | null, data: any[], baseColors: Color): Color {
    if (!activeItem) {
      return baseColors;
    }
    const { domain } = baseColors;
    return {
      ...baseColors,
      domain: data.map((item, index) => {
        const baseColor = domain[index % domain.length];
        return item.name === activeItem
          ? baseColor
          : this.convertHexToRGBA(baseColor, 0.2);
      })
    };
  }
  private convertHexToRGBA(hex: string, opacity: number): string {
    const [r, g, b] = hex.match(/\w\w/g)!.map((x) => parseInt(x, 16));
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
}
