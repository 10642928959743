import { Component } from '@angular/core';

import {
    DevelopmentMessageComponent
} from "../../../assets/shared/components/development-message/development-message.component";

@Component({
  selector: 'app-mailing',
  standalone: true,
    imports: [
        DevelopmentMessageComponent
    ],
  templateUrl: './mailing.component.html',
  styleUrl: './mailing.component.scss'
})
export class MailingComponent {

}
