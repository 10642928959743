import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {

  // Переопределяем метод для получения дней недели
  override getDayOfWeekNames(): string[] {
    return ['Вc', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
  }

  // Переопределяем метод для получения первого дня недели
  override getFirstDayOfWeek(): number {
    return 1; // Понедельник
  }

  // Переопределяем метод форматирования
  override format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'MMMM YYYY') {
      let formattedDate = new Intl.DateTimeFormat('ru-RU', { month: 'long', year: 'numeric' }).format(date);
      formattedDate = formattedDate.replace(/г\./, '');
      formattedDate = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
      return formattedDate;
    }
    // Форматируем как DD.MM.YYYY
    if (displayFormat === 'DD.MM.YYYY') {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    }
    return super.format(date, displayFormat);
  }

  // Переопределяем метод парсинга строки
  override useUtcForDisplay = true;
  override parse(value: any): Date | null {
    if (typeof value === "string" && value.indexOf(".") > -1) {
      const str = value.split(".");
      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);
      return new Date(year, month, date);
    }
    const timestamp = typeof value === "number" ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }
}
