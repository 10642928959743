import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-modal-show-photo',
  standalone: true,
  imports: [

  ],
  templateUrl: './modal-show-photo.component.html',
  styleUrl: './modal-show-photo.component.scss'
})
export class ModalShowPhotoComponent {
  fileName: string | null | undefined;
  fileHash:string | null | undefined;


  constructor(public dialogRef: MatDialogRef<ModalShowPhotoComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.fileHash = data.fileHash
    this.fileName = data.fileName
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  protected readonly environment = environment;
}
