<mat-form-field appearance="outline" class="custom-selection-search">
  <img [class.rotate-180]="isActive" class="arrow-custom-select"
       src="assets/shared/components/select/img-select/select-arrow.svg">
  <mat-select
    (openedChange)="checkIfActive($event)"
    [formControl]="control"
    [multiple]="multipleCheckBox"
    [placeholder]="getSelectedCount()"
  >
    <mat-select-trigger>
      {{ getSelectedCount() }}
    </mat-select-trigger>
    <div [class.select]="!multipleCheckBox" class="btn-group-select">
      <mat-checkbox *ngIf="multipleCheckBox"
                    [checked]="isSelectedCheckAll()"
                    (change)="selectedAll($event.checked)"
      >Выделить все
      </mat-checkbox>
    </div>
    <div [class.select-text]="!multipleCheckBox" class="custom-mat-option">
      <mat-option *ngFor="let option of options"
                  (click)="getInfoOptionCode(option.code)"
                  [value]="option.code"> {{ option.name }}
      </mat-option>
    </div>

    <div *ngIf="multipleCheckBox" class="bth-search">
      <button (click)="accept()" class="button-green"><span class="accept">Применить</span></button>
      <button (click)="cancel()" class="button-red"><span class="cancel">Отменить</span></button>
    </div>
  </mat-select>
</mat-form-field>
