import {Component, Input} from '@angular/core';
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {NotificationService} from "../../../../core/services/notification/notification.service";

import {ModalShowPhotoComponent} from "../../../../layout/dialogs/modal-show-photo/modal-show-photo.component";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {NgForOf, NgIf} from "@angular/common";
import {
  CustomSelectionComponent
} from "../../../../../assets/shared/components/select/custom-selection/custom-selection.component";
import {
  DateAndTimepickerComponent
} from "../../../../../assets/shared/components/date-and-timepicker/date-and-timepicker.component";
import {MatFormField} from "@angular/material/form-field";
import {MatOption, MatSelect} from "@angular/material/select";
import {MatCheckbox} from "@angular/material/checkbox";
import {ApplicationForm} from "../../../../core/interface/application/application.main.interface";
import {MatInput} from "@angular/material/input";
import {environment} from "../../../../../environments/environment";
import {FileStorageService} from "../../../../core/services/file-storage/file.storage.service";

@UntilDestroy()
@Component({
  selector: 'app-info-application-application',
  imports: [
    NgForOf,
    CustomSelectionComponent,
    DateAndTimepickerComponent,
    MatFormField,
    MatSelect,
    MatOption,
    MatCheckbox,
    NgIf,
    MatInput,
    ReactiveFormsModule
  ],
  standalone: true,
  templateUrl: './info-application.component.html',
  styleUrl: './info-application.component.scss'
})
export class InfoApplicationApplicationComponent {
  @Input() formInfoApplication!: FormGroup<ApplicationForm>
  @Input() dictionaries: { [key: string]: any; } | undefined
  @Input() isEdit?: boolean
  isActive: boolean = true;
  isActiveSelect: boolean = false;
  uploadedFiles!: any[]

  contentItemLabel = [
    {name: 'Мероприятие ', value: 'Event'},
    {name: 'ID Единый', value: 'unifiedID'},
    {name: 'Статус заявки', value: 'applicationStatus'},
    {name: 'Дата подачи заявки', value: 'applicationDate'},
    {name: 'Причина отказа от участия', value: 'reasonParticipate'},
    {name: 'Дата подтверждения участия', value: 'participationDate'},
    {name: 'Статус ДВФМ', value: 'DVFMstatus'},
    {name: 'Страна отправления', value: 'departureCountry'},
    {name: 'Город отправления', value: 'departureCity'},
    {name: 'Билеты за свой счет', value: 'tickets'},
    {name: 'Виза не нужна', value: 'visaRequired'},
    {name: 'Город получения визы', value: 'visaIssuingCityCode'},
    {name: 'Ознакомлен с визовым приглашением', value: 'сonfirmationVisaInvitation'},
    {name: 'Город РП', value: 'regProgramCityCode'},
    {name: 'Статус РП', value: 'regProgramStateCode'},
    {name: 'Билет', value: 'ticketsFile'},
    {name: 'Визовое приглашение', value: 'visaRequirement'},
  ]

  constructor(private fileStorageService: FileStorageService) {
  }

  checkIfActive(event: boolean) {
    this.isActiveSelect = event;
  }
  getInfoTokenForDocument(documentId: number ,documentName: string) {
    this.fileStorageService.getTokenForDocument(documentId).pipe(untilDestroyed(this)).subscribe(hash => {
      this.downloadFile(hash,documentName);
    })
  }

  downloadFile(hash: string | undefined | null, fileName: string | undefined) {
    if (hash && fileName !== undefined) {
      fetch(environment.filePersonalUrl + hash)
        .then(response => response.blob())
        .then(blob => {
          const file = new File([blob], fileName, {type: blob.type});
          const url = window.URL.createObjectURL(file);
          const link = document.createElement('a');
          link.style.display = 'none';
          link.href = url;
          link.download = `${fileName}`;
          link.click();
        })

    }
  }

  onFileChange(event: Event, type: 'visaInvitation' | 'ticket'): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const filesArray = Array.from(input.files);
      this.uploadCustomFiles(filesArray, this.uploadedFiles, type);
    }
  }

  uploadCustomFiles(files: any[], uploadedFiles: any[], type: 'visaInvitation' | 'ticket'): void {
    // const filesCopy = [...files];
    // filesCopy.forEach((file, index) => {
    //   this.profileCardService.uploadImage(file).pipe(untilDestroyed(this))
    //     .subscribe(linkFile => {
    //       file.linkFile = linkFile;
    //       if (type === 'photo') {
    //         this.formInfoProfile.get('photo')?.patchValue({
    //           hash: linkFile.hash,
    //           name: linkFile.name
    //         });
    //         this.notify.success('Фотография профиля успешно загружена')
    //         this.formInfoProfile.get('photoId')?.patchValue(linkFile.id);
    //       } else if (type === 'digitalAvatar') {
    //         this.notify.success('Фото цифрового аватара успешно загружено')
    //         this.formInfoProfile.get('digitalAvatarId')?.patchValue(linkFile.id);
    //         this.formInfoProfile.get('digitalAvatar')?.patchValue({
    //           hash: linkFile.hash,
    //           name: linkFile.name
    //         });
    //       }
    //       if (uploadedFiles !== undefined) {
    //         uploadedFiles.push(file);
    //         files.splice(files.indexOf(file), 1);
    //       }
    //     });
    // });
  }


}
