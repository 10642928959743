import {ApplicationConfig, importProvidersFrom} from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import {provideClientHydration} from "@angular/platform-browser";
import {NgxChartsModule} from "@swimlane/ngx-charts";
import {BrowserAnimationsModule, NoopAnimationsModule, provideAnimations} from "@angular/platform-browser/animations";
import {authCodeFlowConfig} from "./sso.config";
import {AuthConfig, OAuthService, provideOAuthClient} from "angular-oauth2-oidc";
import {tokenInterceptor} from "./autorization/token.interceptor";
import {provideHttpClient, withInterceptors} from "@angular/common/http";
import {provideAnimationsAsync} from "@angular/platform-browser/animations/async";
import {AuthService} from "./core/services/auth/auth.service";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE, MatDateFormats,
} from "@angular/material/core";
import {CustomDateAdapter} from "./core/directive/custom-date.adapter";
import {DictionaryService} from "./core/services/pages/dictionary.service";
import {NgxSpinnerModule} from "ngx-spinner";
import {provideToastr} from "ngx-toastr";
import {provideMomentDatetimeAdapter} from "@ng-matero/extensions-moment-adapter";



export const CUSTOM_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD.MM.YYYY' as const, // формат ввода
  },
  display: {
    dateInput: 'DD.MM.YYYY' as const, // формат для input
    monthYearLabel: 'MMMM YYYY' as const, // Полное название месяца
    dateA11yLabel: 'LL' as const,
    monthYearA11yLabel: 'MMMM YYYY' as const,
  },
};


export const appConfig: ApplicationConfig = {
  providers: [
    NgxChartsModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    OAuthService,
    AuthService,
    DictionaryService,
    provideRouter(routes),
    provideAnimations(),
    provideClientHydration(),
    provideOAuthClient(),
    provideAnimationsAsync('noop'),
    provideHttpClient(withInterceptors([tokenInterceptor])),
    importProvidersFrom([BrowserAnimationsModule]),
    provideMomentDatetimeAdapter(),
    importProvidersFrom(NgxSpinnerModule.forRoot({ type: 'square-jelly-box' })),
    provideToastr({closeButton: true, positionClass: 'toast-bottom-right', progressBar: true, timeOut:5000,preventDuplicates:true}),
    { provide: DateAdapter, useClass: CustomDateAdapter},
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    { provide: AuthConfig, useValue: authCodeFlowConfig },
    { provide: MAT_DATE_LOCALE, useValue: 'ru-RU' },
  ],
};
