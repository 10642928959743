import {Component, Input, OnInit} from '@angular/core';
import {
  CustomSelectionComponent
} from "../../../../../assets/shared/components/select/custom-selection/custom-selection.component";
import {MatInput} from "@angular/material/input";
import {NgForOf, NgIf} from "@angular/common";
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {ProfileForm} from "../../../../core/interface/profile-card/profile-card-main.interface";
import {MatCheckbox} from "@angular/material/checkbox";
import {ProfileMainCardService} from "../profile-card.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {combineLatest} from "rxjs";


@UntilDestroy()
@Component({
  selector: 'app-more-information',
  standalone: true,
  imports: [
    CustomSelectionComponent,
    MatInput,
    NgForOf,
    NgIf,
    ReactiveFormsModule,
    MatCheckbox
  ],
  templateUrl: './more-information.component.html',
  styleUrl: './more-information.component.scss'
})
export class MoreInformationComponent implements OnInit {
  @Input() formInfoProfile!: FormGroup<ProfileForm>
  @Input() dictionaries: { [key: string]: any; } | undefined
  isActive: boolean = true;


  contentItemLabel = [
    {name: 'Направление', value: 'direction'},
    {name: 'Ограничение по здоровью', value: 'healthrestrictions'},
    {name: 'Другое', value: 'other'},
    {name: 'Не имею ограничений по здоровью', value: 'isNoHealthRestrictions'},
    {name: 'Тип питания', value: 'foodpreferences'},
    {name: 'Размер одежды', value: 'clothingSizeCode'},
  ]


  constructor(private profileMainCardService: ProfileMainCardService) {
  }

  ngOnInit() {
    this.checkIsNoHealthRestrictions()
  }

  getFilteredContentItemLabel(): { name: string; value: string }[] {
    return this.contentItemLabel.filter(
      item => item.value !== 'other' || this.getHealthRestrictionCodeControl()?.value?.includes('other')
    );
  }

  checkIsNoHealthRestrictions() {
    if (this.formInfoProfile.get('extraInformation.healthRestrictions') && this.formInfoProfile.get('extraInformation.isNoHealthRestrictions')) {
      combineLatest([
        this.profileMainCardService.isEdit$,
        this.formInfoProfile.get('extraInformation.isNoHealthRestrictions')!.valueChanges
      ]).pipe(untilDestroyed(this)).subscribe(([edit, isNoHealthRestrictions]) => {
          if (!isNoHealthRestrictions && edit) {
            this.formInfoProfile
              .get('extraInformation.healthRestrictions')
              ?.enable({ emitEvent: false });
          } else if (isNoHealthRestrictions) {
            this.formInfoProfile
              .get('extraInformation.healthRestrictions.healthRestrictionCode')
              ?.setValue(null, { emitEvent: false });
            this.formInfoProfile
              .get('extraInformation.healthRestrictions.healthRestrictionCode')
              ?.disable({ emitEvent: false });

            this.formInfoProfile
              .get('extraInformation.healthRestrictions.other')
              ?.setValue(null, { emitEvent: false });
            this.formInfoProfile
              .get('extraInformation.healthRestrictions.other')
              ?.disable({ emitEvent: false });
          } else {
            // Чекбокс false, но редактирование запрещено
            this.formInfoProfile
              .get('extraInformation.healthRestrictions.healthRestrictionCode')
              ?.disable({ emitEvent: false });
            this.formInfoProfile
              .get('extraInformation.healthRestrictions.other')
              ?.disable({ emitEvent: false });
          }
        });
    }
  }

  getHealthRestrictionCodeControl(): FormControl<string[]> {
    return this.formInfoProfile.get('extraInformation.healthRestrictions.healthRestrictionCode') as FormControl<string[]>;
  }


}
