<div [class.margin-bottom-unset]="!isActive"
     [formGroup]="formInfoApplication"
     class="form">
  <div (click)="isActive = !isActive" class="label-form">
    <img [class.rotate-180]="isActive" src="assets/images/ul-kit-img/arrow-black.svg">
    <span>Дополнительная информация</span>
  </div>
  <div [class.active]="isActive" class="form-content">
    <div class="left-content">
      <div *ngFor="let item of getFilteredContentItemLabel()" class="item-content-label">
        <img src="assets/images/ul-kit-img/green-point.svg">
        <span>{{item.name}}</span>
      </div>
    </div>


    <div class="right-content">

      <div class="item-info">
        <custom-selection [multipleCheckBox]="true"
                          [control]="formInfoApplication.controls.extraInformation.controls.directions"
                          [options]="dictionaries?.['direction']"
        ></custom-selection>
      </div>

      <div class="item-info">
        <custom-selection [multipleCheckBox]="true"
                          [control]="getHealthRestrictionCodeControl()"
                          [options]="dictionaries?.['healthrestrictions']"
        ></custom-selection>
      </div>

      <div *ngIf="getHealthRestrictionCodeControl()?.value?.includes('other')" class="item-info" formGroupName="extraInformation">
        <div formGroupName="healthRestrictions">
          <input  formControlName="other"
                  [placeholder]="formInfoApplication.get('other')?.value === null ? 'Не заполнено' : ''"
                  class="text-inter-semibold input-main-search" matInput>
        </div>
      </div>
      <div class="item-info block-with-checkbox">
        <input (keypress)="$event.preventDefault()"
               class="input-main-search input-checkbox hide"
               [formControl]="formInfoApplication.controls.extraInformation.controls.isNoHealthRestrictions"
               matInput>
        <mat-checkbox

          [formControl]="formInfoApplication.controls.extraInformation.controls.isNoHealthRestrictions"
          class="custom-checkbox">
          <label class="checkbox-label"></label></mat-checkbox>
      </div>
      <div class="item-info">
        <custom-selection [multipleCheckBox]="false"
                          [control]="formInfoApplication.controls.extraInformation.controls.foodPreferenceCode"
                          [options]="dictionaries?.['foodpreferences']"
        ></custom-selection>
      </div>

      <div class="item-info">
        <custom-selection [multipleCheckBox]="false"
                          [control]="formInfoApplication.controls.extraInformation.controls.clothingSizeCode"
                          [options]="dictionaries?.['clothingsizes']"
        ></custom-selection>
      </div>


      <!--      <div class="item-info block-with-checkbox">-->
      <!--        <input (keypress)="$event.preventDefault()"-->
      <!--               class="input-main-search input-checkbox"-->

      <!--               matInput>-->
      <!--        <mat-checkbox-->
      <!--          class="custom-checkbox">-->
      <!--          <label class="checkbox-label"></label></mat-checkbox>-->
      <!--      </div>-->
    </div>
  </div>
</div>
