import {HttpErrorResponse, HttpInterceptorFn} from '@angular/common/http';
import {inject} from "@angular/core";
import {AuthService} from '../core/services/auth/auth.service';
import {tap} from "rxjs/operators";

import {Router} from "@angular/router";
import {LoaderService} from "../../assets/shared/components/loader/loader.service";
import {ToastrService} from "ngx-toastr";



export const tokenInterceptor: HttpInterceptorFn = (req, next) => {

  const authStorage = inject(AuthService);
  const router = inject(Router);
  const loaderService = inject(LoaderService);
  const toastr = inject(ToastrService)
  if (!req.url.includes('/connect/token')){
    loaderService.start(req);
  }



  if (authStorage.hasValidToken()) {
    let token = authStorage.getAccessToken();
    let header = 'Bearer ' + token;

    let headers = req.headers
      .set('Authorization', header);

    req = req.clone({headers});
  }

  return next(req).pipe(tap(
    () => {
    },
    (error: HttpErrorResponse) => {
      switch (error.status) {
        case 400:
          toastr.error('Неверный запрос. Проверьте введенные данные и повторите попытку.', 'Ошибка: 400');
          break;
        case 401:
          toastr.error('Необходима авторизация. Пожалуйста, войдите в систему.', 'Ошибка: 401');
          router.navigate(['/login'])
          break;
        case 403:
          toastr.error('У вас нет прав доступа к этому ресурсу.', 'Ошибка: 403');
          router.navigate(['/error'])
          break;
        case 404:
          toastr.error('Запрашиваемый ресурс не найден.', 'Ошибка: 404');
          router.navigate(['/notfound'])
          break;
        case 405:
          toastr.error('Метод не разрешен для этого ресурса.', 'Ошибка: 405');
          break;
        case 408:
          toastr.error('Истекло время ожидания запроса. Попробуйте снова.', 'Ошибка: 408');
          break;
        case 409:
          toastr.error('Конфликт запроса. Проверьте данные и попробуйте снова.', 'Ошибка: 409');
          break;
        case 410:
          toastr.error('Ресурс больше не доступен (удалён).', 'Ошибка: 410');
          break;
        case 413:
          toastr.error('Размер запроса слишком велик. Попробуйте уменьшить размер данных.', 'Ошибка: 413');
          break;
        case 414:
          toastr.error('URI запроса слишком длинное. Попробуйте изменить параметры запроса.', 'Ошибка: 414');
          break;
        case 415:
          toastr.error('Неверный формат данных. Проверьте тип содержимого запроса.', 'Ошибка: 415');
          break;
        case 429:
          toastr.error('Слишком много запросов. Пожалуйста, попробуйте позже.', 'Ошибка: 429');
          break;
        case 500:
          toastr.error('Произошла ошибка на сервере.', 'Ошибка: 500');
          break;
        case 502:
          toastr.error('Ошибка шлюза.', 'Ошибка: 502');
          break;
        case 503:
          toastr.error('Сервер временно недоступен.', 'Ошибка: 503');
          break;
        case 504:
          toastr.error('Истекло время ожидания ответа от сервера.', 'Ошибка: 504');
          break;
        default:
          toastr.error('Произошла неизвестная ошибка.', 'Неизвестная ошибка');
          break;
      }
      loaderService.stop(req)
    },
    () => {
      loaderService.stop(req)
    }
  ));
};
