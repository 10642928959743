import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {Profile} from "../../../interface/profile-card/profile-card-main.interface";
import {ColumnTableInterface} from "../../../interface/table/column-table.interface";
import {SphereActivity} from "../../../interface/profile-card/profile-sphereActivity.interface";
import {ExtraInformation} from "../../../interface/profile-card/profile-extraInfo-interface";
import {Passport} from "../../../interface/profile-card/profile-passport.interface";
import {Photo} from "../../../interface/profile-card/profile-card-photo.interface";
import {Document} from "../../../interface/profile-card/profile-card-document.intareface";

@Injectable({
  providedIn: 'root'
})

export class ProfileCardService{

  constructor(private http: HttpClient) {}


  getInfoCardProfile(userUid: number){
    return this.http.get<Profile>(`${environment.baseUrl}/api/v1-web/UserProfile/Detail?userUid=${userUid}`)
  }

  getInfoCardIntegration(userUid: number){
    return this.http.get<Profile>(`${environment.integrationBaseUrl}/api/IntegrationCrm/GetProfileData?userUid=${userUid}`)
  }

  changeProfileStatusBlocked(userUid: number,reasonBlocking:string ){
    return this.http.get<string>(`${environment.integrationBaseUrl}/api/IntegrationCrm/ChangeProfileStatusBlocked?userUid=${userUid}&reasonBlocking=${reasonBlocking}`)
  }

  changeProfileStatusUnblocked(userUid: number){
    return this.http.get<string>(`${environment.integrationBaseUrl}/api/IntegrationCrm/ChangeProfileStatusUnlocked?userUid=${userUid}`)
  }

  changeProfileUser(request: Profile){
    return this.http.post<string>(`${environment.integrationBaseUrl}/api/IntegrationCrm/UpdateUserProfileData`,request)
  }

  changeProfileUserPassportData(request: Passport){
    return this.http.post<string>(`${environment.integrationBaseUrl}/api/IntegrationCrm/UpdateUserProfilePassportData`,request)
  }

  changeProfileUserSphereActivity(request: SphereActivity){
    return this.http.post<string>(`${environment.integrationBaseUrl}/api/IntegrationCrm/UpdateUserProfileSphereActivity`,request)
  }
  changeProfileUserExtraInformation(request: ExtraInformation){
    return this.http.post<string>(`${environment.integrationBaseUrl}/api/IntegrationCrm/UpdateUserProfileExtraInformation`,request)
  }
  uploadPersonalFile(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post<Document>(
      `${environment.integrationBaseUrl}/api/IntegrationCrm/UploadPersonalFile`,
      formData
    );
  }

  uploadImage(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post<Photo>(
      `${environment.integrationBaseUrl}/api/IntegrationCrm/UploadImage`,
      formData
    );
  }

}
