import {Component, OnInit} from '@angular/core';
import {ButtonBackComponent} from "../../../../assets/shared/components/button-back/button-back.component";
import {ChangeHistoryComponent} from "../../../../assets/shared/components/change-history/change-history.component";
import {DropdownComponent} from "../../../../assets/shared/components/dropdown/dropdown-basic/dropdown.component";

import {NgClass, NgIf} from "@angular/common";
import {
  GeneralInformationApplicationComponent,

} from "./general-information/general-information.component";
import {
  PersonalInformationApplicationComponent,

} from "./personal-information/personal-information.component";
import {
  PassportInformationApplicationComponent,

} from "./passport-information/passport-information.component";
import {
  SocialInformationApplicationComponent,

} from "./social-information/social-information.component";
import {
  MoreInformationApplicationComponent,

} from "./more-information/more-information.component";
import {
  FieldActivityInformationApplicationComponent,

} from "./field-activity-information/field-activity-information.component";
import {
  InfoApplicationApplicationComponent,

} from "./info-application/info-application.component";
import {FormGroup} from "@angular/forms";
import {ProfileForm} from "../../../core/interface/profile-card/profile-card-main.interface";
import {
  ModalGeneralConfirmComponent
} from "../../../layout/dialogs/modal-general-confirm/modal-general-confirm.component";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {MatDialog} from "@angular/material/dialog";
import {ApplicationCardMainService} from "./application-card.service";
import {ActivatedRoute} from "@angular/router";
import {DictionaryService} from "../../../core/services/pages/dictionary.service";
import {ApplicationForm} from "../../../core/interface/application/application.main.interface";
import {ApplicationMainService} from "../application-main-service";
import {NotificationService} from "../../../core/services/notification/notification.service";
import {
  AnswersQuestionsComponent
} from "../../../../assets/shared/components/answers-questions/answers-questions.component";

@UntilDestroy()
@Component({
  selector: 'app-application-card',
  standalone: true,
  imports: [
    ButtonBackComponent,
    DropdownComponent,
    NgIf,
    NgClass,
    MoreInformationApplicationComponent,
    FieldActivityInformationApplicationComponent,
    SocialInformationApplicationComponent,
    PassportInformationApplicationComponent,
    PersonalInformationApplicationComponent,
    InfoApplicationApplicationComponent,
    GeneralInformationApplicationComponent,
    AnswersQuestionsComponent,

  ],
  templateUrl: './application-card.component.html',
  styleUrl: './application-card.component.scss'
})
export class ApplicationCardComponent implements OnInit {
  userUid = this.activatedRoute.snapshot.params['id'];
  codeEvent= this.activatedRoute.snapshot.params['codeEvent'];
  formInfoApplication: FormGroup<ApplicationForm> = this.applicationCardMainService.formInfoApplication
  dictionaries!: { [key: string]: any; } | undefined
  questions: string[] | undefined
  isEdit?: boolean
  isDropdownOpen = false;
  locationRegProgram: Array<string> | undefined
  // historyChanges = [
  //   {date: '2024-12-01', description: 'description', author: 'Герман11 Герман11 Герман11'},
  //   {date: '2024-12-10', description: 'description', author: 'Герман11 Герман11 Герман11'},
  //   {date: '2024-12-20', description: 'description', author: 'Герман11 Герман11 Герман11'},
  // ];

constructor( private dialog: MatDialog,
             private applicationMainService: ApplicationMainService,
             private notify: NotificationService,
             private activatedRoute: ActivatedRoute,
             private dictionaryService: DictionaryService,
             private applicationCardMainService:ApplicationCardMainService) {
  this.loadDictionaries()

}


  ngOnInit() {
    this.getInfoEditFormCard()
    this.getInfoDictionaries()
    this.getInfoAnswersQuestions(this.userUid,this.codeEvent)
    this.getInfoLocationCity(this.codeEvent)
  }
  settingsMenu() {
    return  [
      {
        label: 'Статус заявки',
        subMenu: this.dictionaries?.['moderationstates']
          ? this.dictionaries!['moderationstates'].map((state: any) => ({
            label: state.name,
            action: () => this.changeApplicationsState(this.userUid,this.codeEvent,state.code)
          }))
          : []
      },
      {
        label: 'Статус ДВФМ',
        subMenu: this.dictionaries?.['statusdvfm']
          ? this.dictionaries!['statusdvfm'].map((state: any) => ({
            label: state.name,
            action: () => this.changeApplicationStatusDvfm(this.userUid,this.codeEvent,state.code),
          }))
          : []
      },
      {
        label: 'Смена города РП',
        subMenu: this.dictionaries?.['cities']
          ? this.dictionaries!['cities']
            .filter((locationCode: any) => this.locationRegProgram?.includes(locationCode.code))
            .map((locationCode: any) => ({
              label: locationCode.name,
              action: () => this.changeLocationRegprogram(this.userUid, this.codeEvent, locationCode.code),
            }))
          : []
      },
      {
        label: 'Смена билета ',
        subMenu: [{
          label: 'Да',
          action: () =>  this.changeTicketApplication(this.userUid,this.codeEvent,true),
        }
        ,{
            label: 'Нет',
            action: () => this.changeTicketApplication(this.userUid,this.codeEvent,false),
          }],
      },
      {
        label: 'Статус РП',
        subMenu: this.dictionaries?.['moderationstates']
          ? this.dictionaries!['moderationstates'].map((state: any) => ({
            label: state.name,
            action: () => this.changeApplicationsStateRP(this.userUid,this.codeEvent,state.code),
          }))
          : []
      },
      { label: 'Сгенерировать визовое приглашение',
        action: () => this.addVisaInvitationGenerate(this.userUid,this.codeEvent),
      },
    ];
  }
  getInfoLocationCity(codeEvent: string | null | undefined){
    this.applicationCardMainService.getInfoLocationCity(codeEvent).pipe(untilDestroyed(this)).subscribe(code =>{
      this.locationRegProgram = code
    })
  }

  changeLocationRegprogram(userUid: number | null | undefined,codeEvent: string | undefined | null,locationCode: string | undefined | null){
    this.applicationCardMainService.changeLocationRegprogram(userUid,codeEvent,locationCode)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.notify.success('Успешно сменился статус билета');
          this.getInfoProfileCard();
          this.isDropdownOpen = false
        },
        error: (err) => {
          this.notify.error('Ошибка при смене статус билета');
          this.isDropdownOpen = false
        }
      });
  }
  changeTicketApplication(userUid: number | null | undefined,codeEvent: string | undefined | null,ownExpense: boolean){
    this.applicationCardMainService.changeTicketApplication(userUid,codeEvent,ownExpense)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.notify.success('Успешно сменился статус билета');
          this.getInfoProfileCard();
          this.isDropdownOpen = false
        },
        error: (err) => {
          this.notify.error('Ошибка при смене статус билета');
          this.isDropdownOpen = false
        }
      });
  }
  changeApplicationsStateRP(userUid: number | undefined | null,codeEvent: string | undefined | null,moderationStateCode: string){
    this.applicationCardMainService.changeApplicationsStateRP(userUid,codeEvent,moderationStateCode)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.notify.success('Статус успешно изменён');
          this.getInfoProfileCard();
          this.isDropdownOpen = false
        },
        error: (err) => {
          this.notify.error('Ошибка при смене статуса');
          this.isDropdownOpen = false
        }
      });
  }

  changeApplicationStatusDvfm(userUid: number,codeEvent: string,moderationStateCode: string) {
    this.applicationCardMainService.changeApplicationStatusDvfm(userUid,codeEvent,moderationStateCode)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.notify.success('Статус успешно изменён');
          this.getInfoProfileCard();
          this.isDropdownOpen = false
        },
        error: (err) => {
          this.notify.error('Ошибка при смене статуса');
          this.isDropdownOpen = false
        }
      });
  }
  addVisaInvitationGenerate(userUid: number | undefined | null,codeEvent: string | undefined | null){
    this.applicationCardMainService.addVisaInvitationGenerate(userUid,codeEvent).pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.notify.success('Визовое приглашение успешно сгенерировалось');
          this.getInfoProfileCard();
          this.isDropdownOpen = false
        },
        error: (err) => {
          this.notify.error('Ошибка при генерации визового приглашения');
          this.isDropdownOpen = false
        }
      });
  }
  changeApplicationsState(userUid: number | undefined | null,codeEvent: string | undefined | null,moderationStateCode: string) {
    this.applicationCardMainService.changeApplicationsState(userUid,codeEvent,moderationStateCode)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.notify.success('Статус успешно изменён');
          this.getInfoProfileCard();
          this.isDropdownOpen = false
        },
        error: (err) => {
          this.notify.error('Ошибка при смене статуса');
          this.isDropdownOpen = false
        }
      });
  }
  getInfoEditFormCard() {
    this.applicationCardMainService.isEdit$.pipe(untilDestroyed(this)).subscribe(isEdit => {
      this.isEdit = isEdit
    });
  }
  openModalConfirmGeneral() {
    const dialogRef = this.dialog.open(ModalGeneralConfirmComponent, {
      data: {
        message: 'подтвердить это действие'
      }
    });
    dialogRef.afterClosed().pipe(untilDestroyed(this)).subscribe(result => {
      if (result) {
        this.applicationCardMainService.isEdit.next(false);
      }
    });
  }

  getInfoAnswersQuestions(userUid: number | undefined | null,codeEvent: string | undefined | null){
  this.applicationCardMainService.getInfoAnswersQuestions(userUid,codeEvent).pipe(untilDestroyed(this)).subscribe(questions =>{
    this.questions = questions
  })
  }
  isEditForm(): void {
     this.applicationCardMainService.isEditForm();
  }


  canselForm(): void {
     this.applicationCardMainService.canselForm();
  }
  loadDictionaries() {
    let dictionariesList: string[] = [
      'genders',
      'direction',
      'regions',
      'languages',
      'events',
      'countries',
      'participantstatus',
      'cities',
      'statusdvfm',
      'moderationstates',
      'statuspersonalaccount',
      'healthrestrictions',
      'foodpreferences',
      'socialnetworktypes',
      'languagelevels',
      'clothingsizes',
      'statuspersonalaccount',
      'titles'
    ];
    this.applicationCardMainService.loadDictionaries(dictionariesList)
    this.getInfoProfileCard()
  }

  getInfoDictionaries() {
    this.dictionaryService.dictionaries$.pipe(untilDestroyed(this)).subscribe(x => {
      this.dictionaries = x
    })
  }
  getInfoProfileCard() {
    this.applicationCardMainService.getInfoApplicationCard(this.codeEvent,this.userUid)
  }
}
