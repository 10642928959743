import { Routes } from '@angular/router';
import {HomeComponent} from "./pages/home/home.component";
import {ProfileComponent} from "./pages/profile/profile.component";
import {ApplicationComponent} from "./pages/application/application.component";
import {AnalyticsComponent} from "./pages/analytics/analytics.component";
import {InstructionsComponent} from "./pages/instructions/instructions.component";
import {MailingComponent} from "./pages/mailing/mailing.component";
import {LoginComponent} from "./autorization/components/auth/login/login.component";
import {AuthorizeGuard} from "./autorization/auth.guard";
import {LoginCompletedComponent} from "./autorization/components/auth/login-completed/login-completed.component";
import {NotFoundComponent} from "../assets/shared/components/not-found/not-found.component";
import {ProfileCardComponent} from "./pages/profile/profile-card/profile-card.component";
import {ProfileMainService} from "./pages/profile/profile-main.service";
import {ProfileMainCardService} from "./pages/profile/profile-card/profile-card.service";
import {HeaderComponent} from "./layout/header/header.component";
import {ApplicationMainService} from "./pages/application/application-main-service";
import {ApplicationCardComponent} from "./pages/application/application-card/application-card.component";
import {ProfileTableService} from "./core/services/pages/profile/profile-table.service";
import {ApplicationCardMainService} from "./pages/application/application-card/application-card.service";
export const routes: Routes = [

  {path: '', redirectTo: '/login', pathMatch: 'full'},

  {
    path: '',
    canActivate: [AuthorizeGuard],
    children: [
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: '',
        component: HeaderComponent,
        children: [
          {
            path: 'profile',
            children: [
              {
                path: '',
                component: ProfileComponent,
                providers: [ProfileMainService,ProfileTableService],
              },
              {
                path: 'profile-card/:id',
                component: ProfileCardComponent,
                providers: [ProfileMainCardService]
              }
            ]
          },
          {
            path: 'application',
            providers: [ApplicationMainService],
            children: [
              {
                path: '',
                component: ApplicationComponent,

              },
              {
                path: 'application-card/:codeEvent/:id',
                component: ApplicationCardComponent,
                 providers: [ApplicationCardMainService]
              }
            ]
          },
          {
            path: 'analytics',
            component: AnalyticsComponent
          },
          {
            path: 'instructions',
            component: InstructionsComponent
          },
          {
            path: 'mailing',
            component: MailingComponent
          }

        ]
      }
    ],
  },
  {path: 'notfound', component: NotFoundComponent},
  {path: 'error', component: NotFoundComponent},

  // Auth routing
  {path: 'login', component: LoginComponent},
  {path: 'signin-oidc', component: LoginCompletedComponent},
  {path: 'signout-callback-oidc', redirectTo: '/'},
  {path: '**', redirectTo: '/notfound'},
];
