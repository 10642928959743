import {Component, Input} from '@angular/core';
import {NgClass, NgForOf, NgIf, NgStyle} from "@angular/common";


@Component({
  selector: 'app-context-menu-profile-table',
  standalone: true,
  imports: [
    NgStyle,
    NgIf,
    NgForOf,
    NgClass
  ],
  templateUrl: './context-menu-profile-table.component.html',
  styleUrl: './context-menu-profile-table.component.scss'
})
export class ContextMenuProfileTableComponent {
  @Input() contextMenu: any
  @Input() isVisible: boolean = false;
  @Input() position: { x: string, y: string } = { x: '0px', y: '0px' };


}
