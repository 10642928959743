<div [class.margin-bottom-unset]="!isActive" class="form">
  <div (click)="isActive = !isActive" class="label-form">
    <img [class.rotate-180]="isActive" src="assets/images/ul-kit-img/arrow-black.svg">
    <span>Ответы на вопросы</span>
  </div>
  <div [class.active]="isActive" class="form-content">
    <div *ngIf="questions?.length === 0" class="right-content">
      <div class="item-info">
        Ответы отсутствуют
      </div>
    </div>
    <div *ngFor="let answer of questions" class="right-content">
      <div class="item-info">
        {{ answer }}
      </div>
    </div>
  </div>
</div>
