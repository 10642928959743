import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../core/services/auth/auth.service';
import {Router} from "@angular/router";

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {

  constructor(
    private router: Router,
    private authService: AuthService) {
  }

  async ngOnInit() {
    await this.authService.login().then(isAuth => {
      if(isAuth) this.router.navigate(['/home'])
    });
  }
}
