import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {Injectable} from "@angular/core";
import {TableInfo} from "../../core/interface/table/table-info";
import {BehaviorSubject} from "rxjs";
import {HeadersTableInterface} from "../../core/interface/table/headers-table.interface";
import {DictionaryService} from "../../core/services/pages/dictionary.service";
import {ApplicationTableService} from "../../core/services/pages/application/application-table.service";
import {ApplicationCardService} from "../../core/services/pages/application/application-card.service";


@UntilDestroy()
@Injectable()

export class ApplicationMainService {
  tableInfo: TableInfo = {
    tableHeaders: [],
    orderByDescending: undefined,
    take: 100,
    orderByColumn: undefined,
    quickSearch: {
      fields: undefined,
      value: '',
    },
    skip: undefined,
    totalCount: undefined,
    mainCheckboxChecked: undefined
  };

  private itemColumnTable = new BehaviorSubject<any[]>([]);
  itemColumnTable$ = this.itemColumnTable.asObservable();

  private selectedIds = new BehaviorSubject<number[]>([]);
  selectedIds$ = this.selectedIds.asObservable();


  constructor(private dictionaryService: DictionaryService,
              private applicationCardService: ApplicationCardService,
              private applicationTableService: ApplicationTableService) {
    this.getFirstUpdateTableHeadersProfile();
  }


  updateTableProfile() {
    this.applicationTableService.getColumnInfoProfile({
      page: this.currentPage,
      take: this.tableInfo.take,
      orderByColumn: this.tableInfo.orderByColumn,
      orderByDescending: this.tableInfo.orderByDescending,
      selectedFields: this.tableInfo.tableHeaders.map(x => x.code),
      filterFields: this.tableInfo.tableHeaders
        .filter(x => x.value != null && x.value != undefined)
        .map(x => ({
          field: x.code,
          operation: x.operation,
          value: x.subValue ? [x.value, x.subValue] : x.value
        })),
      quickSearch: {
        fields: this.tableInfo.quickSearch?.fields || this.tableInfo.tableHeaders
          .filter(x => x.type == 'string' || x.type == 'number' /*||  v.type == 'dictionary' || v.type == 'dictionaryList'*/)
          .map(code => code.code),
        value: this.tableInfo.quickSearch?.value || '',
      },
    }).pipe(untilDestroyed(this)).subscribe(infoColumn => {
      this.itemColumnTable.next(infoColumn.items ?? [])
      this.tableInfo.totalCount = infoColumn.totalCount ?? 0
    })
  }

  getFirstUpdateTableHeadersProfile() {
    this.applicationTableService.getInfoHeaders().pipe(untilDestroyed(this)).subscribe(infoHeaders => {
      this.tableInfo.tableHeaders = infoHeaders
      if (infoHeaders) {
        this.updateTableProfile()
      }
      this.loadDictionaries(infoHeaders);
    })
  }

  private loadDictionaries(infoDictionaries: HeadersTableInterface[]) {
    this.dictionaryService.loadDictionaries(infoDictionaries)
  }

  getInfoTakeProfileTable() {
    return this.tableInfo.tableHeaders.filter(v => v.type == 'string'
      || v.type == 'number' /*||  v.type == 'dictionary' || v.type == 'dictionaryList'*/)
  }


  public changePage(page: number) {
    this.tableInfo.skip = (page - 1) * this.tableInfo.take!
    this.updateTableProfile()
  }

  public changeTake(take: number) {
    this.tableInfo.take = take;
    this.tableInfo.skip = 0
    this.updateTableProfile()
  }

  get currentPage(): number {
    if (this.tableInfo.take === undefined
      || this.tableInfo.take === 0 || this.tableInfo.skip === undefined) {
      return 1;
    }
    return Math.floor(this.tableInfo.skip / this.tableInfo.take) + 1;
  }

  // Метод для изменения состояния главного чекбокса и всех дочерних элементов на текущей странице
  checkAll(event: boolean): void {
    const allIdsOnPage = this.itemColumnTable.getValue()
      .map(profile => profile.Id)
      .filter((Id): Id is number => Id !== undefined);
    if (event === true) {
      this.selectedIds.next([...new Set([...this.selectedIds.getValue(), ...allIdsOnPage])]);
    } else {
      this.selectedIds.next(this.selectedIds.getValue().filter(id => !allIdsOnPage.includes(id)));
    }
    this.updateMainCheckboxState();
  }

  // Метод для изменения состояния главного чекбокса
  updateMainCheckboxState(): void {
    this.itemColumnTable.getValue().map(profile => profile.Id)
      .filter((Id): Id is number => Id !== undefined);
    if (this.itemColumnTable.getValue().length === 0) {
      this.tableInfo.mainCheckboxChecked = false;
      return;
    }
    this.tableInfo.mainCheckboxChecked = (this.itemColumnTable.getValue().length > 0 &&
      this.itemColumnTable.getValue().every(Id => this.selectedIds.getValue().includes(Id)));
  }


  addVisaInvitationGenerate( userUid: number | undefined | null,codeEvent: string | undefined | null,) {
    return  this.applicationCardService.addVisaInvitationGenerate(userUid, codeEvent)
  }
  changeApplicationsState(userUid: number | undefined | null,codeEvent: string | undefined | null,moderationStateCode: string) {
   return  this.applicationCardService.changeApplicationStatus(userUid,codeEvent,moderationStateCode)
  }
  changeApplicationStatusDvfm(userUid: number | undefined | null,codeEvent: string | undefined | null,moderationStateCode: string) {
    return  this.applicationCardService.changeApplicationStatusDvfm(userUid,codeEvent,moderationStateCode)
  }
  // Метод для проверки состояния главного чекбокса
  isMainCheckboxChecked(): boolean {
    let allIdsOnPage = this.itemColumnTable.getValue()
      .map(profile => profile.Id)
      .filter((Id): Id is number => Id !== undefined);
    if (this.itemColumnTable.getValue().length === 0) {
      return false;
    }
    return allIdsOnPage.every(Id => this.selectedIds.getValue().includes(Id));
  }

  // Метод для изменения состояния отдельных чекбоксов
  onModerationChange(event: { checked: boolean }, Id: number | undefined): void {
    if (event.checked && Id != null) {
      this.selectedIds.next([...this.selectedIds.getValue(), Id]);
    } else {
      this.selectedIds.next(this.selectedIds.getValue().filter(selectedId => selectedId !== Id));
    }
    this.updateMainCheckboxState();
  }

  isModerationChecked(Id: number): boolean {
    return this.selectedIds.getValue().includes(Id);
  }

  getDictionaryListNamesByCode(code: string, dictionaryType: string): string {
    return <string>code?.split(',').map(code => code.trim()).map(code =>
      this.getDictionaryNameByCode(code, dictionaryType)).filter(name => name).join(', ');
  }

  getDictionaryNameByCode(code: string, dictionaryType: string): string {
    return this.dictionaryService.dictionaries.value[dictionaryType]?.find((item: {
      code: string
    }) => item.code === code)?.name
  }
}
