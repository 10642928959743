import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {AfterViewInit, Component, HostListener, OnInit} from "@angular/core";
import { ElementRef, OnDestroy, Renderer2, ViewChild} from "@angular/core";
import {TableComponent} from "../../../../assets/shared/components/table/table.component";
import {MatCheckbox} from "@angular/material/checkbox";
import {DatePipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {NgxPaginationModule} from "ngx-pagination";
import {TableInfo} from "../../../core/interface/table/table-info";
import {Router} from "@angular/router";
import {ProfileMainService} from "../profile-main.service";
import {DictionaryService} from "../../../core/services/pages/dictionary.service";
import {
  ContextMenuProfileTableComponent
} from "../../../../assets/shared/components/context-menu/context-menu-profile-table/context-menu-profile-table.component";
import {CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport} from "@angular/cdk/scrolling";
import {ContextMenu} from "../../../core/interface/context-menu/context-menu.interface";


@UntilDestroy()
@Component({
  selector: 'app-profile-table',
  standalone: true,
  imports: [
    TableComponent,
    MatCheckbox,
    NgForOf,
    FormsModule,
    NgxPaginationModule,
    NgIf,
    DatePipe,
    ContextMenuProfileTableComponent,
    NgClass,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
  ],
  templateUrl: './profile-table.component.html',
  styleUrl: './profile-table.component.scss'
})
export class ProfileTableComponent implements OnInit,AfterViewInit{
  isContextMenuVisible: boolean = false;
  contextMenuPosition = { x: '0px', y: '0px' };
  currentRow: any;
  tableInfo: TableInfo = this.profileMainService.tableInfo;
  dictionaries: { [key: string]: any; } | undefined
  itemColumnTable: any[] = [];
  selectedIds: number[] | undefined
  @ViewChild(CdkVirtualScrollViewport, {static: false})
  viewPort!: CdkVirtualScrollViewport;
  contextMenu: ContextMenu[] = [];
  constructor(private router: Router, private profileMainService: ProfileMainService
              ,private dictionaryService:DictionaryService) {
  }

  ngOnInit() {
    this.getInfoTableColumnProfile()
    this.getInfoCheckboxLangTotal()
    this.getInfoDictionaries()
    this.inverseOfTranslationHeader
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.viewPort) {
        this.viewPort.checkViewportSize();
      }
    });
  }

  public get inverseOfTranslationHeader(): string {
    if (!this.viewPort || !this.viewPort["_renderedContentOffset"]) {
      return "-0px";
    }
    let offset = this.viewPort["_renderedContentOffset"];
    return `-${offset}px`;
  }

  checkAll(event: boolean) {
    this.profileMainService.checkAll(event)
  }

  isMainCheckboxChecked(): boolean {
    return this.profileMainService.isMainCheckboxChecked();
  }
  getInfoCheckboxLangTotal(){
    this.profileMainService.selectedIds$.pipe(untilDestroyed(this)).subscribe( total =>{
      this.selectedIds = total
    })
  }
  getInfoDictionaries(){
    this.dictionaryService.dictionaries$.pipe(untilDestroyed(this)).subscribe(dictionaries =>{
      this.dictionaries = dictionaries
    })
  }

  getInfoTableColumnProfile() {
    this.profileMainService.itemColumnTable$.pipe(untilDestroyed(this)).subscribe(y => {
      this.itemColumnTable = y
    })
  }

  routerInCard(userUid: number) {
     this.router.navigate(['profile/profile-card/' + userUid])
  }

  highlightText(text: string | null, search: string | undefined): string {
    if (!search || !text) {
      return text || '';
    }
    return text.replace(
      new RegExp(`(${search})`, 'gi'),
      '<mark>$1</mark>');
  }

  getDictionaryListNamesByCode(code: string, dictionaryType: string): string {
    return this.profileMainService.getDictionaryListNamesByCode(code, dictionaryType) ? this.profileMainService.getDictionaryListNamesByCode(code, dictionaryType) : 'Нет данных'
  }

  getDictionaryNameByCode(code: string, dictionaryType: string): string {
    return this.profileMainService.getDictionaryNameByCode(code, dictionaryType) ? this.profileMainService.getDictionaryNameByCode(code, dictionaryType) : 'Нет данных'
  }
  get currentPage(): number {
    return this.profileMainService.currentPage;
  }


  onModerationChange(event: { checked: boolean }, Id: number | undefined): void {
    this.profileMainService.onModerationChange(event, Id)
    this.isContextMenuVisible = false;
  }

  checkIfModerationChecked(id: number | undefined): boolean {
    return this.profileMainService.isModerationChecked(id!);
  }

  getTable() {
    this.profileMainService.updateTableProfile();
  }

  openContextMenu(event: MouseEvent, columnItem: any) {
    event.preventDefault();
    this.isContextMenuVisible = true;
    this.contextMenuPosition = { x: `${event.clientX}px`, y: `${event.clientY}px` };
    this.currentRow = columnItem;
    this.contextMenu = [
      {
        label: this.selectedIds?.includes(this.currentRow.Id) ? 'Отменить' : 'Выбрать',
        action: () => this.onModerationChange(
          { checked: !this.selectedIds?.includes(this.currentRow.Id) },
          this.currentRow.Id),
      },
      {
        label: 'Перейти',
        action: () => this.routerInCard(this.currentRow.UserUid)
      }
    ];
  }
  closeContextMenu() {
    this.isContextMenuVisible = false;
  }
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  @HostListener('document:keydown', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (!document.querySelector('.context-menu')?.contains(event.target as Node)) {
      this.closeContextMenu();
    }
  }

  isTextOverflow(el: HTMLElement): boolean {
    if (!el) return false;
    return el.scrollHeight > el.clientHeight + 10 || el.scrollWidth > el.clientWidth + 10;
  }
}
