import {Component, Input} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {MatFormField, MatInput, MatInputModule} from "@angular/material/input";
import {CustomSelectionComponent
} from "../../../../../assets/shared/components/select/custom-selection/custom-selection.component";
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {MatOption, MatSelect} from "@angular/material/select";
import {MatCheckbox} from "@angular/material/checkbox";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ProfileForm} from "../../../../core/interface/profile-card/profile-card-main.interface";
import {environment} from "../../../../../environments/environment";
import {ModalShowPhotoComponent} from "../../../../layout/dialogs/modal-show-photo/modal-show-photo.component";
import {MatDialog} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {
  DateAndTimepickerComponent
} from "../../../../../assets/shared/components/date-and-timepicker/date-and-timepicker.component";
import {NotificationService} from "../../../../core/services/notification/notification.service";
import {ProfileCardService} from "../../../../core/services/pages/profile/profile-card.service";

@UntilDestroy()
@Component({
  selector: 'app-general-information',
  standalone: true,
  imports: [
    NgForOf,
    MatInput,
    NgIf,
    CustomSelectionComponent,
    ReactiveFormsModule,
    MatSelect,
    MatCheckbox,
    MatFormField,
    MatOption,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    DateAndTimepickerComponent,
  ],
  templateUrl: './general-information.component.html',
  styleUrl: './general-information.component.scss',

})
export class GeneralInformationComponent {
  @Input() formInfoProfile!: FormGroup<ProfileForm>
  @Input() dictionaries: { [key: string]: any; } | undefined
  @Input() isEdit?: boolean
  isActive: boolean = true;
  isActiveSelect: boolean = false;
  uploadedFiles!: any[]

  contentItemLabel = [
    {name: 'Логин', value: 'email'},
    {name: 'Статус ЛК', value: 'statusParticipantCode'},
    {name: 'Дата и время регистрации', value: 'dateRegistration'},
    {name: 'Причина блокировки', value: 'block'},
    {name: 'Согласие на рассылку', value: 'mailingIsAccept'},
    {name: 'Тест', value: 'isTest'},
    {name: 'Фотография профиля', value: 'photo'},
    {name: 'Фото цифрового аватара', value: 'avatar'},
  ]

  constructor(private dialog: MatDialog, private notify: NotificationService, private profileCardService: ProfileCardService) {
  }

  checkIfActive(event: boolean) {
    this.isActiveSelect = event;
  }

  openModalShowImage(fileName: string | null | undefined, fileHash: string | null | undefined): void {
    const dialogRef = this.dialog.open(ModalShowPhotoComponent, {
      data: {
        fileName: fileName,
        fileHash: fileHash,
      }
    });
    dialogRef.afterClosed().pipe(untilDestroyed(this)).subscribe(result => {
      if (result) {
      }
    });
  }

  downloadFile(hash: string | undefined | null, fileName: string | undefined) {
    if (hash && fileName !== undefined) {
      fetch(environment.saveFile + hash)
        .then(response => response.blob())
        .then(blob => {
          const file = new File([blob], fileName, {type: blob.type});
          const url = window.URL.createObjectURL(file);
          const link = document.createElement('a');
          link.style.display = 'none';
          link.href = url;
          link.download = fileName;
          link.click();
        })

    }
  }

  onFileChange(event: Event, type: 'photo' | 'digitalAvatar'): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const filesArray = Array.from(input.files);
       this.uploadCustomFiles(filesArray, this.uploadedFiles, type);
    }
  }

  uploadCustomFiles(files: any[], uploadedFiles: any[], type: 'photo' | 'digitalAvatar'): void {
    const filesCopy = [...files];
    filesCopy.forEach((file, index) => {
      this.profileCardService.uploadImage(file).pipe(untilDestroyed(this))
        .subscribe(linkFile => {
          file.linkFile = linkFile;
          if (type === 'photo') {
            this.formInfoProfile.get('photo')?.patchValue({
              hash: linkFile.hash,
              name: linkFile.name
            });
            this.notify.success('Фотография профиля успешно загружена')
            this.formInfoProfile.get('photoId')?.patchValue(linkFile.id);
          } else if (type === 'digitalAvatar') {
            this.notify.success('Фото цифрового аватара успешно загружено')
            this.formInfoProfile.get('digitalAvatarId')?.patchValue(linkFile.id);
            this.formInfoProfile.get('digitalAvatar')?.patchValue({
              hash: linkFile.hash,
              name: linkFile.name
            });
          }
          if (uploadedFiles !== undefined) {
            uploadedFiles.push(file);
            files.splice(files.indexOf(file), 1);
          }
        });
    });
  }


  protected readonly environment = environment;
}
