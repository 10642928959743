<div [class.margin-bottom-unset]="!isActive" [formGroup]="formInfoApplication"
     class="form">
  <div (click)="isActive = !isActive" class="label-form">
    <img [class.rotate-180]="isActive" src="assets/images/ul-kit-img/arrow-black.svg">
    <span>Информация по заявке</span>
  </div>
  <div [class.active]="isActive" class="form-content">
    <div class="left-content">
      <div *ngFor="let item of contentItemLabel; let i = index; let last = last"
           class="item-content-label"
      >
        <img src="assets/images/ul-kit-img/green-point.svg">
        <span>{{ item.name }}</span>
      </div>
    </div>
    <div class="right-content">

      <div class="item-info">
        <custom-selection [multipleCheckBox]="false"
                          [control]="formInfoApplication.controls.eventCode"
                          [options]="dictionaries?.['events']"
        ></custom-selection>
      </div>


      <div class="item-info">
        <input formControlName="applicationUid" class="text-inter-semibold input-main-search"
                [placeholder]="formInfoApplication.get('applicationUid')?.value === null ? 'Не заполнено' : ''"
                matInput>
      </div>

      <div class="item-info">
        <custom-selection [multipleCheckBox]="false"
                          [control]="formInfoApplication.controls.moderationStateCode"
                          [options]="dictionaries?.['moderationstates']"
        ></custom-selection>
      </div>

      <div class="item-info">
        <app-date-and-timepicker [control]="formInfoApplication.controls.dateOfRequest">
        </app-date-and-timepicker>
      </div>

      <div class="item-info">
        <input formControlName="reasonForRefusal" class="text-inter-semibold input-main-search"
               [placeholder]="formInfoApplication.get('reasonForRefusal')?.value === null ? 'Не заполнено' : ''"
               matInput>
      </div>


      <div class="item-info">
        <app-date-and-timepicker [control]="formInfoApplication.controls.dateOfApproval">
        </app-date-and-timepicker>
      </div>

      <div class="item-info">
        <custom-selection [multipleCheckBox]="false"
                          [control]="formInfoApplication.controls.statusDVFM"
                          [options]="dictionaries?.['statusdvfm']"
        ></custom-selection>
      </div>

      <div class="item-info">
        <custom-selection [multipleCheckBox]="false"
                          [control]="formInfoApplication.controls.departureOksmCode"
                          [options]="dictionaries?.['countries']"
        ></custom-selection>
      </div>

      <div class="item-info">
        <custom-selection [multipleCheckBox]="false"
                          [control]="formInfoApplication.controls.departureCityCode"
                          [options]="dictionaries?.['cities']"
        ></custom-selection>
      </div>

      <div class="item-info">
        <mat-form-field appearance="outline" class="custom-selection-search">
          <img [class.rotate-180]="isActiveSelect" class="arrow-custom-select"
               src="assets/shared/components/select/img-select/select-arrow.svg">
          <div class="select-text">
            <mat-select  [formControl]="formInfoApplication.controls.ownExpense"
              (openedChange)="checkIfActive($event)">
              <div class="select-text">
                <mat-option [value]="true">Да</mat-option>
                <mat-option [value]="false">Нет</mat-option>
              </div>
            </mat-select>
          </div>
        </mat-form-field>
      </div>

      <div class="item-info">
        <mat-form-field appearance="outline" class="custom-selection-search">
          <img [class.rotate-180]="isActiveSelect" class="arrow-custom-select"
               src="assets/shared/components/select/img-select/select-arrow.svg">
          <div class="select-text">
            <mat-select [formControl]="formInfoApplication.controls.visaIsNotRequired"
                        (openedChange)="checkIfActive($event)">
              <div class="select-text">
                <mat-option [value]="true">Да</mat-option>
                <mat-option [value]="false">Нет</mat-option>
              </div>
            </mat-select>
          </div>
        </mat-form-field>
      </div>
      <div class="item-info">
        <custom-selection [multipleCheckBox]="false"
                          [control]="formInfoApplication.controls.visaIssuingCityCode"
                          [options]="dictionaries?.['cities']"
        ></custom-selection>
      </div>

      <div class="item-info block-with-checkbox ">
        <input (keypress)="$event.preventDefault()"
               class="input-main-search input-checkbox hide"
               [formControl]="formInfoApplication.controls.confirmationVisaInvitation"
               matInput>
        <mat-checkbox
          [formControl]="formInfoApplication.controls.confirmationVisaInvitation"
          class="custom-checkbox">
          <label class="checkbox-label"></label></mat-checkbox>
      </div>

      <div class="item-info">
        <custom-selection [multipleCheckBox]="false"
                          [control]="formInfoApplication.controls.regProgramCityCode"
                          [options]="dictionaries?.['cities']"
        ></custom-selection>
      </div>

      <div class="item-info">
        <custom-selection [multipleCheckBox]="false"
                          [control]="formInfoApplication.controls.regProgramStateCode"
                          [options]="dictionaries?.['moderationstates']"
        ></custom-selection>
      </div>

      <div *ngIf="formInfoApplication.value.travelTicketFile?.hash" class="photo">
        <div class="item-info tools">
<!--          <button [class.disabled-button]="!isEdit" (click)="fileInput.click()" class="button-classic"-->
<!--                  [disabled]="!isEdit">-->
<!--            <input type="file" style="display: none;" (change)="onFileChange($event,'ticket')" #fileInput>-->
<!--            <img src="assets/images/ul-kit-img/update.svg">-->
<!--            <span *ngIf="isEdit" class="tooltip-text">Изменить билет</span>-->
<!--          </button>-->
          <button class="button-classic"
                  (click)="getInfoTokenForDocument(formInfoApplication.value.travelTicketFileId!,formInfoApplication.value.travelTicketFile?.name!)"
          >

            <img src="assets/images/ul-kit-img/download.svg">
            <span *ngIf="isEdit" class="tooltip-text">Скачать билет</span>
          </button>
        </div>
      </div>

      <div *ngIf="!formInfoApplication.value.travelTicketFile?.hash" [class.no-photo]="!formInfoApplication.value.travelTicketFile?.hash" class="photo ">
        <div class="item-info tools">
          <button [class.disabled-button]="!isEdit" (click)="fileInput.click()" class="button-classic"
                  [disabled]="!isEdit">
            <input type="file" style="display: none;" (change)="onFileChange($event,'ticket')" #fileInput>
            <img src="assets/images/ul-kit-img/upload.svg">
            <span *ngIf="isEdit" class="tooltip-text">Загрузить билет</span>
          </button>
        </div>
      </div>


      <div *ngIf="formInfoApplication.value.visaInvitationFile?.hash"  class="photo">
        <div class="item-info tools">
          <!--          <button [class.disabled-button]="!isEdit" (click)="fileInput.click()" class="button-classic"-->
          <!--                  [disabled]="!isEdit">-->
          <!--            <input type="file" style="display: none;" (change)="onFileChange($event,'visaInvitation')" #fileInput>-->
          <!--            <img src="assets/images/ul-kit-img/update.svg">-->
          <!--            <span *ngIf="isEdit" class="tooltip-text">Изменить визовое приглашение</span>-->
          <!--          </button>-->
          <button class="button-classic"
                  (click)="getInfoTokenForDocument(formInfoApplication.value.visaInvitationId!,formInfoApplication.value.visaInvitationFile?.name!)"
          >
            <img src="assets/images/ul-kit-img/download.svg">
            <span  class="tooltip-text">Скачать визовое приглашение</span>
          </button>
        </div>
      </div>

      <div *ngIf="!formInfoApplication.value.visaInvitationFile?.hash" [class.no-photo]="!formInfoApplication.value.visaInvitationFile?.hash" class="photo ">
        <div class="item-info tools">
          <button [class.disabled-button]="!isEdit" (click)="fileInput.click()" class="button-classic"
                  [disabled]="!isEdit">
            <input type="file" style="display: none;" (change)="onFileChange($event,'visaInvitation')" #fileInput>
            <img src="assets/images/ul-kit-img/upload.svg">
            <span *ngIf="isEdit" class="tooltip-text">Загрузить визовое приглашение</span>
          </button>
        </div>
      </div>

      <!--      <div  class="photo">-->
      <!--        <div class="item-info tools">-->
      <!--          <button [class.disabled-button]="!isEdit" (click)="fileInput.click()" class="button-classic"-->
      <!--                  [disabled]="!isEdit">-->
      <!--            <input type="file" style="display: none;" (change)="onFileChange($event,'digitalAvatar')" #fileInput>-->
      <!--            <img src="assets/images/ul-kit-img/upload.svg">-->
      <!--            <span *ngIf="isEdit" class="tooltip-text">Загрузить билет</span>-->
      <!--          </button>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</div>
