import {Component, Input} from '@angular/core';
import {MatInput} from "@angular/material/input";
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {ProfileForm} from "../../../../core/interface/profile-card/profile-card-main.interface";


@Component({
  selector: 'app-social-information',
  standalone: true,
  imports: [
    MatInput,
    ReactiveFormsModule,
  ],
  templateUrl: './social-information.component.html',
  styleUrl: './social-information.component.scss'
})
export class SocialInformationComponent {
    @Input() formInfoProfile!: FormGroup<ProfileForm>
    @Input() dictionaries: { [key: string]: any; } | undefined
    isActive: boolean = true;

  getInfoSocial(socialNetworkTypeCode: string) : string {
    return this.dictionaries?.['socialnetworktypes']?.find((item: { code: string, name: string }) => item.code === socialNetworkTypeCode)?.name;
  }

}
