import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";

import {environment} from "../../../../../environments/environment";
import {HomeInterface, HomeStatInterface} from "../../../interface/home/home.interface";

@Injectable({
  providedIn: 'root'
})

export class HomeService{

  constructor(private http: HttpClient) {}


  getInfoUserStat(){
    return this.http.get<HomeStatInterface>(`${environment.integrationBaseUrl}/api/IntegrationCrm/GetUsersStat`)
  }
  getInfoCountriesByUsers(){
    return this.http.get<HomeInterface>(`${environment.integrationBaseUrl}/api/IntegrationCrm/GetTopCountriesByUsers`)
  }

}
