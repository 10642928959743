import {Component, Input, OnInit} from '@angular/core';
import {
  CustomSelectionComponent
} from "../../../../../assets/shared/components/select/custom-selection/custom-selection.component";
import {DatepickerComponent} from "../../../../../assets/shared/components/datepicker/datepicker/datepicker.component";
import {FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatFormField} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {NgForOf} from "@angular/common";
import {ProfileForm} from "../../../../core/interface/profile-card/profile-card-main.interface";
import {ProfileMainCardService} from "../../../profile/profile-card/profile-card.service";
import {combineLatest} from "rxjs";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {GeneralInformationApplicationComponent} from "../general-information/general-information.component";
import {ApplicationForm} from "../../../../core/interface/application/application.main.interface";
import {ApplicationCardMainService} from "../application-card.service";


@UntilDestroy()
@Component({
  selector: 'app-personal-application-information',
  imports: [
    CustomSelectionComponent,
    DatepickerComponent,
    FormsModule,
    MatCheckbox,
    MatFormField,
    MatInput,
    MatOption,
    MatSelect,
    NgForOf,
    ReactiveFormsModule
  ],
  standalone: true,
  templateUrl: './personal-information.component.html',
  styleUrl: './personal-information.component.scss'
})
export class PersonalInformationApplicationComponent implements OnInit {
  @Input() formInfoApplication!: FormGroup<ApplicationForm>
  @Input() dictionaries: { [key: string]: any; } | undefined
  isActive: boolean = true;

  isActiveSelect: boolean = false;
  contentItemLabel = [
    {name: 'ID единый', value: 'userUid'},
    {name: 'Обращение', value: 'titleCode'},
    {name: 'Пол', value: 'genders'},
    {name: 'Язык переписки', value: 'languages'},
    {name: 'Фамилия', value: 'lastName'},
    {name: 'Имя', value: 'firstName'},
    {name: 'Отчество', value: 'middleName'},
    {name: 'Family name', value: 'firstNameEng'},
    {name: 'First name', value: 'lastNameEng'},
    {name: 'Middle name', value: 'middleNameEng'},
    {name: 'Хочу стать участником МКД', value: 'wantsParticipantMcd'},
    {name: 'Участник МКД', value: 'isParticipantMcd'},
    {name: 'Дата рождения', value: 'dateBirth'},
    {name: 'Телефон', value: 'phoneNumber'},
  ]

  constructor(private applicationCardMainService: ApplicationCardMainService) {
  }

  ngOnInit() {
    this.setupNameField('lastName', 'noLastName');
    this.setupNameField('firstName', 'noFirstName');
    this.setupNameField('middleName', 'noMiddleName');
  }

  setupNameField(fieldName: string, noFieldName: string) {
    const field = this.formInfoApplication.get(fieldName);
    const noField = this.formInfoApplication.get(noFieldName);

    if (field && noField) {
      combineLatest([
        this.applicationCardMainService.isEdit$,
        noField.valueChanges
      ])
        .pipe(untilDestroyed(this))
        .subscribe(([edit, noValue]) => {
          if (!noValue && edit) {
            field.enable({ emitEvent: false });
          } else if (noValue) {
            field.setValue(null, { emitEvent: false });
            field.disable({ emitEvent: false });
          } else {
            // Чекбокс false, но редактирование запрещено
            field.disable({ emitEvent: false });
          }
        });
    }
  }

  checkIfActive(event: boolean) {
    this.isActiveSelect = event;
  }

}
