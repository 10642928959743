
<div class="container-main-table">
  <table class="main-table">
    <thead>
    <tr  [style.top]="inverseOfTranslationHeader" class="h-table">
      <th  [style.top]="inverseOfTranslationHeader" class="column-with-checkbox">
        <mat-checkbox  class="checkbox-lable"
                       [checked]="isMainCheckboxChecked"
                       (change)="triggerCheck($event)">
          {{selectedIds?.length}} из {{tableInfo.totalCount}}
        </mat-checkbox>
      </th>
      <ng-container *ngFor="let column of tableInfo?.tableHeaders; let i = index" >
        <th *ngIf="column.code !== 'Id'"  [style.top]="inverseOfTranslationHeader"
             [class.active-filter-table]="!!column.value">
          <div class="actions">
              <span>{{ column.name }}</span>
            <div  class="actions-bth">
              <ng-container class="open-filter" *ngIf="!['Id'].includes(column.name)">
                <svg (click)="onColumnFilterClick(column.code)"
                     [class.active-filter]="activeColumnFilterCode === column.code"
                     [attr.fill]="activeColumnFilterCode == column.type  ? '#FF6B00' : '#252525'"
                     xmlns="http://www.w3.org/2000/svg" width="10" height="7" fill="none" viewBox="0 0 8 5">
                  <path  d="m.67 1.175.59-.592 2.743 2.742L6.744.583l.592.592L4.003 4.51z"/></svg>
                <ng-container>
                  <app-table-filter-modal class="table-filter-modal" *ngIf="activeColumnFilterCode === column.code"
                                          [ngStyle]="i === tableInfo.tableHeaders!.length - 1 ? {'left': '-80px'} : {'left': '0px'}"
                                          [column] = "column"
                                          (closedFilter)="onClose()">
                  ></app-table-filter-modal>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="tableInfo.orderByColumn === column.code">
                <img style="width:10px; height:8px" *ngIf="!tableInfo.orderByDescending" (click)="onSelectedSortColumn(column)" src="assets/images/table-image/left-arrow-sort-active.svg">
                <img style="width:10px; height:8px" *ngIf="tableInfo.orderByDescending" (click)="onSelectedSortColumn(column)" src="assets/images/table-image/right-arrow-sort-active.svg">
              </ng-container>
              <ng-container>
                <img style="width:10px; height:8px" *ngIf="tableInfo.orderByColumn !== column.code" (click)="onSelectedSortColumn(column)" src="assets/images/table-image/sort_table.svg">
              </ng-container>
            </div>
          </div>
        </th>

      </ng-container>
    </tr>
    </thead>
    <tbody>

    <ng-container>
      <ng-content></ng-content>
    </ng-container>
    <!--    <ng-template #noData>-->
    <!--      <tr>-->
    <!--        <td class="no-data">-->
    <!--          Нет данных.-->
    <!--        </td>-->
    <!--      </tr>-->
    <!--    </ng-template>-->
    </tbody>
  </table>
</div>
