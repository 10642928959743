import {Component, Input} from '@angular/core';
import { MatButtonModule} from "@angular/material/button";
import {MatFormField, MatFormFieldModule, MatSuffix} from "@angular/material/form-field";
import {MatInput, MatInputModule} from "@angular/material/input";
import {
  MtxDatetimepicker,
  MtxDatetimepickerInput,
  MtxDatetimepickerModule,
  MtxDatetimepickerToggle,
} from "@ng-matero/extensions/datetimepicker";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {provideMomentDatetimeAdapter} from "@ng-matero/extensions-moment-adapter"
import {MatSliderModule} from "@angular/material/slider";
import  moment from 'moment';
import 'moment/locale/ru';
import { DatePipe } from "@angular/common";
import {ValidatorDateDirective} from "../../../../app/core/directive/validators-datepicker.directive";


moment.locale('ru');

@Component({
  selector: 'app-date-and-timepicker',
  standalone: true,
  imports: [
    MatFormField,
    MatInput,
    MatSuffix,
    MtxDatetimepicker,
    MtxDatetimepickerInput,
    MtxDatetimepickerToggle,
    ReactiveFormsModule,
    MatSliderModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MtxDatetimepickerModule,
    ValidatorDateDirective,
  ],
  templateUrl: './date-and-timepicker.component.html',
  styleUrl: './date-and-timepicker.component.scss',
  providers: [
    DatePipe,
    provideMomentDatetimeAdapter({
      parse: {
        dateInput: 'DD.MM.YYYY',
        monthInput: 'MMMM ',
        yearInput: 'YYYY',
        timeInput: 'HH:mm',
        datetimeInput: 'DD.MM.YYYY HH:mm',
      },
      display: {

        monthInput: 'MMMM ',
        yearInput: 'YYYY',
        timeInput: 'HH:mm',
        datetimeInput: 'DD.MM.YYYY в HH:mm',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
        popupHeaderDateLabel: 'MMMM YYYY',  // Настраиваем заголовок месяца и года
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'MMMM YYYY',  // Это ключевой параметр
      },
    }),
  ],
})
export class DateAndTimepickerComponent {
  @Input() control: FormControl<any | null> = new FormControl(null);

}
