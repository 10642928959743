<mat-form-field class="date-and-time-picker-custom">
  <mtx-datetimepicker #datetimePicker></mtx-datetimepicker>
  <input app-validator-date="[0-9]" [mtxDatetimepicker]="datetimePicker"
         [placeholder]="control.value === null ? 'Не заполнено' : ''"
         [formControl]="control"
         [value]="control.value"
         matInput>
  <mtx-datetimepicker-toggle [for]="datetimePicker" matSuffix></mtx-datetimepicker-toggle>
</mat-form-field>

