
<app-context-menu-profile-table
  [contextMenu]="contextMenu"
  [isVisible]="isContextMenuVisible"
  [position]="contextMenuPosition">
</app-context-menu-profile-table>


<cdk-virtual-scroll-viewport *ngIf="tableInfo.tableHeaders.length > 0"
                             [itemSize]="40" style="height: 100%;">
  <app-table [tableInfo]="tableInfo"
             [selectedIds]="selectedIds"
             [inverseOfTranslationHeader]="inverseOfTranslationHeader"
             [isMainCheckboxChecked]="isMainCheckboxChecked()"
             (triggerCheckAll)="checkAll($event)"
             (trigger)="getTable()">
    <ng-container *cdkVirtualFor="let columnItem of itemColumnTable | paginate: {
    itemsPerPage: tableInfo.take,
    currentPage: currentPage,
    totalItems: tableInfo.totalCount }; let i = index">
      <tr class="b-table"
          [ngClass]="{'checked-background': selectedIds?.includes(columnItem.UserUid)}"
          (contextmenu)="openContextMenu($event, columnItem)">

        <td (click)="$event.stopPropagation()">
          <a class="b-table__link first-b-table__link">
            <mat-checkbox [checked]="checkIfModerationChecked(columnItem.Id)"
                          (change)="onModerationChange($event,columnItem.Id)"></mat-checkbox>
          </a>
        </td>

        <ng-container *ngFor="let column of tableInfo?.tableHeaders">
          <td *ngIf="column.code !== 'Id'">
            <!-- Для типа 'dictionary' -->
            <ng-container *ngIf="column.type == 'dictionary'">
              <a class="b-table__link"
                 #linkElement
                 [ngClass]="{'popup-enabled': isTextOverflow(linkElement),'no-data': !columnItem[column.code]}"
                 [attr.data-full-text]="getDictionaryNameByCode(columnItem[column.code], column.dictionaryName)"
                 [innerHTML]="highlightText(getDictionaryNameByCode(columnItem[column.code], column.dictionaryName),tableInfo.quickSearch?.value)"></a>
            </ng-container>

            <!-- Для типа 'dictionaryList' -->
            <ng-container *ngIf="column.type == 'dictionaryList'">
              <a class="b-table__link"
                 #linkElement
                 [attr.data-full-text]="getDictionaryListNamesByCode(columnItem[column.code], column.dictionaryName)"
                 [ngClass]="{'popup-enabled': isTextOverflow(linkElement),'no-data': !columnItem[column.code]}"
                 [innerHTML]="highlightText(getDictionaryListNamesByCode(columnItem[column.code], column.dictionaryName),tableInfo.quickSearch?.value)"></a>
            </ng-container>

            <!-- Для типа 'string' -->
            <ng-container *ngIf="column.type == 'string'">
              <a class="b-table__link"
                 #linkElement
                 [attr.data-full-text]="columnItem[column.code]"
                 [ngClass]="{'popup-enabled': isTextOverflow(linkElement),'no-data': !columnItem[column.code]}"
                 [innerHTML]="highlightText(columnItem[column.code] || 'Нет данных',tableInfo.quickSearch?.value)"></a>
            </ng-container>

            <div
              class="b-table__clickable-area"
              (click)="routerInCard(columnItem.UserUid)">
              <!-- Для типа 'number' -->
              <ng-container *ngIf="column.type == 'number'">
                <a class="b-table__link number-link"  [ngClass]="{'no-data': !columnItem[column.code]}"

                   [innerHTML]="highlightText(columnItem[column.code]?.toString() || 'Нет данных',tableInfo.quickSearch?.value)"></a>
              </ng-container>
            </div>
            <!-- Для типа 'bool' -->
            <ng-container *ngIf="column.type == 'bool'">
              <a class="b-table__link"   [innerHTML]="highlightText(columnItem[column.code] ? 'Да' : 'Нет',tableInfo.quickSearch?.value)">
              </a>
            </ng-container>

            <!-- Для типа 'datetime' -->
            <ng-container *ngIf="column.type == 'datetime'">
              <a class="b-table__link"  [ngClass]="{'no-data': !columnItem[column.code]}"
                 [innerHTML]="highlightText(columnItem[column.code] ? (columnItem[column.code] | date: 'dd.MM.yyyy') : 'Нет данных',tableInfo.quickSearch?.value)">
              </a>

            </ng-container>
          </td>
        </ng-container>
      </tr>
    </ng-container>
  </app-table>
</cdk-virtual-scroll-viewport>



