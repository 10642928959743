import {Directive, HostListener, Input, Output, EventEmitter, ElementRef} from '@angular/core';

@Directive({
  selector: '[app-validator-date]',
  standalone: true
})

export class ValidatorDateDirective {

  private dateValue = '';
  @Output() dateChange: EventEmitter< | null | Date> = new EventEmitter<  null | Date>();

  @Input('date-value') modelValue: any;

  @Input() isStartDate: boolean = false;

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;

    // Если длина строки уже 10 символов и нажат не Backspace — блокируем ввод
    if (input.value.length >= 10 && event.key !== 'Backspace') {
      event.preventDefault(); // Блокируем вставку или ввод
      return;
    }

    // Обработка нажатия Backspace
    if (event.key === 'Backspace') {
      const caretStart = input.selectionStart ?? 0;
      const caretEnd = input.selectionEnd ?? 0;

      // Если точка перед курсором и символ перед точкой — цифра, то удаляем точку
      if (input.value[caretStart - 1] === '.' && !isNaN(parseInt(input.value[caretStart - 2]))) {
        const valueBeforeCaret = input.value.slice(0, caretStart - 1);
        const valueAfterCaret = input.value.slice(caretEnd);
        input.value = valueBeforeCaret + valueAfterCaret;
        event.preventDefault(); // Прерываем удаление и обновляем позицию курсора
        input.setSelectionRange(caretStart - 1, caretStart - 1);
      }
    }
  }

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const input = this.el.nativeElement;
    let value = input.value.replace(/[^\d]/g, ''); // Оставляем только цифры

    // Ограничиваем длину ввода до 10 символов
    if (value.length >= 10) {
      event.preventDefault()
      return;
    }

    // Форматируем в DD.MM.YYYY
    if (value.length >= 3) {
      value = value.slice(0, 2) + '.' + value.slice(2);
    }
    if (value.length >= 6) {
      value = value.slice(0, 5) + '.' + value.slice(5);
    }

    // Проверка для дня (не больше 31)
    const day = parseInt(value.slice(0, 2), 10);
    if (day > 31) {
      value = '31' + value.slice(2); // Ограничиваем до 31
    }
    if (day < 1 && value.length === 2) {
      value = '01' + value.slice(2); // Ограничиваем до 31
    }

    const month = parseInt(value.slice(3, 5), 10);
    if (month > 12) {
      value = value.slice(0, 3) + '12';
    }
    if (month < 1 && value.length === 5) {
      value = value.slice(0, 3) + '01' + value.slice(5);
    }

    input.value = value;
    this.dateValue = value;
  }

  ngAfterViewInit(): void {
    // Проверка, что поле изначально пустое
    if (!this.modelValue && !this.isStartDate) {
      const currentDate = new Date();
      currentDate.setHours(23, 59, 59);
      this.el.nativeElement.value = currentDate;

      const event = new Event('input', { bubbles: true });
      this.el.nativeElement.dispatchEvent(event);
    }
  }

}
