import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {IoctmoListInterface} from "../../interface/guide/guide.interface";
import {BehaviorSubject, catchError, Observable, of, Subject} from "rxjs";
import {tap} from "rxjs/operators";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {HeadersTableInterface} from "../../interface/table/headers-table.interface";

@UntilDestroy()
@Injectable({
  providedIn: 'root'
}
)
export class DictionaryService {
  private cache: Map<string, IoctmoListInterface[]> = new Map();
  private requestsInProgress: Map<string, Subject<IoctmoListInterface[]>> = new Map(); // Мапа для отслеживания выполняющихся запросов

  dictionaries = new BehaviorSubject<{ [key: string]: any }>({});
  dictionaries$ = this.dictionaries.asObservable();

  constructor(private http: HttpClient) { }

  getAllOktmoList(language: string, dictionaryName: string): Observable<IoctmoListInterface[]> {
    const cacheKey = `${dictionaryName}-${language}`;

    if (this.cache.has(cacheKey)) {
      return of(this.cache.get(cacheKey) || []);
    }

    if (this.requestsInProgress.has(cacheKey)) {
      return this.requestsInProgress.get(cacheKey)!.asObservable();
    }
    this.requestsInProgress.set(cacheKey, new Subject<IoctmoListInterface[]>());

    this.http.get<IoctmoListInterface[]>
    (`${environment.baseUrl}/api/v1-dictionary/DictionaryEntry/GetReference?language=${language}&dictionary=${dictionaryName}`)
      .pipe(
        tap(data => {
          this.cache.set(cacheKey, data);
          this.dictionaries.value[dictionaryName] = data;
          new Subject<IoctmoListInterface[]>().next(data);
          new Subject<IoctmoListInterface[]>().complete();
        }),
        catchError(error => {
          console.error(`Ошибка при загрузке справочника ${dictionaryName}:`, error);
          new Subject<IoctmoListInterface[]>().next([]);
          new Subject<IoctmoListInterface[]>().complete();
          return of([]);
        }),
        untilDestroyed(this)
      )
      .subscribe();

    return new Subject<IoctmoListInterface[]>().asObservable();
  }

  loadDictionaries(dictionaries: string[] | HeadersTableInterface[]): void {
    const codes = Array.isArray(dictionaries) && typeof dictionaries[0] === 'string'
      ? dictionaries as string[]
      : (dictionaries as HeadersTableInterface[])
        .map(item => item.dictionaryName)
        .filter(name => name !== null && name !== undefined);

    codes.forEach(code => {
      this.getAllOktmoList('RU', code).pipe(untilDestroyed(this)).subscribe(data => {
        data.sort((a, b) => a.name.localeCompare(b.name));
        this.dictionaries.value[code] = data;
      });
    });
  }
}
