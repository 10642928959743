import { Component, OnInit} from '@angular/core';
import {NgForOf} from "@angular/common";
import {RouterLink} from "@angular/router";
import {BarChartModule, ChartCommonModule, PieChartModule} from "@swimlane/ngx-charts";
import {AuthService} from "../../core/services/auth/auth.service";
import {
  DropdownProfileComponent
} from "../../../assets/shared/components/dropdown/dropdown-profile/dropdown-profile.component";
import {HomeService} from "../../core/services/pages/home/home.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {HomeStatInterface} from "../../core/interface/home/home.interface";
import {DictionaryService} from "../../core/services/pages/dictionary.service";
import {ChartsClass} from "../../core/class/home.class";
import {
  ChartsBarVerticalComponent
} from "../../../assets/shared/components/charts/charts-bar-vertical/charts-bar-vertical.component";
import {UserInfoClass} from "../../core/class/user-info.class";


@UntilDestroy()
@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    NgForOf,
    RouterLink,
    BarChartModule,
    ChartCommonModule,
    PieChartModule,
    DropdownProfileComponent,
    ChartsBarVerticalComponent,
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],

})
export class HomeComponent implements OnInit {
  stat: HomeStatInterface | undefined;
  countriesCharts: ChartsClass[] | undefined;

  homeNavBarNotLong = [
    // временно
    {
      icon: 'assets/images/ul-kit-img/seating.svg',
      label: 'Портал',
      description: '',
      url: 'https://wyffest.com/cm',
    },
    {
      icon: 'assets/images/ul-kit-img/mailing.svg',
      label: 'Рассылка(временно)',
      description: 'Шаблоны писем, отчеты\nпо рассылкам.',
      url: 'https://notifications.wyffest.com/mailings',
    },
    {
      icon: 'assets/images/ul-kit-img/analytics.svg',
      label: 'НСИ',
      description: 'Хранение и учет справочников.',
      url: 'https://nsi.wyffest.com/cm',
    },
    {
      icon: 'assets/images/ul-kit-img/analytics.svg',
      label: 'Мероприятия',
      description: 'Создание и модерация информации о мероприятиях.',
      url: 'https://event.wyffest.com/cm',
    },
    {
      icon: 'assets/images/ul-kit-img/seating.svg',
      label: 'Фотобанк',
      description: 'Работа с фоторгафиями в разделе\nФотобанк на портале',
      url: 'https://photo.wyffest.com/cm',
    },
    {
      icon: 'assets/images/ul-kit-img/seating.svg',
      label: 'Новости',
      description: 'Создание, модерация и публикация новостей',
      url: 'https://article.wyffest.com/cm',
    },
  ]
  homeNavBar = [
    {
      icon: 'assets/images/ul-kit-img/profile.svg',
      label: 'Пользователи',
      description: 'Реестр пользователей, работа\nс данными.',
      url: '/profile',
    },
    {
      icon: 'assets/images/ul-kit-img/mailing.svg',
      label: 'Рассылка',
      description: 'Шаблоны писем, отчеты\nпо рассылкам.',
      url: '/mailing',
    },
    {
      icon: 'assets/images/ul-kit-img/applications.svg',
      label: 'Заявки участников',
      description: 'Модерация, отбор участников.',
      url: '/application',
    },
    {
      icon: 'assets/images/ul-kit-img/instructions.svg',
      label: 'Инструкции',
      description: 'Инструкции, документы\nпо работе в системе.',
      url: '/instructions',
    },
    {
      icon: 'assets/images/ul-kit-img/analytics.svg',
      label: 'Аналитика',
      description: 'Дашборды, графики,\nотчеты ключевых показателей.',
      url: '/analytics',
    },
    {
      icon: 'assets/images/ul-kit-img/services.svg',
      label: 'Сервисы',
      description: 'Проживание и питание.',
      url: '/home',
    },
    {
      icon: 'assets/images/ul-kit-img/seating.svg',
      label: 'Рассадка',
      description: 'Распределение участников\nпо местам.',
      url: '/home',
    },
    {
      icon: 'assets/images/ul-kit-img/delete-application.svg',
      label: 'Заявки на удаление',
      description: 'Удаление персональных данных\n(ЛК).',
      url: '/home',
    },
  ]
  isDropdownOpen = false;
  dictionaries: { [key: string]: any; } | undefined
  userInfo: UserInfoClass | null = this.authService.getUserInfo();


  constructor(private dictionaryService: DictionaryService,
              private homeService: HomeService,
              private authService:AuthService ) {
    this.loadDictionaries(['countries'])
  }

  ngOnInit() {
    this.getInfoDictionaries();
    this.getInfoCountriesByUsers();
    this.getInfoStatUser();
  }


  getInfoDictionaries() {
    this.dictionaryService.dictionaries$.pipe(untilDestroyed(this)).subscribe(x => {
      this.dictionaries = x
    })
  }



  getInfoCountriesByUsers() {
    this.homeService.getInfoCountriesByUsers()
      .pipe(untilDestroyed(this))
      .subscribe(infoCountries => {
        this.countriesCharts = infoCountries.countries.map(c => ({
          name: c.code.toUpperCase(),
          value: parseFloat(c.percentage.toFixed(2)),
          membersValue: c.totalUsers
        }));
      });
  }
  getInfoStatUser() {
    this.homeService.getInfoUserStat().pipe(untilDestroyed(this)).subscribe(stat => {
      this.stat = stat
    })
  }

  loadDictionaries(dictionariesList: string[]) {
    return this.dictionaryService.loadDictionaries(dictionariesList)
  }


}
