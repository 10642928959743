import {UntilDestroy} from "@ngneat/until-destroy";
import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../../environments/environment";

@UntilDestroy()
@Injectable({
        providedIn: 'root'
    }
)

export class FileStorageService{


    constructor(private http: HttpClient) {}

    getTokenForDocument(documentId: number){
        const headers = new HttpHeaders().set('accept', 'application/json');
       return this.http.get<string>(`https://dev-profile.wyffest.com/api/IntegrationCrm/GetTokenPersonalFile?documentId=${documentId}`, {headers} )
    }

}
