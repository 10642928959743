import { Component } from '@angular/core';
import {AuthService} from "../../../../../app/core/services/auth/auth.service";
import {UserInfoClass} from "../../../../../app/core/class/user-info.class";

@Component({
  selector: 'app-dropdown-profile',
  standalone: true,
    imports: [

    ],
  templateUrl: './dropdown-profile.component.html',
  styleUrl: './dropdown-profile.component.scss'
})
export class DropdownProfileComponent {
  userInfo: UserInfoClass | null = this.authService.getUserInfo();

constructor(private authService:AuthService ) {
}


  async logout(){
    await this.authService.logout()
  }
}
