<div [class.margin-bottom-unset]="!isActive"
     [formGroup]="formInfoProfile"
     class="form">
  <div (click)="isActive = !isActive" class="label-form">
    <img [class.rotate-180]="isActive" src="assets/images/ul-kit-img/arrow-black.svg">
    <span>Общая информация</span>
  </div>
  <div [class.active]="isActive" class="form-content">
    <div class="left-content">
      <div *ngFor="let item of contentItemLabel; let i = index; let last = last"
           class="item-content-label"
           [class.no-photo]="(last && !formInfoProfile.value.digitalAvatar?.hash)
           || (i === contentItemLabel.length - 2 && !formInfoProfile.value.photo?.hash)"
           >
        <img src="assets/images/ul-kit-img/green-point.svg">
        <span>{{ item.name }}</span>
      </div>
    </div>
    <div class="right-content">


      <div class="item-info">
        <input formControlName="userName" class="text-inter-semibold input-main-search"
               [placeholder]="formInfoProfile.get('userName')?.value === null ? 'Не заполнено' : ''"
               matInput>
      </div>


      <div class="item-info">
        <custom-selection [multipleCheckBox]="false"
                          [control]="formInfoProfile.controls.statusCode"
                          [options]="dictionaries?.['statuspersonalaccount']"
        ></custom-selection>
      </div>
      <div class="item-info">
        <app-date-and-timepicker [control]="formInfoProfile.controls.userCreationDate">
        </app-date-and-timepicker>
      </div>

      <div class="item-info">
        <input formControlName="reasonBlocking" class="text-inter-semibold input-main-search"
               [placeholder]="formInfoProfile.get('reasonBlocking')?.value === null ? 'Не заполнено' : ''"
               matInput>
      </div>

      <div class="item-info">
        <mat-form-field appearance="outline" class="custom-selection-search">
          <img [class.rotate-180]="isActiveSelect" class="arrow-custom-select"
               src="assets/shared/components/select/img-select/select-arrow.svg">
          <div class="select-text">
            <mat-select [formControl]="formInfoProfile.controls.mailingIsAccept"
                        (openedChange)="checkIfActive($event)">
              <div class="select-text">
              <mat-option [value]="true">Да</mat-option>
              <mat-option [value]="false">Нет</mat-option>
              </div>
            </mat-select>
          </div>
        </mat-form-field>
      </div>


      <div class="item-info block-with-checkbox ">
        <input (keypress)="$event.preventDefault()"
               class="input-main-search input-checkbox hide"
               [formControl]="formInfoProfile.controls.isTest"
               matInput>
        <mat-checkbox
          [formControl]="formInfoProfile.controls.isTest"
          class="custom-checkbox">
          <label class="checkbox-label"></label></mat-checkbox>
      </div>


      <div *ngIf="formInfoProfile.value.photo?.hash" class="photo ">
        <div class="item-info tools">
          <button [class.disabled-button]="!isEdit" (click)="fileInput.click()" class="button-classic"
                  [disabled]="!isEdit">
            <input type="file" style="display: none;" (change)="onFileChange($event,'photo')" #fileInput>
            <img src="assets/images/ul-kit-img/update.svg">
            <span *ngIf="isEdit" class="tooltip-text">Изменить фотографию профиля</span>
          </button>
          <button class="button-classic"
                  (click)="downloadFile(formInfoProfile.value.photo?.hash,formInfoProfile.value.photo?.name || '')"
                  [disabled]="!isEdit"
                  [class.disabled-button]="!isEdit"
          >


            <img src="assets/images/ul-kit-img/download.svg">
            <span *ngIf="isEdit" class="tooltip-text">Скачать фотографию профиля</span>
          </button>
          <button class="button-classic"
                  [class.disabled-button]="!isEdit"
                  [disabled]="!isEdit"
                  (click)="openModalShowImage(formInfoProfile.value.photo?.name,formInfoProfile.value.photo?.hash )">

            <img src="assets/images/ul-kit-img/look.svg">
            <span *ngIf="isEdit" class="tooltip-text">Посмотреть фотографию профиля</span>
          </button>
        </div>
        <img class="photo-img" src="{{environment.photoUrl + formInfoProfile.value.photo?.hash }}"/>
      </div>

      <div *ngIf="!formInfoProfile.value.photo?.hash"  [class.no-photo]="!formInfoProfile.value.photo?.hash" class="photo">
        <div class="item-info tools">
          <button [class.disabled-button]="!isEdit" (click)="fileInput.click()" class="button-classic"
                  [disabled]="!isEdit">
            <input type="file" style="display: none;" (change)="onFileChange($event,'photo')" #fileInput>
            <img src="assets/images/ul-kit-img/upload.svg">
            <span *ngIf="isEdit" class="tooltip-text">Загрузить фотографию профиля</span>
          </button>
        </div>
      </div>


      <div *ngIf="formInfoProfile.value.digitalAvatar?.hash" class="photo">
        <div class="item-info tools">
          <button [class.disabled-button]="!isEdit" (click)="fileInput.click()" class="button-classic"
                  [disabled]="!isEdit">
            <input type="file" style="display: none;" (change)="onFileChange($event,'digitalAvatar')" #fileInput>
            <img src="assets/images/ul-kit-img/update.svg">
            <span *ngIf="isEdit" class="tooltip-text">Изменить цифровой аватар</span>
          </button>
          <button class="button-classic"
                  [class.disabled-button]="!isEdit"
                  [disabled]="!isEdit"
                  (click)="downloadFile(formInfoProfile.value.digitalAvatar?.hash,formInfoProfile.value.digitalAvatar?.name || '')"
          >

            <img src="assets/images/ul-kit-img/download.svg">
            <span *ngIf="isEdit" class="tooltip-text">Скачать цифровой аватар</span>
          </button>
          <button class="button-classic"
                  [class.disabled-button]="!isEdit"
                  [disabled]="!isEdit"
                  (click)="openModalShowImage(formInfoProfile.value.digitalAvatar?.name ,formInfoProfile.value.digitalAvatar?.hash)">

            <img src="assets/images/ul-kit-img/look.svg">

            <span *ngIf="isEdit" class="tooltip-text">Посмотреть цифровой аватар</span>
          </button>
        </div>
        <img class="photo-img" src="{{environment.photoUrl + formInfoProfile.value.digitalAvatar?.hash}}"/>
      </div>


      <div *ngIf="!formInfoProfile.value.digitalAvatar?.hash" [class.no-photo]="!formInfoProfile.value.digitalAvatar?.hash" class="photo ">
        <div class="item-info tools">
          <button [class.disabled-button]="!isEdit" (click)="fileInput.click()" class="button-classic"
                  [disabled]="!isEdit">
            <input type="file" style="display: none;" (change)="onFileChange($event,'digitalAvatar')" #fileInput>
            <img src="assets/images/ul-kit-img/upload.svg">
            <span *ngIf="isEdit" class="tooltip-text">Загрузить цифровой аватар</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
