import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {ProfileTableModel} from "../../../models/profile-table.model";
import {ColumnTableInterface} from "../../../interface/table/column-table.interface";
import {HeadersTableInterface} from "../../../interface/table/headers-table.interface";



@Injectable({
  providedIn: 'root'
})

export class ProfileTableService{


  constructor(private http: HttpClient) {}

  getInfoHeaders(){
    return this.http.get<HeadersTableInterface[]>(`${environment.baseUrl}/api/v1-web/UserProfile/GetHeaders`)
  }
  getColumnInfoProfile(request: ProfileTableModel){
    return this.http.post<ColumnTableInterface>(`${environment.baseUrl}/api/v1-web/UserProfile/Get`,request)
  }
}
