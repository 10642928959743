import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {HeadersTableInterface} from "../../../interface/table/headers-table.interface";
import {environment} from "../../../../../environments/environment";
import {ProfileTableModel} from "../../../models/profile-table.model";
import {ColumnTableInterface} from "../../../interface/table/column-table.interface";

@Injectable({
  providedIn: 'root'
})
export class ApplicationTableService {

  constructor(private http: HttpClient) {}

  getInfoHeaders(){
    return this.http.get<HeadersTableInterface[]>(`${environment.baseUrl}/api/v1-web/Application/GetHeaders`)
  }
  getColumnInfoProfile(request: ProfileTableModel){
    return this.http.post<ColumnTableInterface>(`${environment.baseUrl}/api/v1-web/Application/Get`,request)
  }
}
