import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-not-found',
  standalone: true,
    imports: [
        RouterLink
    ],
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.scss'
})
export class NotFoundComponent implements OnInit{
   code: string = '404';
   message: string = 'Ресурс не найден';

  constructor(private router: Router) {
  }

  ngOnInit() {
    if (this.router.url.includes('notfound')) {
      this.code = '404';
      this.message = 'Ресурс не найден';
    } else if (this.router.url.includes('error')) {
      this.code = '403';
      this.message = 'Нет доступа';
    }
  }
}
