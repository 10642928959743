export const environment = {
  production: false,
  baseUrl: 'https://dev-crm.wyffest.com',
  integrationBaseUrl: 'https://dev-profile.wyffest.com',
  requestBaseUrl: 'https://dev-request.wyffest.com',
// работа с файлами
  filePersonalUrl: 'https://dev-storage.wyffest.com/personal/',
  photoUrl:         'https://dev-storage.wyffest.com/image/wyffest-dev-identity/images/',
  saveFile: 'https://dev-storage.wyffest.com/file/wyffest-dev-identity/images/',
  auth: {
    issuer: 'https://dev-sso.wyffest.com',
    selfUrl: 'https://dev-crm.wyffest.com',
    clientId: 'vfm.crm',
    scope: 'openid profile roles offline_access'
  }
};
