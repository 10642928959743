import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {AfterViewInit, Component, HostListener, OnInit, ViewChild} from "@angular/core";
import {DatePipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {MatCheckbox} from "@angular/material/checkbox";
import {NgxPaginationModule} from "ngx-pagination";
import {TableComponent} from "../../../../assets/shared/components/table/table.component";
import {FormsModule} from "@angular/forms";

import {TableInfo} from "../../../core/interface/table/table-info";
import {Router} from "@angular/router";
import {ApplicationMainService} from "../application-main-service";
import {DictionaryService} from "../../../core/services/pages/dictionary.service";
import {
  ContextMenuProfileTableComponent
} from "../../../../assets/shared/components/context-menu/context-menu-profile-table/context-menu-profile-table.component";
import {CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport} from "@angular/cdk/scrolling";
import {ContextMenu} from "../../../core/interface/context-menu/context-menu.interface";
import {NotificationService} from "../../../core/services/notification/notification.service";



@UntilDestroy()
@Component({
  selector: 'app-application-table',
  standalone: true,
  imports: [
    DatePipe,
    MatCheckbox,
    NgForOf,
    NgIf,
    NgxPaginationModule,
    TableComponent,
    NgClass,
    FormsModule,
    ContextMenuProfileTableComponent,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
  ],
  templateUrl: './application-table.component.html',
  styleUrl: './application-table.component.scss'
})
export class ApplicationTableComponent implements OnInit,AfterViewInit {
  isContextMenuVisible: boolean = false;
  contextMenuPosition = {x: '0px', y: '0px'};
  currentRow: any;
  tableInfo: TableInfo = this.applicationMainService.tableInfo;
  dictionaries: { [key: string]: any; } | undefined
  itemColumnTable: any[] = [];
  selectedIds: number[] | undefined
  contextMenu: Array<any> = [];

  @ViewChild(CdkVirtualScrollViewport, {static: false})
  viewPort!: CdkVirtualScrollViewport;


  constructor(private router: Router,
              private applicationMainService: ApplicationMainService,
              private notify: NotificationService,
              private dictionaryService: DictionaryService) {
  }

  ngOnInit() {
    this.getInfoTableColumnProfile()
    this.getInfoCheckboxLangTotal()
    this.getInfoDictionaries()
    this.inverseOfTranslationHeader
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.viewPort) {
        this.viewPort.checkViewportSize();
      }
    });
  }

  public get inverseOfTranslationHeader(): string {
    if (!this.viewPort || !this.viewPort["_renderedContentOffset"]) {
      return "-0px";
    }
    let offset = this.viewPort["_renderedContentOffset"];
    return `-${offset}px`;
  }

  checkAll(event: boolean) {
    this.applicationMainService.checkAll(event)
  }

  isMainCheckboxChecked(): boolean {
    return this.applicationMainService.isMainCheckboxChecked();
  }

  getInfoCheckboxLangTotal() {
    this.applicationMainService.selectedIds$.pipe(untilDestroyed(this)).subscribe(total => {
      this.selectedIds = total
    })
  }

  getInfoDictionaries() {
    this.dictionaryService.dictionaries$.pipe(untilDestroyed(this)).subscribe(dictionaries => {
      this.dictionaries = dictionaries
    })
  }

  getInfoTableColumnProfile() {
    this.applicationMainService.itemColumnTable$.pipe(untilDestroyed(this)).subscribe(y => {
      this.itemColumnTable = y
    })
  }

  routerInCard(codeEvent: string,userUid: number) {
     this.router.navigate(['application/application-card/' + codeEvent + '/' + userUid])
  }

  highlightText(text: string | null, search: string | undefined): string {
    if (!search || !text) {
      return text || '';
    }
    return text.replace(
      new RegExp(`(${search})`, 'gi'),
      '<mark>$1</mark>');
  }

  getDictionaryListNamesByCode(code: string, dictionaryType: string): string {
    return this.applicationMainService.getDictionaryListNamesByCode(code, dictionaryType) ? this.applicationMainService.getDictionaryListNamesByCode(code, dictionaryType) : 'Нет данных'
  }

  getDictionaryNameByCode(code: string, dictionaryType: string): string {
    return this.applicationMainService.getDictionaryNameByCode(code, dictionaryType) ? this.applicationMainService.getDictionaryNameByCode(code, dictionaryType) : 'Нет данных'
  }

  get currentPage(): number {
    return this.applicationMainService.currentPage;
  }


  onModerationChange(event: { checked: boolean }, Id: number | undefined): void {
    this.applicationMainService.onModerationChange(event, Id)
    this.isContextMenuVisible = false;
  }

  checkIfModerationChecked(id: number | undefined): boolean {
    return this.applicationMainService.isModerationChecked(id!);
  }

  getTable() {
    this.applicationMainService.updateTableProfile();
  }

  openContextMenu(event: MouseEvent, columnItem: any) {
    event.preventDefault();
    this.isContextMenuVisible = true;
    this.contextMenuPosition = { x: `${event.clientX}px`, y: `${event.clientY}px` };
    this.currentRow = columnItem;
    this.contextMenu = [
      {
        label: this.selectedIds?.includes(this.currentRow.Id) ? 'Отменить' : 'Выбрать',
        action: () => this.onModerationChange(
          { checked: !this.selectedIds?.includes(this.currentRow.Id) },
          this.currentRow.Id),
      },
      {
        label: 'Статус заявки',
        subMenu: [
          ...(this.dictionaries?.['moderationstates']?.filter((state: any) => state.code !== this.currentRow.ModerationStateCode)
            ?.map((state: any) => ({
            label: state.name,
             action: () => this.changeApplicationsState(this.currentRow.UserUid,this.currentRow.EventCode,state.code)
          })) || [])
        ]
      },
      {
        label: 'Статус ДВФМ',
        subMenu: [
          ...(this.dictionaries?.['statusdvfm']?.filter((state: any) => state.code !== this.currentRow.ModerationStateCode)
            ?.map((state: any) => ({
              label: state.name,
              action: () => this.changeApplicationStatusDvfm(this.currentRow.UserUid,this.currentRow.EventCode,state.code)
            })) || [])
        ]
      },
      {
        label: 'Сгенер. виз-ое пригл.',
        action: () => this.addVisaInvitationGenerate(this.currentRow.UserUid,this.currentRow.EventCode)
      },
      {
        label: 'Перейти',
         action: () => this.routerInCard(this.currentRow.EventCode,this.currentRow.UserUid)
      }
    ];
  }


  addVisaInvitationGenerate(userUid: number | undefined | null,codeEvent: string | undefined | null){
    this.applicationMainService.addVisaInvitationGenerate(userUid,codeEvent).pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.notify.success('Визовое приглашение успешно сгенерировалось');
          this.getInfoTableColumnProfile();
        },
        error: (err) => {
          this.notify.error('Ошибка при генерации визового приглашения');
        }
      });
  }

  changeApplicationStatusDvfm(userUid: number,codeEvent: string,moderationStateCode: string) {
    this.applicationMainService.changeApplicationStatusDvfm(userUid,codeEvent,moderationStateCode)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.notify.success('Статус успешно изменён');
          this.getInfoTableColumnProfile();
        },
        error: (err) => {
          this.notify.error('Ошибка при смене статуса');
        }
      });
  }

  changeApplicationsState(userUid: number,codeEvent: string,moderationStateCode: string) {
    this.applicationMainService.changeApplicationsState(userUid,codeEvent,moderationStateCode)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.notify.success('Статус успешно изменён');
          this.getInfoTableColumnProfile();
        },
        error: (err) => {
          this.notify.error('Ошибка при смене статуса');
        }
      });
  }


  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  @HostListener('document:keydown', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (!document.querySelector('.context-menu')?.contains(event.target as Node)) {
      this.isContextMenuVisible = false;
    }
  }

  isTextOverflow(el: HTMLElement): boolean {
    if (!el) return false;
    return el.scrollHeight > el.clientHeight + 10 || el.scrollWidth > el.clientWidth + 10;
  }
}
