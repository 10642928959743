<div [class.margin-bottom-unset]="!isActive" [formGroup]="formInfoProfile" class="form">
    <div (click)="isActive = !isActive" class="label-form">
        <img [class.rotate-180]="isActive" src="assets/images/ul-kit-img/arrow-black.svg">
        <span>Персональные данные</span>
    </div>
    <div [class.active]="isActive" class="form-content">
        <div class="left-content">
            <div *ngFor="let item of contentItemLabel" class="item-content-label">
                <img src="assets/images/ul-kit-img/green-point.svg">
                <span class="text-inter-regular">{{item.name}}</span>
            </div>
        </div>
        <div class="right-content">
            <div class="item-info">
                <input formControlName="userUid" class="text-inter-semibold input-main-search"
                       matInput>
            </div>


          <div class="item-info">
            <custom-selection [multipleCheckBox]="false"
                              [control]="formInfoProfile.controls.titleCode"
                              [options]="dictionaries?.['titles']"
            ></custom-selection>
          </div>

            <div class="item-info">
                <custom-selection [multipleCheckBox]="false"
                                  [control]="formInfoProfile.controls.genderCode"
                                  [options]="dictionaries?.['genders']"
                ></custom-selection>
            </div>

            <div class="item-info">
                <custom-selection [multipleCheckBox]="false"
                                  [control]="formInfoProfile.controls.corrLanguageCode"
                                  [options]="dictionaries?.['languages']"
                ></custom-selection>
            </div>

            <div class="item-info block-with-checkbox">
                <input
                  [placeholder]="formInfoProfile.get('lastName')?.value === null ? 'Не заполнено' : ''"
                        class="input-main-search text-inter-semibold"
                        formControlName="lastName"
                        matInput>
                <mat-checkbox formControlName="noLastName"
                              class="custom-checkbox">
                    <label class="checkbox-label">Нет фамилии</label>
                </mat-checkbox>

            </div>
            <div class="item-info block-with-checkbox">
                <input
                  [placeholder]="formInfoProfile.get('firstName')?.value === null ? 'Не заполнено' : ''"
                        class="input-main-search text-inter-semibold"
                        formControlName="firstName"
                        matInput>

                <mat-checkbox formControlName="noFirstName"
                              class="custom-checkbox">
                    <label class="checkbox-label">Нет имени</label>
                </mat-checkbox>
            </div>

            <div class="item-info block-with-checkbox">
                <input
                  [placeholder]="formInfoProfile.get('middleName')?.value === null ? 'Не заполнено' : ''"
                        class="input-main-search text-inter-semibold"
                        formControlName="middleName"
                        matInput>
                <mat-checkbox formControlName="noMiddleName"
                              class="custom-checkbox">
                    <label class="checkbox-label">Нет отчества</label>
                </mat-checkbox>

            </div>

            <div class="item-info">
                <input formControlName="firstNameEng" class="text-inter-semibold input-main-search"
                       [placeholder]="formInfoProfile.get('firstNameEng')?.value === null ? 'Не заполнено' : ''"
                       matInput>
            </div>


            <div class="item-info">
                <input formControlName="lastNameEng" class="text-inter-semibold input-main-search"
                       [placeholder]="formInfoProfile.get('lastNameEng')?.value === null ? 'Не заполнено' : ''"
                       matInput>
            </div>

            <div class="item-info">
                <input formControlName="middleNameEng" class="text-inter-semibold input-main-search"
                       [placeholder]="formInfoProfile.get('middleNameEng')?.value === null ? 'Не заполнено' : ''"
                       matInput>
            </div>

          <div class="item-info">
            <mat-form-field appearance="outline" class="custom-selection-search">
              <img [class.rotate-180]="isActiveSelect" class="arrow-custom-select"
                   src="assets/shared/components/select/img-select/select-arrow.svg">
              <mat-select [formControl]="formInfoProfile.controls.extraInformation.controls.wantsParticipantMcd"
                          [placeholder]="formInfoProfile.get('wantsParticipantMcd')?.value === null ? 'Не заполнено' : ''"
                          (openedChange)="checkIfActive($event)">
                <div class="select-text">
                  <mat-option [value]="true">Да</mat-option>
                  <mat-option [value]="false">Нет</mat-option>
                </div>
              </mat-select>
            </mat-form-field>
          </div>

            <div class="item-info">
                <mat-form-field appearance="outline" class="custom-selection-search">
                    <img [class.rotate-180]="isActiveSelect" class="arrow-custom-select"
                         src="assets/shared/components/select/img-select/select-arrow.svg">
                    <mat-select [formControl]="formInfoProfile.controls.extraInformation.controls.isParticipantMcd"
                                [placeholder]="formInfoProfile.get('isParticipantMcd')?.value === null ? 'Не заполнено' : ''"
                                (openedChange)="checkIfActive($event)">
                      <div class="select-text">
                        <mat-option [value]="true">Да</mat-option>
                        <mat-option [value]="false">Нет</mat-option>
                      </div>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="item-info">
                <custom-datepicker
                        [control]="formInfoProfile.controls.dateBirth"></custom-datepicker>
            </div>

            <div class="item-info">
                <input formControlName="phoneNumber" class="text-inter-semibold input-main-search"
                       [placeholder]="formInfoProfile.get('phoneNumber')?.value === null ? 'Не заполнено' : ''"
                       matInput>
            </div>

        </div>
    </div>
</div>
