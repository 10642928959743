import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {IDropDown} from "../../../../../app/core/interface/dropdown/dropdown.interface";

@Component({
  selector: 'app-dropdown',
  standalone: true,
  imports: [
    NgForOf,
    NgClass,
    NgIf
  ],
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss'
})
export class DropdownComponent  {
  @Input() menuItems: any[] | undefined

  @Output() menuAction = new EventEmitter<string>();
  isOpenMenuItem: any | null = null;




  onMenuItemClick(menuItem: any): void {
    this.menuAction.emit(menuItem.label);
    if (!menuItem.subMenu) {
      menuItem?.action?.();
    }
  }


  toggleSubMenu(menuItem: IDropDown): void {
    this.isOpenMenuItem = this.isOpenMenuItem === menuItem.label? null : menuItem.label;
  }
}
