<div class="page page-with-header">
  <div class="body">
    <div class="container">
      <div class="block-profile">
        <div class="header">
          <div class="header-block-logo">
            <img [routerLink]="'/home'" src="assets/images/ul-kit-img/logo.svg">
            <div class="navbar-menu">
              <div
                [routerLink]="[item.url]"
                routerLinkActive="active"
                (click)="isDropdownOpen = false"
                class="text-inter-regular nav-item"
                *ngFor="let item of navMenu">
                <span>{{item.name}}</span>
              </div>
            </div>
              <div class="info-user" (click)="isDropdownOpen = !isDropdownOpen">
                <span class="user-name">{{userInfo?.shortName}}</span>
                <img src="assets/images/ul-kit-img/arrow-down-white.svg"
                     [class.rotate-180]="isDropdownOpen">
              </div>
          </div>
          <div class="dropdown" *ngIf="isDropdownOpen">
            <app-dropdown-profile></app-dropdown-profile>
          </div>
        </div>
      </div>
    </div>
  </div>
  <router-outlet style="display: none;"/>
</div>

